import React from 'react'

const FbImg = () => {
  return (
    <svg
      id="Group_67"
      xmlns="http://www.w3.org/2000/svg"
      width="24.959"
      height="24.959"
      viewBox="0 0 24.959 24.959"
    >
      <path
        id="Path_32"
        d="M109.129,220.426A12.479,12.479,0,1,0,94.7,232.753v-8.72H91.531v-3.607H94.7v-2.749c0-3.128,1.863-4.855,4.714-4.855a19.193,19.193,0,0,1,2.794.244v3.071h-1.574a1.8,1.8,0,0,0-2.034,1.949v2.341h3.461l-.553,3.607H98.6v8.72A12.483,12.483,0,0,0,109.129,220.426Z"
        transform="translate(-84.17 -207.946)"
        fill="#131313"
      />
      <path
        id="Path_33"
        d="M133.96,245.525l.553-3.607h-3.461v-2.341a1.8,1.8,0,0,1,2.034-1.949h1.574v-3.071a19.2,19.2,0,0,0-2.794-.244c-2.851,0-4.714,1.728-4.714,4.855v2.749h-3.169v3.607h3.169v8.72a12.61,12.61,0,0,0,3.9,0v-8.72Z"
        transform="translate(-116.623 -229.438)"
        fill="#fff"
      />
    </svg>
  )
}

export default FbImg
