import React, { useState } from 'react'
import { useFormik } from 'formik'
import './style.css'
import CustomizedBoldText from '../../components/CustimizedBoldText'
import CustomizedMediumText from '../../components/CustomizedMediumText'
import CustomizedButton from '../../components/CustomizedButton'
import { signUpPasswordInitialValues, signUpPasswordSchema} from '../../utils/formikSchemas'
import { useDispatch } from 'react-redux'
import { signupUser } from '../../redux/actions/userActions'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import SSLLogo from '../../Images/SSLLogo'
import Navigator from '../../components/Navigator'


const Password = () => {
  const [submitPressed,setSubmitPressed] = useState(false)
  const dispatch = useDispatch()

  // route handlers
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')

  const formik = useFormik({
    initialValues: signUpPasswordInitialValues,
    validationSchema:signUpPasswordSchema,
    onSubmit: (values) => {
      const {password} = values
      dispatch(signupUser({user_id:id,user_link:password},navigate))
    },
  })

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
  } = formik

  return (
    <div className='signup-container'>
      <div className='signup-form'>
        <SSLLogo/>
        <div className='signup-logo'>
          <img src={require('../../Images/motivatonLogo.png')} alt="LOGO" />
          <CustomizedBoldText text={'Set Password'} fontSize={40} lineHeight={'53px'}/>
        </div>
        <form onSubmit={handleSubmit}>
          <div className='password-container'>
            <CustomizedMediumText text={'Password'} fontSize={16} lineHeight={'24px'}/>
            <input
              type="password"
              name="password"
              autoComplete='new-password'
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder='Enter your password'
              className={(submitPressed && errors.password ) && 'inputError' }
            />
            {submitPressed && errors.password ? (
              <div className="error">{errors.password}</div>
            ) : null}
          </div>
          <div>
            <CustomizedMediumText text={'Confirm Password'} fontSize={16} lineHeight={'24px'}/>
            <input
              type="password"
              name="confirmPassword"
              autoComplete='new-password'
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder='Re-enter your password'
              className={(submitPressed && errors.confirmPassword ) && 'inputError' }
            />
            {submitPressed && errors.confirmPassword ? (
              <div className="error">{errors.confirmPassword}</div>
            ) : null}
          </div>
          <CustomizedButton 
            marginTop={16}
            title={'Continue'}
            color={'#ffffff'}
            onClick={() => {
              setSubmitPressed(true)
              handleSubmit()
            }}
          />
          <div className='loginLink'>
            <CustomizedMediumText text={'Already have an account?'} 
              fontSize={16} 
              lineHeight={'21px'} 
              textAlign={'center'}
              color={'#838383'}
            />
            <Navigator screen={'/signup'}><span className='link'>Sign Up</span></Navigator>
          </div>
        </form>
      </div>
    </div>
  )
}


export default Password