import { useEffect } from 'react'

const GoogleAnalytics = () => {
  const gaid = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID
  useEffect(() => {
    if (gaid && !document.querySelector(`#${gaid}`)) {
      const script = document.createElement('script')
      script.src = `https://www.googletagmanager.com/gtag/js?id=${gaid}`
      script.async = true
      script.id = gaid
      document.head.insertAdjacentElement('afterbegin', script)
      const content = `
window.dataLayer = window.dataLayer || [];
function gtag() { window.dataLayer.push(arguments); }
gtag('js', new Date());
gtag('config', '${gaid}');
`
      const handler = document.createElement('script')
      handler.innerHTML = content
      script.insertAdjacentElement('afterEnd', handler)
    }
  })
}

export default GoogleAnalytics