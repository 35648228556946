import PropTypes from 'prop-types'
import React, { useRef } from 'react'

const ScreenPaddedContainer = ({ children, scrollPos }) => {
  const container = useRef(null)

  scrollPos !== undefined &&
    container.current &&
    container.current.scrollTo({ top: scrollPos, behavior: 'smooth' })
  return (
    <div className='aboutUsContainer' ref={container}>
      {children}
    </div>
  )
}

ScreenPaddedContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  scrollPos: PropTypes.number
}

export default ScreenPaddedContainer
