import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import './style.css'
import CustomizedBoldText from '../../components/CustimizedBoldText'
import CustomizedMediumText from '../../components/CustomizedMediumText'
import CustomizedButton from '../../components/CustomizedButton'
import { logInPasswordInitialValues, loginPasswordSchema } from '../../utils/formikSchemas'
import { useDispatch } from 'react-redux'
import { login, requestUnlockEmail, unlockUser } from '../../redux/actions/userActions'
import { useNavigate, useLocation } from 'react-router-dom'
import { getRouteParam } from '../../utils/getRouteParam'
import { colors } from '../../utils/colors'
import SSLLogo from '../../Images/SSLLogo'
import Navigator from '../../components/Navigator'

const LoginPassword = () => {
  //states
  const [submitPressed,setSubmitPressed] = useState(false)
  const [unlock, setUnlock] = useState(false)
  
  //redux handlers
  const dispatch = useDispatch()

  //route params
  const navigate = useNavigate()
  const id = getRouteParam('id')
  const location = useLocation()

  const routeParam = location.pathname === '/unlock' ? location.search?.slice(1): null

  useEffect(()=>{
    if (routeParam) {
      dispatch(unlockUser({tmp_id: routeParam},navigate))
    }
  },[dispatch, navigate, routeParam])

  const formik = useFormik({
    initialValues: logInPasswordInitialValues,
    validationSchema:loginPasswordSchema,
    onSubmit: (values) => {
      dispatch(login({user_id:id,user_link:values?.password},navigate,setUnlock))
    },
  })

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
  } = formik

  return (
    <div className='signIn-container'>
      <div className='signIn-password'>
        <SSLLogo/>
        <div className='signIn-logo'>
          <img src={require('../../Images/motivatonLogo.png')} alt="LOGO" />
          <CustomizedBoldText text={'Sign in'} fontSize={40} lineHeight={'53px'}/>
          <CustomizedMediumText text={'Welcome back! Please enter your details.'} 
            fontSize={20} 
            lineHeight={'27px'} 
            textAlign={'center'}
            color={'#838383'}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <CustomizedMediumText text={'Password'} fontSize={16} lineHeight={'24px'}/>
            <input
              type="password"
              name="password"
              autoComplete="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder='Enter your password'
              className={(submitPressed && errors.password ) ? 'inputError' : '' }
            />
            {submitPressed && errors.password ? (
              <div className="error">{errors.password}</div>
            ) : null}
          </div>
          <Navigator screen={'/forgetPassword'}><span className='forgetPassword'>Forget Password?</span></Navigator>
          {!unlock && <CustomizedButton 
            marginTop={32}
            marginBottom={16}
            title={'Continue'}
            color={'#ffffff'}
            onClick={() => {
              setSubmitPressed(true)
              handleSubmit()
            }}
          />}
          {unlock  && <div>
            <CustomizedButton 
              marginTop={32}
              marginBottom={16}
              title={'Unlock'}
              color={'#ffffff'}
              backgroundColor={colors.danger}
              onClick={() => {
                dispatch(requestUnlockEmail({user_id:id}))
              }}
            />
          </div>}
          <div className='loginLink'>
            <CustomizedMediumText text={'Already have an account?'} 
              fontSize={16} 
              lineHeight={'21px'} 
              textAlign={'center'}
              color={'#838383'}
            />
            <Navigator screen={'/signup'}><span className='link'>Sign Up</span></Navigator>
          </div>
        </form>
      </div>
    </div>
  )
}


export default LoginPassword