import React from 'react'
import PropTypes from 'prop-types'

const GenderSign = ({ gender, name }) => {
  const detect = gender => {
    switch (gender) {
    case true:
      return 'male'
    case false:
      return 'female'
    default:
      return 'neutral'
    }
  }
  const type = detect(gender)
  return (
    <div>
      <img className='userPortrait mt-2 rounded-full' alt={`${name} ${type} userpic`} src={require(`../Images/${type}_icon.jpeg`)} width={48} height={48} />
      <div>{name}</div>
    </div>
  )
}

GenderSign.propTypes = {
  gender: PropTypes.bool,
  name: PropTypes.string
}

export default GenderSign
