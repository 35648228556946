import React, { useEffect } from 'react'
import './App.css'
import { Navigate, Route, Routes } from 'react-router-dom'
import Home from './screens/Home/index'
import './customCss.css'
import About from './screens/About'
import Fund from './screens/Fund'
import Payment from './screens/Fund/payment'
import { useDispatch, useSelector } from 'react-redux'
import Loader from './components/Loader/Loader'
import Signup from './screens/Signup'
import Login from './screens/Login/Login'
import LoginPassword from './screens/Login/LoginPassword'
import Password from './screens/Password/Password'
import Profile from './screens/Profile/Profile'
import ResetPassword from './screens/ResetPassword/ResetPassword'
import ForgetPassword from './screens/ForgetPassword/ForgetPassword'
import Toast from './components/Toast/Toast'
import Help from './screens/Help'
import GoogleAnalytics from './components/GoogleAnalytics/GoogleAnalytics'
import Blog from './screens/Blog'
import Admin from './screens/Admin'
import { getUserData } from './redux/actions/userActions'

function App() {
  const loader= useSelector(state => state.loader)
  const toast = useSelector(state => state.toastReducer)
  const user = useSelector(state => state.userData)
  const id = localStorage.getItem('id')
  const dispatch = useDispatch()

  useEffect(() => {
    if (id && !user.userData.userId) {
      dispatch(getUserData(id))
    } 
  }, [id])

  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/about" element={<About/>} />
        <Route exact path="/help" element={<Help/>} />
        <Route exact path="/login" element={<Login/>} />
        <Route exact path="/loginPassword" element={<LoginPassword/>} />
        <Route exact path="/password" element={<Password/>} />
        <Route exact path="/profile" element={<Profile/>} />
        <Route exact path="/resetPsw" element={<ResetPassword/>} />
        <Route exact path="/forgetPassword" element={<ForgetPassword/>} />
        <Route exact path="/confirmAccount" element={<Profile/>} />
        <Route exact path="/unlock" element={<LoginPassword/>} />
        <Route exact path="/fund" element={<Fund/>} />
        <Route exact path="/payment" element={<Payment/>} />
        <Route exact path="/gettopic/:link" element={<Home/>} />
        <Route exact path="/mgmt" element={<Admin/>} />
        <Route path="/posts" element={<Navigate to="/posts/1" replace />} />
        <Route path="/posts/:page" element={<Blog />} />
        <Route path="*" element={ <><GoogleAnalytics/><h2>404 Not Found</h2></> } /> 
      </Routes>
      {loader && <Loader/>}
      {toast && <Toast message={toast}/>}
    </>
  )
}

export default App


