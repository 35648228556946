export const copy = (value, setShowTooltip) => {
  const textArea = document.createElement('textarea')
  textArea.value = value
  document.body.appendChild(textArea)

  textArea.select()
  document.execCommand('copy')

  document.body.removeChild(textArea)
  setTimeout(() => setShowTooltip(false), 2000) // Hide the tooltip after 2 seconds
}