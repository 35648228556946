import React, { useEffect, useState } from 'react'
import HorizontalDiv from './HorizontalDiv'
import VerticalImagePlusText from './VerticalImagePlusText'
import PostedImg from '../Images/PostedImg'
import LikesImg from '../Images/LikesImg'
import BellImg from '../Images/BellImg'
import PendingImg from '../Images/PendingImg'
import ShareImg from '../Images/ShareImg'
import Tooltip from './Tooltip/Tooltip'
import CustomizedText from './CustomizedText'
import ScienceImg from '../Images/ScienceImg'
import { copy } from '../utils/copy'
import MailImag from '../Images/MailImg'
import Chip from '../Images/Chip'
import Hyperlink from '../Images/hyperlink'
import PeerReview from '../Images/peerReview'
import PropTypes from 'prop-types'

const TopicsIconsListContainer = ({ elem }) => {
  const [fillColor, setFillColor] = useState(['', ''])
  const [tooltip, setTooltip] = useState(false)

  const [justifyContent, setJustifyContent] = useState(
    window.innerWidth >= 1200
      ? 'flex-start'
      : window.innerWidth >= 992
        ? 'flex-start'
        : window.innerWidth >= 576
          ? 'center'
          : 'center'
  )
  const handleResize = () => {
    if (window.innerWidth >= 1200) {
      setJustifyContent('flex-start')
    } else if (window.innerWidth >= 992) {
      setJustifyContent('flex-start')
    } else if (window.innerWidth >= 576) {
      setJustifyContent('center')
    } else {
      setJustifyContent('center')
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <HorizontalDiv
      marginTop={13}
      marginBottom={18}
      gap={28}
      alignItems={'flex-start'}
      width={'100%'}
      justifyContent={justifyContent}
    >
      <HorizontalDiv
        justifyContent={'flex-start'}
        gap={12}
        alignSelf={'flex-start'}
      >
        <Tooltip text={elem?.typeTooltip}>
          <VerticalImagePlusText
            image={
              elem?.typeTooltip.includes('Techniques') ? (
                <Chip />
              ) : (
                <ScienceImg />
              )
            }
          />
        </Tooltip>
        <Tooltip text={elem?.ddcTooltip} opacity={0.5}>
          <CustomizedText
            fontSize={16}
            lineHeight={'36px'}
            color={'#fff'}
            text={elem?.ddc}
          />
        </Tooltip>
      </HorizontalDiv>

      {elem?.postedAuthors ? (
        <Tooltip text={elem?.postedTooltip}>
          <VerticalImagePlusText
            image={<PostedImg />}
            text={elem?.postedAuthors}
          />
        </Tooltip>
      ) : null}

      {elem?.pendingAuthors ? (
        <Tooltip text={elem?.pendingTooltip}>
          <VerticalImagePlusText
            image={<PendingImg />}
            text={elem?.pendingAuthors}
          />
        </Tooltip>
      ) : null}

      {elem?.newIcon ? (
        <Tooltip text={elem?.newTooltip}>
          <VerticalImagePlusText image={<BellImg />} />
        </Tooltip>
      ) : null}

      {elem?.remindTooltip && elem?.remindAction ? (
        <VerticalImagePlusText image={<MailImag />} />
      ) : null}

      {elem?.likesNumber ? (
        <VerticalImagePlusText
          image={<LikesImg fillColor={fillColor[0] && 'white'} />}
          text={elem?.likesNumber}
          setFillColor={setFillColor}
          index={0}
          fillColor={fillColor}
        />
      ) : null}

      {elem?.link ? (
        <Tooltip
          text={
            tooltip
              ? 'link to be copied to the buffer'
              : `copy link to "${elem?.topicName}" topic`
          }
        >
          <VerticalImagePlusText
            image={<ShareImg fillColor={fillColor[1] && 'white'} />}
            setFillColor={setFillColor}
            index={1}
            fillColor={fillColor}
            onCopy={copy}
            copyValue={elem?.link}
            setTooltip={setTooltip}
          />
        </Tooltip>
      ) : null}
      {elem?.peerReviewIcon && elem?.peerReviewAction ? (
        <Tooltip text={'Leave peer-review'}>
          <VerticalImagePlusText
            image={<PeerReview />}
            setFillColor={setFillColor}
            index={0}
            fillColor={fillColor}
          />
        </Tooltip>
      ) : null}
      {elem?.reserveIcon && elem?.reserveAction ? (
        <Tooltip text={'Request to reserve topic.'}>
          <VerticalImagePlusText
            image={<Hyperlink />}
            setFillColor={setFillColor}
            index={0}
            fillColor={fillColor}
          />
        </Tooltip>
      ) : null}
    </HorizontalDiv>
  )
}

TopicsIconsListContainer.propTypes = {
  elem: PropTypes.object
}

export default React.memo(TopicsIconsListContainer)
