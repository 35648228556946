import axios from 'axios'

const authenticatingApiInterceptor = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'Content-Type': 'application/json',
  }
})

authenticatingApiInterceptor.interceptors.request.use((config) => {
  const token = localStorage.getItem('token')
  if(token){
    config.headers['Authorization'] = token
  }
  return config
})

// interceptor for global error handling.
authenticatingApiInterceptor.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('Axios request error:', error)
    return Promise.reject(error)
  }
)

export default authenticatingApiInterceptor
