import React from 'react'

function PostedImg() {
  return (
    <svg
      id="Group_14"
      data-name="Group 14"
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <path id="Path_10" data-name="Path 10" d="M0,0H35V35H0Z" fill="none" />
      <path
        id="Path_11"
        data-name="Path 11"
        d="M27.609,8.227a3.245,3.245,0,0,1,1.641,2.841V21.69a3.244,3.244,0,0,1-1.689,2.841l-9.844,6.227a3.309,3.309,0,0,1-3.185,0L4.689,24.531A3.245,3.245,0,0,1,3,21.69V11.066A3.242,3.242,0,0,1,4.689,8.227l9.844-5.8a3.4,3.4,0,0,1,3.281,0l9.844,5.8h-.048Z"
        transform="translate(1.375 0.917)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_12"
        data-name="Path 12"
        d="M13.375,13.375,9,13.375A4.375,4.375,0,1,0,13.375,9,4.375,4.375,0,0,0,9,13.375"
        transform="translate(4.125 4.125)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  )
}

export default PostedImg
