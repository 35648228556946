import React, { useState } from 'react'
import { useFormik } from 'formik'
import './style.css'
import CustomizedBoldText from '../../components/CustimizedBoldText'
import CustomizedMediumText from '../../components/CustomizedMediumText'
import CustomizedButton from '../../components/CustomizedButton'
import { logInPasswordInitialValues, loginPasswordSchema } from '../../utils/formikSchemas'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { resetPasswordSecondCall } from '../../redux/actions/userActions'
import SSLLogo from '../../Images/SSLLogo'
import Navigator from '../../components/Navigator'

const ResetPassword = () => {
  const [submitPressed,setSubmitPressed] = useState(false)
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const location = useLocation()
  const routeParam = location.search?.slice(1)

  const formik = useFormik({
    initialValues: logInPasswordInitialValues,
    validationSchema:loginPasswordSchema,
    onSubmit: (values) => {
      dispatch(resetPasswordSecondCall({tmpId:routeParam,newPassword:values.password},navigate))
      // Handle form submission here
      
    },
  })

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
  } = formik

  return (
    <div className='signIn-container'>
      <div className='signIn-password'>
        <SSLLogo/>
        <div className='signIn-logo'>
          <img src={require('../../Images/motivatonLogo.png')} alt="LOGO" />
          <CustomizedBoldText text={'Password setup'} fontSize={40} lineHeight={'53px'}/>
          <CustomizedMediumText text={'Welcome back! Please enter new password.'} 
            fontSize={20} 
            lineHeight={'27px'} 
            textAlign={'center'}
            color={'#838383'}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <CustomizedMediumText text={'New password'} fontSize={16} lineHeight={'24px'}/>
            <input
              type="password"
              name="password"
              autoComplete='new-password'
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder='Enter your new password'
              className={(submitPressed && errors.password ) ? 'inputError' : null}
            />
            {submitPressed && errors.password ? (
              <div className="error">{errors.password}</div>
            ) : null}
          </div>
          <CustomizedButton 
            marginTop={32}
            marginBottom={16}
            title={'Continue'}
            color={'#ffffff'}
            onClick={() => {
              setSubmitPressed(true)
              handleSubmit()
            }}
          />
          <div className='loginLink'>
            <CustomizedMediumText text={'Already have an account?'} 
              fontSize={16} 
              lineHeight={'21px'} 
              textAlign={'center'}
              color={'#838383'}
            />
            <Navigator screen={'/login'}>Login</Navigator>
          </div>
        </form>
      </div>
    </div>
  )
}


export default ResetPassword