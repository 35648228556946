import React from 'react'
const PeerReview = () => (
  <svg width="50" height="30" viewBox="0 0 533 178" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M82.999 88.3312C74.2439 88.3312 65.6854 85.741 58.4058 80.888C51.1262 76.035 45.4524 69.1373 42.102 61.0671C38.7515 52.9969 37.8749 44.1166 39.5829 35.5493C41.291 26.9821 45.507 19.1125 51.6978 12.9358C57.8886 6.75916 65.7761 2.55279 74.363 0.84865C82.9499 -0.85549 91.8505 0.0191362 99.9392 3.36193C108.028 6.70472 114.941 12.3655 119.805 19.6285C124.67 26.8915 127.266 35.4305 127.266 44.1656C127.253 55.8751 122.585 67.1012 114.286 75.3811C105.987 83.6609 94.7353 88.3182 82.999 88.3312ZM82.999 12.2682C76.6759 12.2682 70.4948 14.139 65.2373 17.6439C59.9798 21.1488 55.882 26.1305 53.4623 31.959C51.0425 37.7875 50.4094 44.201 51.643 50.3885C52.8766 56.576 55.9214 62.2595 60.3926 66.7205C64.8637 71.1814 70.5603 74.2193 76.7619 75.4501C82.9636 76.6809 89.3918 76.0492 95.2336 73.635C101.075 71.2207 106.068 67.1324 109.581 61.8869C113.094 56.6414 114.969 50.4743 114.969 44.1656C114.96 35.7089 111.588 27.6013 105.595 21.6215C99.6012 15.6417 91.4751 12.278 82.999 12.2682Z" fill="white"/>
    <path d="M159.852 178C158.221 178 156.657 177.354 155.504 176.203C154.351 175.053 153.704 173.493 153.704 171.866V156.702C153.691 145.055 149.047 133.888 140.792 125.652C132.537 117.416 121.345 112.783 109.671 112.77H56.3293C44.655 112.783 33.4626 117.416 25.2077 125.652C16.9527 133.888 12.3093 145.055 12.2963 156.702V171.866C12.2963 173.493 11.6485 175.053 10.4955 176.203C9.34255 177.354 7.77874 178 6.14815 178C4.51756 178 2.95375 177.354 1.80075 176.203C0.647749 175.053 0 173.493 0 171.866V156.702C0.0178977 141.802 5.95832 127.518 16.5182 116.982C27.0782 106.446 41.3954 100.52 56.3293 100.502H109.665C124.6 100.518 138.918 106.444 149.48 116.98C160.041 127.516 165.982 141.801 166 156.702V171.866C166 173.493 165.352 175.053 164.199 176.203C163.046 177.354 161.482 178 159.852 178Z" fill="white"/>
    <path d="M322.678 80.3566H288.106C285.721 80.3566 283.785 78.4206 283.785 76.0352C283.785 73.6498 285.721 71.7138 288.106 71.7138H318.357V41.4638C318.357 39.0784 320.293 37.1424 322.678 37.1424C325.064 37.1424 327 39.0784 327 41.4638V76.0352C327 78.4206 325.064 80.3566 322.678 80.3566Z" fill="white"/>
    <path d="M210.322 140.857C207.936 140.857 206 138.921 206 136.535V101.964C206 99.5785 207.936 97.6425 210.322 97.6425H244.894C247.279 97.6425 249.215 99.5785 249.215 101.964C249.215 104.349 247.279 106.285 244.894 106.285H214.643V136.535C214.643 138.921 212.707 140.857 210.322 140.857Z" fill="white"/>
    <path d="M321.125 80.3571C319.154 80.3571 317.383 79.0088 316.916 77.021C311.436 53.5383 290.71 37.1429 266.501 37.1429C242.292 37.1429 221.566 53.5383 216.086 77.021C215.542 79.3372 213.251 80.7806 210.892 80.2447C208.567 79.7002 207.123 77.3753 207.659 75.0504C214.064 47.6439 238.256 28.5 266.501 28.5C294.746 28.5 318.938 47.6439 325.343 75.0504C325.879 77.3753 324.444 79.7002 322.11 80.2447C321.782 80.3225 321.445 80.3571 321.125 80.3571Z" fill="white"/>
    <path d="M266.501 149.5C238.256 149.5 214.064 130.356 207.659 102.95C207.123 100.625 208.567 98.2997 210.892 97.7552C213.251 97.2107 215.542 98.6627 216.086 100.979C221.566 124.462 242.292 140.857 266.501 140.857C290.71 140.857 311.436 124.462 316.916 100.979C317.469 98.6627 319.759 97.2107 322.11 97.7552C324.444 98.2911 325.887 100.625 325.343 102.95C318.938 130.356 294.746 149.5 266.501 149.5Z" fill="white"/>
    <path d="M449.999 88.3312C441.244 88.3312 432.685 85.741 425.406 80.888C418.126 76.035 412.452 69.1373 409.102 61.0671C405.752 52.9969 404.875 44.1166 406.583 35.5493C408.291 26.9821 412.507 19.1125 418.698 12.9358C424.889 6.75916 432.776 2.55279 441.363 0.84865C449.95 -0.85549 458.85 0.0191362 466.939 3.36193C475.028 6.70472 481.941 12.3655 486.805 19.6285C491.67 26.8915 494.266 35.4305 494.266 44.1656C494.253 55.8751 489.585 67.1012 481.286 75.3811C472.987 83.6609 461.735 88.3182 449.999 88.3312ZM449.999 12.2682C443.676 12.2682 437.495 14.139 432.237 17.6439C426.98 21.1488 422.882 26.1305 420.462 31.959C418.043 37.7875 417.409 44.201 418.643 50.3885C419.877 56.576 422.921 62.2595 427.393 66.7205C431.864 71.1814 437.56 74.2193 443.762 75.4501C449.964 76.6809 456.392 76.0492 462.234 73.635C468.075 71.2207 473.068 67.1324 476.581 61.8869C480.094 56.6414 481.969 50.4743 481.969 44.1656C481.96 35.7089 478.588 27.6013 472.595 21.6215C466.601 15.6417 458.475 12.278 449.999 12.2682Z" fill="white"/>
    <path d="M526.852 178C525.221 178 523.657 177.354 522.504 176.203C521.351 175.053 520.704 173.493 520.704 171.866V156.702C520.691 145.055 516.047 133.888 507.792 125.652C499.537 117.416 488.345 112.783 476.671 112.77H423.329C411.655 112.783 400.463 117.416 392.208 125.652C383.953 133.888 379.309 145.055 379.296 156.702V171.866C379.296 173.493 378.649 175.053 377.496 176.203C376.343 177.354 374.779 178 373.148 178C371.518 178 369.954 177.354 368.801 176.203C367.648 175.053 367 173.493 367 171.866V156.702C367.018 141.802 372.958 127.518 383.518 116.982C394.078 106.446 408.395 100.52 423.329 100.502H476.665C491.6 100.518 505.918 106.444 516.48 116.98C527.041 127.516 532.982 141.801 533 156.702V171.866C533 173.493 532.352 175.053 531.199 176.203C530.046 177.354 528.482 178 526.852 178Z" fill="white"/>
  </svg>

)

export default PeerReview