import PropTypes from 'prop-types'
import React from 'react'
export default function UserIcon({ className = 'w-6 h-6', stroke = 1.5 }) {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={stroke} stroke="currentColor" className={className}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
  </svg>
}

UserIcon.propTypes = {
  className: PropTypes.string,
  stroke: PropTypes.number,
}
