import React, { useEffect, useState } from 'react'
import './styles.css'
import { useDispatch } from 'react-redux'
import { filterTopics } from '../../redux/actions/TopicActions'
import { useDebounce } from 'use-debounce'
import PropTypes from 'prop-types'

const SearchComp = ({ toggleBar, searchBar, runSearch, setRunSearch }) => {
  //states
  const [searchText, setSearchText] = useState('')

  //redux handlers
  const dispatch = useDispatch()

  //functions
  const handleInputChange = (event) => {
    setSearchText(event.target.value)
  }

  const [query] = useDebounce(searchText, 500)

  const handleBlur = () => {
    if (searchBar && toggleBar) toggleBar(false)
  }

  const search = () => {
    dispatch(filterTopics(query))
  }

  useEffect(() => {
    if (runSearch === true) {
      setRunSearch(false)
      search()
    }
  }, [runSearch])

  useEffect(() => {
    !!query && search()
  }, [query])

  return (
    <div
      className={
        searchBar
          ? 'p-1 md:pr-0 rounded-3xl border mx-1 md:mx-2 bg-white md:pl-9 md:grid md:grid-cols-2 w-full md:w-auto'
          : 'hidden'
      }
    >
      <input
        type='text'
        className='align-middle outline-none border-0 md:col-start-1 md:col-end-12 mx-1'
        placeholder='Search videos here...'
        value={searchText}
        onChange={handleInputChange}
        onBlur={handleBlur}
        name={'search'}
      />
      <div
        onClick={() => search}
        className='submitButton md:inline-block md:col-start-13 hidden'
      >
        Search
      </div>
    </div>
  )
}

SearchComp.propTypes = {
  toggleBar: PropTypes.func,
  searchBar: PropTypes.bool,
  runSearch: PropTypes.bool,
  setRunSearch: PropTypes.func
}

export default SearchComp
