import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { HIDE_TOAST } from '../../redux/types/LoaderTypes'
import './style.css'

const Toast = ({ message }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      dispatch({type:HIDE_TOAST})
    }, 4000)
  }, [dispatch])

  return (
    <div className="toast">
      <p>{message}</p>
    </div>
  ) 
}

Toast.propTypes = {
  message: PropTypes.string.isRequired
}

export default Toast
