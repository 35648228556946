import React from 'react'
import HorizontalDiv from '../HorizontalDiv'
import ImageComp from '../imageComp/ImageComp'
import CustomizedText from '../CustomizedText'
import FbImg from '../../Images/FbImg'
import PinterestImg from '../../Images/PinterestImg'
import LinkedIn from '../../Images/LinkedInImg'
import BeImg from '../../Images/BeImg'
import TwitterImg from '../../Images/TwitterImg'
import './styles.css'
import CustomizedBoldText from '../CustimizedBoldText'
import { colors } from '../../utils/colors'
import FooterLink from './FooterLink'
import Quotes from './Quotes'

const Footer = () => {
  // const link = <a target="_blank" rel="noreferrer" href="mailto:info@motivation.how?subject=Reaching%20you%20from%20Website&body=Hello%20MotivationHow%20team">info@motivation.how</a>
  const addr =
    process.env.REACT_APP_MOTIVATION_HOW_EMAIL || 'info@motivation.how'
  // const mail = () => {
  //   window.open(
  //     `mailto:${addr}?subject=Reaching%20you%20from%20Website&body=Hello%20MotivationHow%20team,%20`
  //   )
  // }
  const rows = [
    '© SATUS LLC. US Patents pending',
    'MotivationHow is now a part of Microsoft for Startups Founders Hub',
    '522 W Riverside Ave Ste N', 'Spokane, WA 99201-0580, USA',
    'Phone: <span class="static-text"><a href="tel:4252988984">(425) 298-8984</a></span>',
    `Email: <span class="static-text"><a href="mailto:${addr}">info@motivation.how</a></span>`
  ]
  return (
    <div style={{ width: '100%' }}>
      <Quotes />
      <div className='footer'>
        <HorizontalDiv>
          <ImageComp imgSrc={require('../../Images/logo.png')} />
          <CustomizedBoldText
            fontSize={20}
            marginLeft={10}
            color={colors?.black}
          >
            Motivation How
          </CustomizedBoldText>
        </HorizontalDiv>
        <div>
          <CustomizedText
            text={'Find us on Social Media'}
            fontSize={18}
            fontWeight={'medium'}
            lineHeight={'24px'}
            color={colors?.black}
          />
          <HorizontalDiv marginTop={25}>
            <FooterLink url={process.env.REACT_APP_FB_CHAT}>
              <FbImg />
            </FooterLink>
            <FooterLink url={process.env.REACT_APP_PT_LINK}>
              <PinterestImg />
            </FooterLink>
            <FooterLink url={process.env.REACT_APP_LN_LINK}>
              <LinkedIn />
            </FooterLink>
            <FooterLink url={process.env.REACT_APP_BE_LINK}>
              <BeImg />
            </FooterLink>
            <FooterLink url={process.env.REACT_APP_X_LINK}>
              <TwitterImg />
            </FooterLink>
          </HorizontalDiv>
        </div>
        <div className='w-fit float-end -mr-20'>
          {rows.slice(2).map((v, i) =>
            <CustomizedText
              key={i}
              fontSize={14}
              fontWeight={'medium'}
              lineHeight={'18px'}
              color={colors?.black}
              text={v}
            />
          )}
        </div>
      </div>
      {rows.slice(0, 2).map((v, i) =>
        <CustomizedText
          key={i}
          fontSize={16}
          fontWeight={'medium'}
          marginTop={i == 0 ? 28 : 6}
          textAlign={'center'}
          lineHeight={'21px'}
          html={v}
        />)
      }
    </div>
  )
}

export default Footer
