import PropTypes from 'prop-types'
import React from 'react'

const IframeVideoComp = ({ src, width, height }) => {
  return (
    <iframe
      width={width ? width + 'px' : '100%'}
      height={height ? height + 'px' : '100%'}
      style={{ borderRadius: 20 }}
      src={src}
      title='video player'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    ></iframe>
  )
}

IframeVideoComp.propTypes = {
  src: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}

export default IframeVideoComp
