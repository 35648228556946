import { HIDE_SCREEN_LOADER, SHOW_SCREEN_LOADER } from '../types/LoaderTypes'


const loaderInitialState = false

// eslint-disable-next-line no-unused-vars
export const loaderReducer = (state = loaderInitialState, action) => {
  switch (action.type) {
  case SHOW_SCREEN_LOADER:
    return true
  case HIDE_SCREEN_LOADER:
    return false 
  default:
    return false
  }
}
