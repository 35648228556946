import React, { useEffect, useState } from 'react'
import ScreenPaddedContainer from '../../components/ScreenPaddedContainer'
import GradientText from '../../components/GradientText'
import Footer from '../../components/Footer/Footer'
import CustomizedBoldText from '../../components/CustimizedBoldText'
import CustomizedButton from '../../components/CustomizedButton'
import './style.css'
import CustomizedMediumText from '../../components/CustomizedMediumText'
import useResponsiveStyles from '../../utils/useResponsiveStyles'
import {  useDispatch, useSelector } from 'react-redux'
import { becomeAuthorReq, getAllInterests, getCountries, getDirectKids, getUserInterests, resetPasswordFirstCall, updateInterests, updateProfileInfo, checkAuthorData, requestConfirmationEmail, confirmAccount  } from '../../redux/actions/userActions'
import AllChildrenDownArrow from '../../Images/AllChildrenArrow'
import DirectChildren from '../../Images/DirectChildren'
import UpBoldArrow from '../../Images/UpBoldArrow'
import DownBoldArrow from '../../Images/DownBoldArrow'
import DeleteIcon from '../../Images/DeleteIcon'
import ParentHeirarichyImg from '../../Images/ParentHeirarichyImg'
import SearchableSelect from '../../components/SearchableSelect/SearchableSelect'
import { useLocation, useNavigate } from 'react-router-dom'
import CompleteLogo from '../../Images/CompleteLogo'
// @note import new component
import SSLLogo from '../../Images/SSLLogo'

const Profile = () => {
  //states
  const [editingMode, setEditingMode] = useState(false)
  const [interestsList, setInterestsList] = useState([])
  const { fontSize, width } = useResponsiveStyles()
  const [editProfileMode,setEditProfileMode] = useState(false)
  const [personalInfoData, setPersonalInfoData] = useState({})
  const [countries,setCountries] = useState([])
  const [selfDescription,setSelfDescription] = useState('')
  const [link,setLink] = useState('')
  const [showInterestsIndex,setShowInterestsIndex] = useState()
  const [showAllChildrenIndex, setShowAllChildrenIndex] = useState()
  const [getCompleteInterests, setGetCompleteInterests] = useState(false)


  //redux handlers
  const dispatch = useDispatch()
  const {userData:userReducerData,userInterests} = useSelector(state => state.userData)
  const authorData = useSelector(state => state?.userData?.author)

  const navigate = useNavigate()
  const location = useLocation()
  const routeParam = location.search?.slice(1)
  
  const userId = Number(localStorage.getItem('id'))

  useEffect(() => {
    if (routeParam) {
      dispatch(confirmAccount({ tmp_id: routeParam }, navigate))
    }
  }, [dispatch,navigate,routeParam])

  // useEffects
  useEffect(()=>{
    setPersonalInfoData(userReducerData)
    if (userId) {
      dispatch(getUserInterests(userId))
    }
  },[userReducerData,dispatch])

  useEffect(()=>{
    const interestValues = Object?.values(userInterests)
    setInterestsList(interestValues)
  },[userInterests])

  useEffect(()=>{
    //calling api to get user data only if state's been removed from redux in a case like page refresh
    if(!userReducerData.userId || !userId) {
      navigate('/login')
    }
    dispatch(checkAuthorData(userId))
    getListOfCountries()
    //to check requested for author to set description and link
    if(authorData?.request){
      setSelfDescription(authorData?.request)
    }
    if(authorData?.links){
      setLink(authorData?.links)
    }
    // eslint-disable-next-line 
  },[])

  //functions
  const editProfileInfo = () => {
    dispatch(
      updateProfileInfo({
        data: {
          user_id: personalInfoData?.userId,
          nick_name: personalInfoData?.nickName,
          last_name: personalInfoData?.lastName,
          male_gender: personalInfoData?.maleGender,
          country_id: personalInfoData?.countryId,
          us_zip_code: personalInfoData?.usZipCode,
          use_nick_name: personalInfoData?.useNickName
        },
        values: personalInfoData,
        setEditProfileMode
      })
    )
  }

  const getListOfCountries = async() => {
    const listOfCountries = await dispatch(getCountries())
    setCountries(listOfCountries)
  }

  function swapArrayElement(direction, index) {
    setShowInterestsIndex('')
    const array = JSON.parse(JSON.stringify(interestsList))
    if (direction === 'up' && index > 0) {
      // Swap with the element before it
      [array[index], array[index - 1]] = [array[index - 1], array[index]]
    } else if (direction === 'down' && index < array.length - 1) {
      // Swap with the element after it
      [array[index], array[index + 1]] = [array[index + 1], array[index]]
    }
  
    setInterestsList(array)
  }

  const showDirectKids = (elem,index,closeDropdown) => {
    setShowAllChildrenIndex('')
    if(closeDropdown){
      setShowInterestsIndex('')
    }
    else{
      if((elem?.directKids?.length > 0) && index === showInterestsIndex){setShowInterestsIndex('')}
      else setShowInterestsIndex(index)
    }
  }
  
  const handleSelectedInterest = async (value,id) =>{
    const data = await dispatch(getDirectKids(value))
    setInterestsList((prevData) => {
      return prevData.map((obj,index) => {
        if (index === id) {
          const updatedObj = { ...obj }
          updatedObj.path = data?.parentPath
          updatedObj.directKids = data?.directKids
          updatedObj.children = data?.allKids
          updatedObj.name = value
          return updatedObj
        }
        return obj
      })
    })
    showDirectKids(null,null,'close')
  }

  const showAllKids = (elem,index,closeDropdown) => {
    setShowInterestsIndex('')
    if(closeDropdown){
      setShowAllChildrenIndex('')
    }
    else{
      if((elem?.children?.length > 0) && index === showAllChildrenIndex){setShowAllChildrenIndex('')}
      else setShowAllChildrenIndex(index)
    }
  }

  const handleInterestsApi = () =>{
    if(!editingMode){setEditingMode(true)}
    else{ 
      const reducedValue = interestsList?.reduce((first,second,index)=>{
        return {...first,[`${index+1}`]:second?.name}
      },{})
      if (userId) {
        dispatch(updateInterests(reducedValue, userId)) 
      }
   
      setEditingMode(false)
    }

  }

  const addInterest = (value) => {
    const reducedValue = interestsList?.concat({name:value})?.reduce((first,second,index)=>{
      return {...first,[`${index+1}`]:second?.name}
    },{})
    if (userId) {
      dispatch(updateInterests(reducedValue,userId)) 
    }

    setEditingMode(false)
    setGetCompleteInterests(false)
  }

  const showAllInterests = () => {
    setGetCompleteInterests(true)
    dispatch(getAllInterests())
  }

  return (
    <div>
      <img className='paper_background' src={require('../../Images/paper-background.png')} alt="" />
      <ScreenPaddedContainer>
        <CompleteLogo />
        {/* @note inject logo, move it to place by css transform */}
        <SSLLogo css={{transform: 'translate(30px, 10%) scale(.85)'}} />
        <GradientText
          text={'My Profile'}
          bold
          fontSize={Number(fontSize)}
          textAlign={'center'}
          marginBottom={48}
          cursor='pointer'
        />
        <div className="profileContentContainer">
          <CustomizedBoldText
            text={'Personal Info'}
            fontSize={32}
            lineHeight={'58px'}
            display={'flex'}
            width={width}
          />
          <div className="flex justify-end items-center gap-3 w-full">
            <CustomizedButton
              color={'#fff'}
              fontSize={14}
              padding={'12px 44px'}
              title={!editProfileMode ? 'Edit Profile' : 'Update Profile'}
              onClick={() => {
                !editProfileMode ? setEditProfileMode(true) : editProfileInfo()
              }}
            />
            {editProfileMode && (
              <CustomizedButton
                backgroundColor={'#e1e4e8'}
                color={'#756D86'}
                fontSize={14}
                padding={'12px 44px'}
                title={'Cancel'}
                onClick={() => {
                  setEditProfileMode(false)
                  setPersonalInfoData(userReducerData)
                }}
              />
            )}
          </div>
        </div>
        <div className="profile-form">
          <form>
            <div className="form-container">
              <div>
                <CustomizedMediumText
                  text={'First Name'}
                  fontSize={16}
                  lineHeight={'24px'}
                />
                <input
                  type="text"
                  name="first_name"
                  placeholder="First Name"
                  disabled
                  value={personalInfoData?.firstName || ''}
                />
              </div>
              <div>
                <CustomizedMediumText
                  text={'Last Name'}
                  fontSize={16}
                  lineHeight={'24px'}
                />
                <input
                  type="text"
                  name="last_name"
                  placeholder="Last Name"
                  disabled={!editProfileMode}
                  value={personalInfoData?.lastName || ''}
                  onChange={(e) =>
                    setPersonalInfoData({
                      ...personalInfoData,
                      lastName: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <CustomizedMediumText
                  text={'NickName'}
                  fontSize={16}
                  lineHeight={'24px'}
                />
                <div className="nicknameContainer">
                  <input
                    type="text"
                    name="nickname"
                    placeholder="Enter your nick name"
                    disabled={!editProfileMode}
                    value={personalInfoData?.nickName || ''}
                    onChange={(e) =>
                      setPersonalInfoData({
                        ...personalInfoData,
                        nickName: e.target.value,
                      })
                    }
                  />
                  {editProfileMode && <label htmlFor="checkbox">
                    <input
                      type="checkbox"
                      name="checkbox"
                      id="checkbox"
                      value={personalInfoData?.useNickName || ''}
                      onChange={() =>
                        setPersonalInfoData({
                          ...personalInfoData,
                          useNickName: !personalInfoData?.useNickName,
                        })
                      }
                      checked={personalInfoData?.useNickName}
                    />
                    <span>Use nickname</span>
                  </label>}
                </div>
              </div>
              <div>
                <CustomizedMediumText
                  text={'Country'}
                  fontSize={16}
                  lineHeight={'24px'}
                />
                <select 
                  name="country_id" 
                  disabled={!editProfileMode}
                  defaultValue={personalInfoData?.countryId}
                  value={personalInfoData?.countryId}
                  onChange={(e)=>
                    setPersonalInfoData({
                      ...personalInfoData,
                      countryId: e.target.value,
                    })}
                >
                  {countries?.length > 0 && countries?.map(elem => {
                    return(
                      <option value={elem?.countryId} key={elem?.countryId}
                        // selected = {elem?.countryId === personalInfoData?.countryId}
                      >{elem?.countryName}</option>
                    )
                  })}
                </select>
              </div>
              {
                // eslint-disable-next-line
                personalInfoData?.countryId == "228" ? (
                  <div>
                    <CustomizedMediumText
                      text={'US Zip Code'}
                      fontSize={16}
                      lineHeight={'24px'}
                    />
                    <input
                      type="text"
                      name="us_zip_code"
                      placeholder="Enter US Zip Code"
                      disabled={!editProfileMode}
                      value={personalInfoData?.usZipCode || ''}
                      onChange={(e) =>
                        setPersonalInfoData({
                          ...personalInfoData,
                          usZipCode: e.target.value,
                        })
                      }
                    />
                  </div>
                ) : null
              }
              <div>
                <CustomizedMediumText
                  text={'Gender'}
                  fontSize={16}
                  lineHeight={'24px'}
                />
                <select
                  name="male_gender"
                  disabled={!editProfileMode}
                  value={personalInfoData.maleGender}
                  onChange={(e) =>
                    setPersonalInfoData({
                      ...personalInfoData,
                      maleGender: e.target.value
                    })}>
                  <option value='true' >Male</option>
                  <option value='false'>Female</option>
                  <option value='null'>Prefer not to say</option>
                </select>
              </div>
              <div>
                <CustomizedMediumText
                  text={'Birthday'}
                  fontSize={16}
                  lineHeight={'24px'}
                />
                <input
                  type="date"
                  name="birth_day"
                  disabled
                  value={personalInfoData?.birthDay || ''}
                  onChange={(e) =>
                    setPersonalInfoData({
                      ...personalInfoData,
                      birthDay: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <CustomizedMediumText
                  text={'Email confirmed'}
                  fontSize={16}
                  lineHeight={'24px'}
                />
                {personalInfoData?.confirmedAccount ? <b style={{fontSize:'x-large'}}>☑</b> : 
                  <input
                    style={{width: 'unset'}}
                    type="checkbox"
                    name="confirmedAccount"
                    value={true}
                    onChange={personalInfoData?.confirmedAccount ? null : () => {
                      dispatch(requestConfirmationEmail({user_id: personalInfoData?.userId}))
                    }
                    }
                  />}
              </div>

            </div>
          </form>
          <p className='my-4 text-lg leading-6 text-[#1592E6]'
            style={{cursor:'pointer'}}
            onClick={()=>{
              const user_name = localStorage.getItem('email')
              user_name && dispatch(resetPasswordFirstCall({user_name}))}}
          >If you want to change password please click here...</p>
        </div>
        <div className="profileContentContainer">
          <CustomizedBoldText
            text={'Interests. From most till less interesting (up to 10max):'}
            fontSize={32}
            lineHeight={'58px'}
            display={'flex'}
            width={width}
          />
          <div className="flex justify-end items-center gap-3 w-full">
            <CustomizedButton
              color={'#fff'}
              fontSize={14}
              padding={'12px 44px'}
              title={editingMode ? 'Update Interest' : 'Edit Interest'}
              onClick={() => handleInterestsApi()}
            />
            {editingMode && (
              <CustomizedButton
                backgroundColor={'#e1e4e8'}
                color={'#756D86'}
                fontSize={14}
                padding={'12px 44px'}
                title={'Cancel'}
                onClick={() => {
                  setEditingMode(false)
                  setGetCompleteInterests(false)
                }}
              />
            )}
          </div>
        </div>
        <div className="mb-8">
          <div className="w-full overflow-hidden">
            <div className="overflow-x-auto w-full">
              <table className="w-full text-left font-light">
                <thead>
                  <tr>
                    {editingMode && (
                      <th className="py-4">
                        <CustomizedMediumText
                          text={'Priority:'}
                          fontSize={18}
                          lineHeight={'24px'}
                          whiteSpace={'nowrap'}
                        />
                      </th>
                    )}
                    <th className="py-4">
                      <CustomizedMediumText
                        text={'Interest Name (in hierarchical structure):'}
                        fontSize={18}
                        lineHeight={'24px'}
                        whiteSpace={'nowrap'}
                      />
                    </th>
                    {editingMode && (
                      <th className="py-4">
                        <CustomizedMediumText
                          text={'Hierarchy (level/details):'}
                          fontSize={18}
                          lineHeight={'24px'}
                          whiteSpace={'nowrap'}
                        />
                      </th>
                    )}
                    {editingMode && (
                      <th className="py-4">
                        <CustomizedMediumText
                          text={'Add/Delete:'}
                          fontSize={18}
                          lineHeight={'24px'}
                          whiteSpace={'nowrap'}
                        />
                      </th>
                    )}
                  </tr>
                </thead>
                {interestsList?.map((elem, index) => {
                  return (
                    <tbody key={index}>
                      <tr>
                        {editingMode && (
                          <td className="px-3 py-4">
                            <div className="flex gap-4 items-center">
                              <CustomizedBoldText
                                text={`${index + 1}`}
                                fontSize={22}
                                lineHeight={'24px'}
                                whiteSpace={'nowrap'}
                              />
                              <div
                                style={{ cursor: index !== 0 && 'pointer' }}
                                onClick={() => {
                                  index !== 0 && swapArrayElement('up', index)
                                }}
                              >
                                <UpBoldArrow disabled={index === 0} />
                              </div>
                              <div
                                style={{
                                  cursor:
                                    index !== interestsList?.length - 1 &&
                                    'pointer',
                                }}
                                onClick={() => {
                                  index !== interestsList?.length - 1 &&
                                    swapArrayElement('down', index)
                                }}
                              >
                                <DownBoldArrow
                                  disabled={index === interestsList?.length - 1}
                                />
                              </div>
                            </div>
                          </td>
                        )}
                        <td>
                          {elem?.path?.length>0 && (
                            <CustomizedMediumText
                              text={elem?.path?.join(' -> ')}
                              fontSize={18}
                              lineHeight={'24px'}
                              whiteSpace={'nowrap'}
                              marginBottom={4}
                            />
                          )}
                          
                          <div className="flex gap-4 justify-center items-center px-4 py-2 border border-amber-500 rounded-lg">
                            <CustomizedMediumText
                              text={elem?.name}
                              color={'#f77f27'}
                              fontSize={18}
                              lineHeight={'24px'}
                              whiteSpace={'nowrap'}
                            />
                          </div>
                          {editingMode && showInterestsIndex === index && elem?.directKids?.length > 0 ? (
                            <div className="mt-1 flex flex-col gap-2 justify-center items-center px-4 py-2 border border-amber-500 rounded-lg max-h-28 overflow-y-auto">
                              {elem?.directKids?.map((data, idx) => {
                                return (
                                  <CustomizedMediumText
                                    key={idx}
                                    text={data}
                                    color={'#f77f27'}
                                    fontSize={14}
                                    lineHeight={'20px'}
                                    whiteSpace={'nowrap'}
                                    cursor={'pointer'}
                                    onClick={()=>handleSelectedInterest(data,index)}
                                  />
                                )
                              })}
                            </div>
                          )
                            : editingMode && showAllChildrenIndex === index && elem?.children?.length > 0 ? (
                              <div className="mt-1 flex flex-col gap-2 justify-center items-center px-4 py-2 border border-amber-500 rounded-lg max-h-28 overflow-y-auto">
                                {elem?.children?.map((data, idx) => {
                                  return (
                                    <CustomizedMediumText
                                      key={idx}
                                      text={data}
                                      color={'#f77f27'}
                                      fontSize={14}
                                      lineHeight={'20px'}
                                      whiteSpace={'nowrap'}
                                      cursor={'pointer'}
                                      onClick={()=>handleSelectedInterest(data,index)}
                                    />
                                  )
                                })}
                              </div>
                            )
                              : 
                              null
                          }
                        </td>
                        {editingMode && (
                          <td className="px-3 py-4">
                            <div className="flex gap-8 items-center" >
                              <div 
                                style={{cursor: 'pointer'}}
                                onClick={()=>{!((elem?.path?.includes(elem?.name) && elem?.path?.length === 1) || elem?.path?.length === 0) && handleSelectedInterest(elem?.path?.length > 1 ? elem?.path[elem?.path?.length-2] : elem?.path[0],index)}}
                              >
                                <ParentHeirarichyImg 
                                  disabled={(elem?.path?.includes(elem?.name) && elem?.path?.length === 1) || elem?.path?.length === 0}
                                  // disabled={elem?.path?.length <= 0}
                                />
                              </div>
                              <div
                                onClick={()=>  elem?.directKids?.length > 0 &&  showDirectKids(elem,index) }
                                style={{cursor:'pointer'}}>
                                <DirectChildren  disabled={elem?.directKids?.length <= 0   }
                                />
                              </div>
                              <div
                                onClick={()=> elem?.children?.length > 0 && showAllKids(elem,index)}
                                style={{cursor: 'pointer'}}
                              >
                                <AllChildrenDownArrow
                                  disabled={elem?.children?.length < 1}
                                />
                              </div>
                            </div>
                          </td>
                        )}
                        {editingMode && (
                          <td
                            className="px-3 py-4"
                            onClick={() =>
                              setInterestsList(
                                interestsList.filter((data, key) => key !== index)
                              )
                            }
                          >
                            <DeleteIcon />
                          </td>
                        )}
                      </tr>
                    </tbody>
                  )
                })}
              </table>
            </div>
          </div>
          <div className='flex gap-4 mt-4 h-full'>
            {interestsList.length < 10 && editingMode && (
              <button
                className="rounded-full mt-1.5 border flex items-center justify-center border-black text-2xl w-8 h-8 text-center"
                style={{ lineHeight: '0' }}
                onClick={()=>showAllInterests()}
              >
                <span className="mb-1">+</span>
              </button>
            )}
            {(editingMode && getCompleteInterests) && 
              // <select
              //   className='flex gap-4 justify-center items-center px-4 py-2 border border-amber-500 rounded-lg text-[#f77f27] text-base'
              //     name="all_interests"
              //     value={extraInterests[extraInterests?.length - 1]}
              //     onChange={(e) =>
              //       {setExtraInterests(extraInterests?.concat(e.target.value))
              //       addInterest(e.target.value)
              //       }
              //     }
              // >
              //   {allInterests?.length > 0 && allInterests?.map((elem)=>{
              //     return(
              //       <option value={elem}>{elem}</option>
              //     )
              //   })}
              // </select>
              <SearchableSelect getInterests addInterest={addInterest}/>
            }
          </div>
        </div>
        <div className="profileContentContainer">
          <CustomizedBoldText
            text={'Request to become an Author'}
            fontSize={32}
            lineHeight={'58px'}
            display={'flex'}
            width={width}
          />
          <div className="flex justify-end items-center gap-3 w-full">
            <CustomizedButton
              onClick={()=>dispatch(becomeAuthorReq({request:selfDescription,links:link}))}
              color={'#fff'}
              fontSize={14}
              padding={'12px 44px'}
              title={'Send Request'}
            />
            <CustomizedButton
              backgroundColor={'#e1e4e8'}
              color={'#756D86'}
              fontSize={14}
              padding={'12px 44px'}
              title={'Cancel'}
            />
          </div>
        </div>
        <div className="profile-form">
          <form>
            <div className="flex flex-col gap-4">
              <div>
                <CustomizedMediumText
                  text={'Describe yourself'}
                  fontSize={16}
                  lineHeight={'24px'}
                />
                <textarea
                  value={selfDescription}
                  className="border-2 w-full rounded-xl p-2 mt-2"
                  rows="5"
                  name="description"
                  placeholder='Text: e.g., "I have been working with video creating business since 2018..."'
                  onChange={(e)=>setSelfDescription(e.target.value)}
                />
              </div>
              <div>
                <CustomizedMediumText
                  text={'Link/references'}
                  fontSize={16}
                  lineHeight={'24px'}
                />
                <input type="link" name="link" placeholder="Enter link here" value={link} onChange={(e)=>setLink(e.target.value)}/>
              </div>
              <div>
                {authorData?.dateRequested && `Date Requested: ${authorData?.dateRequested}`} 
              </div>
              <div>
                {authorData?.dateProvisioned && `Date Provisioned: ${authorData?.dateProvisioned}`} 
              </div>
              <div>
                {authorData?.dateApproved && `Date Approved: ${authorData?.dateApproved}`} 
              </div>
              {/* <div className="flex gap-8 flex-wrap">
                <div>
                  <CustomizedMediumText
                    text={'Request Date'}
                    fontSize={16}
                    lineHeight={'24px'}
                  />
                  <input type="date" name="request_date" />
                </div>
                <div>
                  <CustomizedMediumText
                    text={'Approval Date'}
                    fontSize={16}
                    lineHeight={'24px'}
                  />
                  <input type="date" name="approval_date" />
                </div>
                <div>
                  <CustomizedMediumText
                    text={'Provisioned Date'}
                    fontSize={16}
                    lineHeight={'24px'}
                  />
                  <input type="date" name="provisioned_date" />
                </div>
              </div> */}
            </div>
          </form>
        </div>
        <Footer />
      </ScreenPaddedContainer>
    </div>
  )
}

export default Profile
