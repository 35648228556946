import React from 'react'
import './style.css'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'


const DonateLink = ({ hide }) => {

  return (process.env.REACT_APP_PAYMENT_STRIPE === 'enabled' || process.env.REACT_APP_PAYMENT_PAYPAL === 'enabled') ?
    (<>
      <NavLink to={'/fund'}  className={({ isActive }) => isActive ? 'hidden md:hidden' : ''}>
        <div className={hide ? 'hidden' : 'bg-white rounded-full border-2 border-orange-400 w-9 h-9 p-1 md:hidden'}>
          <img src="/fundIcon.png" alt='Fund link' />
        </div>
        <div className="hidden text-xl md:inline-block font-semibold mx-1 border-l-4 border-t-4 border-current px-1  hover:border-orange-400 cursor-pointer">
                    Fund
        </div>
      </NavLink>
    </>) : ''
}

DonateLink.propTypes = {
  hide: PropTypes.bool
}

export default DonateLink
