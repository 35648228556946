import PropTypes, { string } from 'prop-types'
import React from 'react'
import { colors } from '../utils/colors'

const CustomizedMediumText = ({
  text,
  color,
  fontSize,
  width,
  marginLeft,
  cursor,
  marginTop,
  textAlign,
  marginRight,
  lineHeight,
  style,
  border,
  onClick,
  whiteSpace,
  marginBottom,
  className
}) => {
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        color: color ? color : colors?.notHighlightedText,
        fontSize: typeof fontSize == string ? fontSize : fontSize + 'px',
        width: width,
        marginLeft: marginLeft,
        cursor: cursor,
        whiteSpace: whiteSpace,
        marginTop: marginTop,
        fontFamily: 'GilroyMedium',
        textAlign: textAlign,
        marginRight: marginRight,
        lineHeight: lineHeight,
        border: border,
        marginBottom: marginBottom,
        ...style
      }}
    >
      {text}
    </div>
  )
}

CustomizedMediumText.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.number,
  marginLeft: PropTypes.number,
  cursor: PropTypes.string,
  marginTop: PropTypes.number,
  textAlign: PropTypes.string,
  marginRight: PropTypes.number,
  lineHeight: PropTypes.string,
  style: PropTypes.string,
  border: PropTypes.string,
  onClick: PropTypes.func,
  whiteSpace: PropTypes.string,
  marginBottom: PropTypes.number,
  className: PropTypes.string
}

export default CustomizedMediumText
