import { REMOVE_USER_DATA, SAVE_ALL_INTEREST_TYPES, SAVE_USER_DATA, SAVE_USER_INTERESTS, SAVE_AUTHOR_DATA } from '../types/userTypes'


const userInitialData = {
  userData:{},
  userInterests:{},
  allInterests: []
}

export const userData = (state = userInitialData, action) => {
  switch (action.type) {
  case SAVE_USER_DATA:
    return {
      ...state,
      userData: {...action.payload[0], maleGender: `${action.payload[0].maleGender}` } 
    }
  case REMOVE_USER_DATA:
    return {
      ...state,
      userData: {}
    }
  case SAVE_USER_INTERESTS:
    return {
      ...state,
      userInterests: action.payload
    }
  case SAVE_ALL_INTEREST_TYPES:
    return {
      ...state,
      allInterests: action.payload
    }
  case SAVE_AUTHOR_DATA:
    return{
      ...state,
      author: action.payload
    }
  default:
    return state
  }
}
