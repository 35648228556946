import PropTypes from 'prop-types'
import React from 'react'
import Tooltip from '../Tooltip/Tooltip'
// @note new component, url|children is params: for <FooterLink url={} children={}/>
const FooterLink = ({ url, children }) => {
  // @note this returns <Tooltip> or just html-link depending url value
  return (url === '#' || !url) ? <Tooltip text={'Placeholder for future use'} opacity={1} >{children}</Tooltip> : <a href={url} target="_blank" rel="noreferrer">{children}</a>
}

FooterLink.propTypes = {
  url: PropTypes.string, 
  children: PropTypes.element
}

export default FooterLink
