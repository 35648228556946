import PropTypes from 'prop-types'
import React from 'react'
import './styles.css'

const CircularDiv = ({children,style}) => {
  return (
    <div 
      className='circularDivMainContainer'
      style={style}
    >{children}</div>
  )
}

CircularDiv.propTypes = {
  children: PropTypes.element,
  style: PropTypes.string
}

export default CircularDiv
