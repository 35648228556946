import React from 'react'
import CustomizedText from './CustomizedText'
import { colors } from '../utils/colors'
import PropTypes from 'prop-types'

const VerticalImagePlusText = ({ text, image, setFillColor, fillColor, index, ...rest}) => {
  return (
    <div className="VerticalImageContainer"
      onMouseEnter={() => {
        if (setFillColor) {
          const updatedFillColors = [...fillColor]
          updatedFillColors[index] = colors?.white
          setFillColor(updatedFillColors)
        }
      }}

      onMouseLeave={() => setFillColor && setFillColor([])}
    >
      <div onClick={() => {
        if(rest?.onCopy){
          rest.onCopy(rest?.copyValue, rest?.setTooltip)
          rest?.setTooltip(true)
        }
      }} >{image}</div>
      <CustomizedText
        text={text} color={colors?.faded} textAlign={'center'}
        fontSize={15} lineHeight={'20px'} marginTop={4}
      /> 
    </div>
  )
}

VerticalImagePlusText.propTypes = {
  text: PropTypes.string, 
  image: PropTypes.element, 
  setFillColor: PropTypes.func, 
  fillColor:PropTypes.arrayOf(PropTypes.string), 
  index: PropTypes.number
}

export default VerticalImagePlusText
