import React, { useEffect, useState } from 'react'
import ScreenPaddedContainer from '../../components/ScreenPaddedContainer'
import CompleteLogo from '../../Images/CompleteLogo'
import GoogleAnalytics from '../../components/GoogleAnalytics/GoogleAnalytics'
import HorizontalDiv from '../../components/HorizontalDiv'
import Footer from '../../components/Footer/Footer'
import { useDispatch } from 'react-redux'
import { getHtml } from '../../redux/actions/PageActions'
import './styles.css'
import useResponsiveStyles from '../../utils/useResponsiveStyles'
import { Helmet } from 'react-helmet'
import NavBar from '../../components/NavBar'
import ImageComp from '../../components/imageComp/ImageComp'
import Navigator from '../../components/Navigator'

// @note new page component
export const Help = () => {
  const { handleResize } = useResponsiveStyles()
  const dispatch = useDispatch()
  const [htmlContent, setHtmlContent] = useState('')

  const origin = window.location.origin

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
    // eslint-disable-next-line
    }, [])

  useEffect(() => {
    const requestHtml = async () => {
      const content = await dispatch(getHtml({ url: '/data.raw' }))
      const params = { autoplay: 1, loop: 1, mute: 1, rel: 0, version: 3, enablejsapi: 0 }
      const p = new DOMParser()
      const dom = p.parseFromString(content, 'text/html')
      const items = [...dom.querySelectorAll('iframe[src*="youtube.com"]'),
        ...dom.querySelectorAll('iframe[src*="youtu.be"]')]
      items.forEach(elem => {
        const url = new URL(elem.src)
        url.search = ''
        url.host = 'www.youtube-nocookie.com'
        params.playlist = url.pathname.split('/').pop()
        for (const key in params) {
          url.searchParams.set(key, params[key])
        }
        elem.src = `${url.href}&origin=${origin}`
      })
      setHtmlContent(dom.body.innerHTML)
      dom.body.remove()
    }
    requestHtml()
    return () => {
      setHtmlContent(null)
    }
  }, [dispatch, origin])


  return (
    <div>
      <Helmet>
        <title>Discover How to Navigate and Maximize Your Experience | Motivation.How Help</title>
        <meta name='description' content="Explore our comprehensive help guide, tips and insights to learn how to navigate Motivation.How effectively to its fullest potential, find valuable information, and discover the most engaging content tailored to your interests."/>
      </Helmet>
      <GoogleAnalytics />
      <img className='paper_background' src={require('../../Images/paper-background.png')} alt="" />
      <ScreenPaddedContainer>
        <div className="grid grid-cols-4">
          <div className="hidden md:grid"><CompleteLogo /></div>
          <div className="md:hidden w-fit">
            <Navigator screen={'/'}>
              <ImageComp imgSrc={require('../../Images/logo.png')} width={36} height={36} />
            </Navigator>
          </div>
          <div className="col-span-3">
            <NavBar />
          </div>
        </div>
        <HorizontalDiv marginTop={76} marginBottom={78}>
          <div className="raw-html" dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </HorizontalDiv>
        <Footer />
      </ScreenPaddedContainer>
    </div>
  )
}

export default Help