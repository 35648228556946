import { SAVE_ALL_TEXTS, SAVE_CHAPTER_TEXT } from '../types/TextsTypes'

const textInitialData = {}

export const handlingText = (state = textInitialData, action) => {
  switch (action.type) {
  case SAVE_ALL_TEXTS: {
    return { ...state, ...action.payload }
  }
  case SAVE_CHAPTER_TEXT: {
    const { page, text } = action.payload
    return { ...state, ...{ [page]: text } }
  }
  default: return state
  }
}
