import axios from 'axios'

export const renewToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken')
  const config = {
    headers: {
      Authorization: refreshToken,
    },
  }


  try {
    const response = await axios.get(process.env.REACT_APP_API + 'sec/users/authenticate/renewToken', config)
    // Assuming the response contains the new token, you can extract it from the response.
    const newToken = response.data
    if (newToken) {
      // console.log("local storage token updated---> ", newToken);
      // Update the token in localStorage or wherever you store it.
      localStorage.setItem('token', newToken)
    } else {
      localStorage.clear()
      if(window?.location?.pathname === '/profile'){
        window.location.replace(null, '/login')
      }
    }
    return newToken
  } catch (error) {
    // Handle any errors that occur during token renewal.
    console.error('Token renewal failed:', error)
    localStorage.clear()
    if(window?.location?.pathname === '/profile'){
      window.location.replace('/login') //redirect when on profile page
    }

    throw error // Propagate the error up to the caller for further handling.
  }
}
