import defaultAxios from '../../utils/axiosInterceptors'
import { HIDE_SCREEN_LOADER, SHOW_SCREEN_LOADER, SHOW_TOAST } from '../types/LoaderTypes'
import { SAVE_ALL_TEXTS, SAVE_CHAPTER_TEXT } from '../types/TextsTypes'

export const getAllTexts = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_SCREEN_LOADER })
    const allTexst = await defaultAxios.get('/cache/static_text')
    dispatch({ type: SAVE_ALL_TEXTS, payload: allTexst?.data })
  }
  catch (error) {
    console.log('Error while saving topics:', error)
    dispatch({ type: SHOW_TOAST, payload: error?.response?.data || 'Can\'t load static text' })
  } finally {
    dispatch({ type: HIDE_SCREEN_LOADER })
  }
}

export const getPage = ({ page }) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_SCREEN_LOADER })
    const pageText = await defaultAxios.get(`/cache/static_text?page=${page}`)
    dispatch({ type: SAVE_CHAPTER_TEXT, payload: { page: page, text: pageText.data } })
  }
  catch (error) {
    console.log('Error while saving page]:', error)
    dispatch({ type: SHOW_TOAST, payload: error?.response?.data || 'Can\'t load static text' })
  } finally {
    dispatch({ type: HIDE_SCREEN_LOADER })
  }
}

export const getHtml = ({ url }) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_SCREEN_LOADER })
    const result = await fetch(url).then(resp => resp.text())
    return result
  }
  catch (error) {
    console.log('Error while loading data:', error)
    dispatch({ type: SHOW_TOAST, payload: error?.response?.data || 'Can\'t load static html' })
  } finally {
    dispatch({ type: HIDE_SCREEN_LOADER })
  }
}

