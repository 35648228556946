import defaultAxios from '../../utils/axiosInterceptors'
import { HIDE_SCREEN_LOADER, SHOW_SCREEN_LOADER, SHOW_TOAST } from '../types/LoaderTypes'

export const getCount =
  ({ userId, onSuccess }) =>
    async dispatch => {
      try {
        dispatch({ type: SHOW_SCREEN_LOADER })
        const response = await defaultAxios.get('/content/post', { params: { approved: false, user_id: userId, count: true } })
        onSuccess(response.data[0].count)
      } catch (error) {
        console.log('Error while saving topics:', error)
        dispatch({ type: SHOW_TOAST, payload: JSON.stringify(error.response.data) || 'Can\'t load posts list' })
      } finally {
        dispatch({ type: HIDE_SCREEN_LOADER })
      }
    }

export const pagePost =
  ({ userId, max, skip, approved, onSuccess }) =>
    async dispatch => {
      try {
        dispatch({ type: SHOW_SCREEN_LOADER })
        const headers = { 'preheat': 'preheat' }
        const params = { approved, user_id: userId, max, skip }
        const response = await defaultAxios.get('/content/post', { params, headers })
        onSuccess({ list: response.data })
      } catch (error) {
        console.log('Error while loading:', error)
        dispatch({ type: SHOW_TOAST, payload: JSON.stringify({ api: error.request.responseURL, ...error.response.data}) || 'Can\'t load posts list' })
      } finally {
        dispatch({ type: HIDE_SCREEN_LOADER })
      }
    }

export const allPosts =
  ({ userId, onSuccess, approved }) =>
    async dispatch => {
      try {
        dispatch({ type: SHOW_SCREEN_LOADER })
        const response = await defaultAxios.get('/content/post', { params: { approved, user_id: userId } })
        onSuccess(response.data)
      } catch (error) {
        console.log('Error while saving topics:', error)
        dispatch({ type: SHOW_TOAST, payload: JSON.stringify(error.response.data) || 'Can\'t load posts list' })
      } finally {
        dispatch({ type: HIDE_SCREEN_LOADER })
      }
    }

export const setApprove =
  ({ postId, userId, onSuccess }) =>
    async dispatch => {
      try {
        dispatch({ type: SHOW_SCREEN_LOADER })
        const params = { post_id: postId, user_id: userId, action: 'approve' }
        const query = new URLSearchParams(params)
        await defaultAxios.put(`/multi/posts?${query.toString()}`, params)
        onSuccess()
      } catch (error) {
        dispatch({ type: SHOW_TOAST, payload: error?.response?.data || 'Can\'t approve post' })
      } finally {
        dispatch({ type: HIDE_SCREEN_LOADER })
      }
    }

export const setHide =
  ({ postId, userId, hideComments, onSuccess }) =>
    async dispatch => {
      try {
        dispatch({ type: SHOW_SCREEN_LOADER })
        const params = { post_id: postId, user_id: userId, action: hideComments ? 'hide_comments' : 'show_comments' }
        const query = new URLSearchParams(params)
        await defaultAxios.put(`/content/post?${query.toString()}`, params)
        onSuccess()
      } catch (error) {
        dispatch({ type: SHOW_TOAST, payload: error?.response?.data || 'Can\'t set visibility' })
      } finally {
        dispatch({ type: HIDE_SCREEN_LOADER })
      }
    }

export const setPause =
  ({ postId, userId, acceptComments, onSuccess }) =>
    async dispatch => {
      try {
        dispatch({ type: SHOW_SCREEN_LOADER })
        const params = { post_id: postId, user_id: userId, action: acceptComments ? 'resume_comments' : 'pause_comments' }
        const query = new URLSearchParams(params)
        await defaultAxios.put(`/content/post?${query.toString()}`, params)
        onSuccess()
      } catch (error) {
        dispatch({ type: SHOW_TOAST, payload: error?.response?.data || 'Can\'t set accepting' })
      } finally {
        dispatch({ type: HIDE_SCREEN_LOADER })
      }
    }
