import PropTypes from 'prop-types'
import React from 'react'
import ReactPaginate from 'react-paginate'
import { useNavigate } from 'react-router-dom'
import './styles.css'

const Paginate = ({ total, current, handler, base='/posts' }) => {
  const buildHref = (num) => {
    return `${base}/${num}`
  }

  const nav = useNavigate()

  const handleChange = ({ selected }) => {
    if (handler) {
      handler(selected + 1)
    } else {
      nav(`${base}/${selected + 1}`)
    }
  }

  return <div>
    <ReactPaginate
      className='paging'
      pageCount={total}
      initialPage={current - 1}
      previousLabel='<'
      nextLabel=">"
      disableInitialCallback={true}
      hrefAllControls={false}
      hrefBuilder={buildHref}
      onPageChange={handleChange}
      activeClassName='disabled'
    />
  </div>
}

Paginate.propTypes = {
  total: PropTypes.number,
  current: PropTypes.number,
  base: PropTypes.string,
  handler: PropTypes.func
}

export default Paginate
