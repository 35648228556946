import PropTypes from 'prop-types'
import React from 'react'
import { colors } from '../utils/colors'

const CustomizedBoldText = ({
  text,
  color,
  fontSize,
  fontWeight,
  filter,
  width,
  marginLeft,
  cursor,
  marginTop,
  textAlign,
  marginRight,
  lineHeight,
  marginBottom,
  display,
  style,
  left
}) => {
  return (
    <h4
      style={{
        color: color ? color : colors?.notHighlightedText,
        fontSize: fontSize ? fontSize : '30px',
        filter: filter,
        display: display,
        width: width,
        marginLeft: marginLeft  + 'px',
        cursor: cursor,
        marginTop: marginTop + 'px',
        fontFamily: 'GilroyBold',
        textAlign: textAlign,
        marginRight: marginRight  + 'px',
        lineHeight: lineHeight ? lineHeight : '27px',
        fontWeight: fontWeight ? fontWeight : '900',
        marginBottom: marginBottom  + 'px',
        left: left,
        ...style
      }} > {text} </h4>
  )
}

CustomizedBoldText.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.number,
  filter: PropTypes.string,
  width: PropTypes.string,
  marginLeft: PropTypes.number,
  cursor: PropTypes.string,
  marginTop: PropTypes.number,
  textAlign: PropTypes.string,
  marginRight: PropTypes.number,
  lineHeight: PropTypes.string,
  marginBottom: PropTypes.number,
  display: PropTypes.string,
  style: PropTypes.object,
  left: PropTypes.number
}

export default CustomizedBoldText
