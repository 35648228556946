import React from 'react'
import './styles.css' // Create a CSS file for styling

const Loader = () => {
  return (
    <div className="circular-loader">
      <div className="spinner"></div>
    </div>
  )
}

export default Loader
