import React, { useEffect, useState } from 'react'
import ImageCompWithTitle from '../ImageCompWithTitle'
import SlickSliderComp from '../SlickSliderComp/SlickSliderComp'
import HorizontalDiv from '../HorizontalDiv'
import PropTypes from 'prop-types'

const ConditionalSlider = ({
  array,
  setShowModal,
  setVideoId,
  setVideoSrc,
  topic,
  author
}) => {
  const [width, setWidth] = useState(
    window.innerWidth >= 1600
      ? '350px'
      : window.innerWidth >= 1200
        ? '380px'
        : window.innerWidth >= 992
          ? '350px'
          : window.innerWidth >= 576
            ? '350px'
            : '350px'
  )
  const [left, setPlayButtonLeft] = useState(
    window.innerWidth >= 1800
      ? '42%'
      : window.innerWidth >= 1600
        ? '40%'
        : window.innerWidth >= 1200
          ? '39%'
          : window.innerWidth >= 992
            ? '36%'
            : window.innerWidth >= 576
              ? '39%'
              : '39%'
  )
  const [size, setSize] = useState(
    window.innerWidth >= 992 ? '80' : window.innerWidth >= 768 ? '60' : '80'
  )

  const handleResize = () => {
    if (window.innerWidth >= 1800) {
      setPlayButtonLeft('42%')
    } else if (window.innerWidth >= 1600) {
      setWidth('350px')
      setPlayButtonLeft('40%')
    } else if (window.innerWidth >= 1200) {
      setWidth('380px')
      setPlayButtonLeft('39%')
    } else if (window.innerWidth >= 992) {
      setWidth('300px')
      setPlayButtonLeft('36%')
      setSize('80')
    } else if (window.innerWidth >= 768) {
      setWidth('300px')
      setPlayButtonLeft('39%')
      setSize('60')
    } else if (window.innerWidth >= 576) {
      setWidth('350px')
      setPlayButtonLeft('39%')
      setSize('80')
    } else {
      setWidth('350px')
      setPlayButtonLeft('39%')
      setSize('80')
    }
  }

  const buildAlt = (topic, author, video) => {
    const list = { why: 'Why?', what: 'What?', 'add-on': 'Add-on' }
    const id = video.videoId
    const section = ['why', 'what', 'add-on']
      .map((sect) => {
        return author[sect].some((item) => item.videoId === id) ? sect : false
      })
      .filter((v) => v)
      .pop()
    const tail =
      author[section].length > 1 ? ` Part ${video.sequenceNumber}` : ''
    const alt = `“${topic.topicName}”. ${list[section]} Video${tail} (by ${author.authorName})`
    return alt
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return array.length > 3 ? (
    <div style={{ marginTop: 21 }}>
      <SlickSliderComp
        data={array.map((elem, index) => {
          let imagePath = elem?.videoIcon?.split('/')
          imagePath = imagePath?.[imagePath.length - 1]
          imagePath = imagePath
            ? `videosThumbnails/${imagePath}`
            : 'dummyImages/NoVideo.png'
          return (
            <ImageCompWithTitle
              alt={buildAlt(topic, author, elem)}
              key={'More-' + index}
              cursor={'pointer'}
              imgSrc={require(`../../Images/${imagePath}`)}
              width={width}
              borderRadius={20}
              showPlayButton
              playButtonLeft={left}
              playButtonTop={'32%'}
              size={size}
              text={`Part ${elem?.sequenceNumber}`}
              color={'black'}
              onClick={() => {
                setVideoSrc(elem.videoLink1)
                setShowModal(true)
                setVideoId(elem?.videoId)
              }}
            />
          )
        })}
        slides={3}
        length={array.length}
      />
    </div>
  ) : (
    <HorizontalDiv marginTop={21} justifyContent={'flex-start'} gap={41}>
      {array.length > 0
        ? array.map((elem, index) => {
          let imagePath = elem?.videoIcon?.split('/')
          imagePath = imagePath?.[imagePath.length - 1]
          imagePath = imagePath
            ? `videosThumbnails/${imagePath}`
            : 'dummyImages/NoVideo.png'
          return (
            <ImageCompWithTitle
              alt={buildAlt(topic, author, elem)}
              cursor={'pointer'}
              key={'Less-' + index}
              imgSrc={require(`../../Images/${imagePath}`)}
              width={width}
              borderRadius={20}
              showPlayButton
              playButtonTop={'32%'}
              size={'80'}
              text={`Part ${elem?.sequenceNumber}`}
              color={'black'}
              onClick={() => {
                setVideoSrc(elem.videoLink1)
                setVideoId(elem.videoId)
                setShowModal(true)
              }}
            />
          )
        })
        : null}
    </HorizontalDiv>
  )
}

ConditionalSlider.propTypes = {
  array: PropTypes.array,
  setShowModal: PropTypes.func,
  setVideoId: PropTypes.func,
  setVideoSrc: PropTypes.func,
  topic: PropTypes.object,
  author: PropTypes.object
}

export default ConditionalSlider
