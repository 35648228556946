import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import './styles.css'
import CustomizedMediumText from '../../components/CustomizedMediumText'
import CustomizedButton from '../../components/CustomizedButton'
import { signupInitialValues, signupSchema } from '../../utils/formikSchemas'
import { useDispatch } from 'react-redux'
import { authenticateSignup, getCountries, getIpAddress } from '../../redux/actions/userActions'
import { useNavigate } from 'react-router-dom'
// @note import new component
import SSLLogo from '../../Images/SSLLogo'
import Navigator from '../../components/Navigator'
import { Helmet } from 'react-helmet'

const Signup = () => {
  //states
  const [submitPressed, setSubmitPressed] = useState(false)
  const [countries, setCountries] = useState([])
  const [selectedCountry, setSelectedCountry] = useState('228')
  const [ipAddress, setIpAddress] = useState('')

  // console.log("selectedCountry--> ",selectedCountry);

  //route handler
  const navigate = useNavigate()

  //redux handlers
  const dispatch = useDispatch()

  //formik
  const formik = useFormik({
    initialValues: signupInitialValues,
    validationSchema:signupSchema,
    onSubmit: (values) => {
      if(selectedCountry === '228'){
        values.us_zip_code && dispatch(authenticateSignup({...values,ip_addr:ipAddress,country_id:selectedCountry},navigate))
      }
      else dispatch(authenticateSignup({...values,ip_addr:ipAddress,country_id:selectedCountry},navigate))
    },
  })

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values:{first_name,last_name,email,us_zip_code,male_gender,birth_day},
    errors,
  } = formik

  const getListOfCountries = async() => {
    const listOfCountries = await dispatch(getCountries())
    setCountries(listOfCountries)
  }

  const gettingIpAddress = async() => {
    const ip = await dispatch(getIpAddress())
    ip && setIpAddress(ip)
  }


  useEffect(()=>{
    getListOfCountries()
    gettingIpAddress()
  // eslint-disable-next-line
  },[])

  // useEffect(()=>{
  //   countries?.length > 0 && setSelectedCountry(countries[0]?.countryId)
  // },[countries])

  return (
    <div className='signup-container'>
      <Helmet>
        <title>Sign Up | Join Our Learning Community | Motivation.How</title>
        <meta name='description' content='Register with Motivation.How to dive into engaging Science and Tech content. Join our community and start your journey of discovery and growth today!'/>
      </Helmet>
      <div className='signup-form'>
        <SSLLogo css={{ transform: 'translateY(140%)' }} />
        <div className='signup-logo'>
          <img src={require('../../Images/motivatonLogo.png')} alt="LOGO" />
          <h1 style={{fontSize:40, lineHeight:'53px', fontWeight:'bolder'}}>Sign up</h1>
        </div>
        <form >
          <div className='name-container'>
            <div>
              <CustomizedMediumText text={'First Name'} fontSize={16} lineHeight={'24px'}/>
              <input
                type="text"
                name="first_name"
                value={first_name}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder='First Name'
                className={(submitPressed && errors.first_name) ? 'inputError' : null}
              />
              {(submitPressed && errors.first_name ) && (
                <div className="error">{errors.first_name}</div>
              )}
            </div>
            <div>
              <CustomizedMediumText text={'Last Name'} fontSize={16} lineHeight={'24px'}/>
              <input
                type="text"
                name="last_name"
                value={last_name}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder='Last Name'
                className={(submitPressed && errors.last_name) ? 'inputError' : null}
              />
              {(submitPressed &&  errors.last_name) && (
                <div className="error">{errors.last_name}</div>
              )}
            </div>
          </div>
          <div>
            <CustomizedMediumText text={'Email'} fontSize={16} lineHeight={'24px'}/>
            <input
              type="email"
              name="email"
              value={email}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder='Enter your email'
              className={(submitPressed && errors.email) ? 'inputError' : null}
            />
            {(submitPressed && errors.email) && (
              <div className="error">{errors.email}</div>
            )}
          </div>
          <div className='info-container'>
            <div>
              <CustomizedMediumText text={'Country'} fontSize={16} lineHeight={'24px'}/>
              <select
                name="country_id"
                value={selectedCountry}
                onChange={(e)=>setSelectedCountry(e.target.value)}
                onBlur={handleBlur}
                className={(submitPressed && !selectedCountry) ? 'inputError' : null}
              >
                {countries?.length > 0 && countries?.map(elem => {
                  return(
                    <option
                      value={elem?.countryId}
                      key={elem?.countryId}
                    // selected={(selectedCountry ?? 228) === elem?.countryId}
                    >{elem?.countryName}</option>
                  )
                })}
              </select>
              {(submitPressed && !selectedCountry) && (
                <div className="error">{'Please select a country'}</div>
              )}
            </div>
            <div>
              {!selectedCountry || selectedCountry ==='228' ?
                (
                  <>
                    <CustomizedMediumText text={'US Zip Code'} fontSize={16} lineHeight={'24px'}/>
                    <input
                      type="text"
                      name="us_zip_code"
                      value={us_zip_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder='Enter US Zip Code'
                      className={(submitPressed && selectedCountry === '228' && errors.us_zip_code) ? 'inputError' : null}
                    />
                    {(selectedCountry ==='228' && !us_zip_code && submitPressed) && (
                      <div className="error">zip code is required with United States</div>
                    )}
                  </>
                ) : null
              }
            </div>
            <div>
              <CustomizedMediumText text={'Gender'} fontSize={16} lineHeight={'24px'}/>
              <select
                name="male_gender"
                value={male_gender}
                onChange={handleChange}
                onBlur={handleBlur}
                //default value is given in signupInitialValues
                className={(submitPressed && errors.male_gender) ? 'inputError' : null}
              >
                <option value="true">Male</option>
                <option value="false">Female</option>
                <option value="null">Prefer not to say</option>
              </select>
              {(submitPressed && errors.male_gender) && (
                <div className="error">{errors.male_gender}</div>
              )}
            </div>
            <div>
              <CustomizedMediumText text={'Birthday'} fontSize={16} lineHeight={'24px'} />
              <input
                type="date"
                name="birth_day"
                value={birth_day}
                onChange={handleChange}
                onBlur={handleBlur}
                className={(submitPressed && errors.birth_day) ? 'inputError' : null}
              />
              {(submitPressed && errors.birth_day) && (
                <div className="error">{errors.birth_day}</div>
              )}
            </div>
          </div>
          <CustomizedButton 
            marginTop={16}
            title={'Get Started'}
            color={'#ffffff'}
            onClick={() => {
              setSubmitPressed(true)
              handleSubmit()
            }}
          />
          <div className='loginLink'>
            <CustomizedMediumText text={'Already have an account?'} 
              fontSize={16} 
              lineHeight={'21px'} 
              textAlign={'center'}
              color={'#838383'}
            />
            <Navigator screen={'/login'}><span className='link'>Log in</span></Navigator>
          </div>
        </form>
      </div>
    </div>
  )
}


export default Signup
