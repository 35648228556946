import PropTypes from 'prop-types'
import React from 'react'

const AllChildrenDownArrow = ({disabled}) => {
  return (
    <svg
      className="w-8 h-8"
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.4269 60.6659L49.6469 47.3419L46.28 43.948L38.7997 51.4902L38.7987 30.9162H62.4091V57.2489L54.9289 49.7067L51.562 53.1006L64.78 66.4247L78 53.1006L74.6331 49.7067L67.1529 57.2489L67.151 26.1172H38.7985L38.7978 13H3V17.799H34.056V51.4902L26.5758 43.948L23.2089 47.3419L36.4269 60.6659Z"
        fill={!disabled ? 'black' : 'gray'}
      />
    </svg>
  )
}
AllChildrenDownArrow.propTypes = {
  disabled: PropTypes.bool
}

export default AllChildrenDownArrow
