import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { sendComment } from '../../redux/actions/BlogActions'

const CommentForm = props => {
  const { post } = props
  const { userData } = useSelector(state => state.userData)
  const dispatch = useDispatch()

  const validator = useFormik({
    initialValues: { content: '' },
    validationSchema: Yup.object({
      content: Yup.string('Add comment').max(250, 'Too long').required('Enter text')
    }),
    onSubmit: values => {
      dispatch(
        sendComment({
          userId: userData.userId,
          postId: post.postId,
          ...values
        })
      )
    }
  })

  return userData.userId && post.acceptComments ? (
    <div className='w-full'>
      <form onSubmit={validator.handleSubmit} className='flex justify-end relative'>
        <input placeholder='Your comment here' type='text' className='w-2/3 m-2 p-1 rounded-md h-10 bg-white' {...validator.getFieldProps('content')}></input>
        <input type='submit' className='border rounded bg-slate-50 px-1 h-10 m-2'></input>
      </form>
    </div>
  ) : null
}

CommentForm.propTypes = {
  post: PropTypes.object.isRequired
}

export default CommentForm
