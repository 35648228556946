import axios from 'axios'
import { renewToken } from './renewToken'

const feedBackAxiosInterCeptor = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'Authorization': localStorage.getItem('token'),
  },
})

feedBackAxiosInterCeptor.interceptors.request.use((config) => {
  const token = localStorage.getItem('token')
  if(token){
    config.headers['Authorization'] = token
  }
  return config
})
// Add a variable to track whether a token renewal is in progress to avoid multiple simultaneous renewals.
let isTokenRenewalInProgress = false

// Interceptor for global error handling and token renewal
feedBackAxiosInterCeptor.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log('originalRequest--->',error)
    // Check if the error indicates an expired token or any other condition you want to renew the token on.
    if (error.response && error.response.status === 401 && !isTokenRenewalInProgress) {
      isTokenRenewalInProgress = true
      try {
        // Renew the user's token
        const newToken = await renewToken()

        // Update the Authorization header with the new token
        // console.log('Before token update:', feedBackAxiosInterCeptor.defaults.headers.Authorization);
        feedBackAxiosInterCeptor.defaults.headers['Authorization'] = newToken
        error.config.headers['Authorization'] = newToken 
        // console.log('After token update:', feedBackAxiosInterCeptor.defaults.headers.Authorization);

        // Retry the original request with the new token
        const originalRequest = error.config
        const response = await feedBackAxiosInterCeptor(originalRequest)

        // Reset the token renewal flag
        isTokenRenewalInProgress = false

        return response
      } catch (renewalError) {
        // Handle token renewal errors, e.g., log or handle as needed
        console.error('Token renewal failed:', renewalError)
        return Promise.reject(error)
      }
    } else {
      // Handle other errors as usual
      console.error('Axios request error:', error)
      return Promise.reject(error)
    }
  }
)

export default feedBackAxiosInterCeptor
