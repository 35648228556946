import PropTypes from 'prop-types'
import React from 'react'

const PointerDiv = ({
  children,
  onClick
}) => {
  return (
    <div onClick={onClick}
      style={{cursor:'pointer'}}
    > 
      {children}
    </div>
  )
}

PointerDiv.propTypes = {
  children: PropTypes.element,
  onClick: PropTypes.func
}

export default PointerDiv
