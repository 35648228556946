import React, { useEffect, useLayoutEffect, useState } from 'react'
import ScreenPaddedContainer from '../../components/ScreenPaddedContainer'
import CustomizedBoldText from '../../components/CustimizedBoldText'
import HorizontalDiv from '../../components/HorizontalDiv'
import GradientText from '../../components/GradientText'
import FrameImageComp from '../../components/FrameImageComp/FrameImageComp'
import InfoContainer from '../../components/InfoContainer'
import { securityExpertRemarks } from '../../utils/dummyText'
import Footer from '../../components/Footer/Footer'
import Modal from '../../components/Modal/Modal'
import FeedbackForm from '../../components/FeedbackForm/FeedbackForm'
import {
  classifications,
  humanKnowledgeAndScience,
  ideaData,
  littleScientists,
  techniqueAndTechnology,
  whyUsData,
  yourHelp
} from './data'
import VideoListSection from '../../components/VideoListSection'
import CompleteLogo from '../../Images/CompleteLogo'
import GoogleAnalytics from '../../components/GoogleAnalytics/GoogleAnalytics'
import { Helmet } from 'react-helmet'
import './styles.css'
import NavBar from '../../components/NavBar'
import Navigator from '../../components/Navigator'
import ImageComp from '../../components/imageComp/ImageComp'

const About = () => {
  const [fontSize, setFontSize] = useState(
    window.innerWidth >= 1200
      ? 76
      : window.innerWidth >= 992
        ? 68
        : window.innerWidth >= 576
          ? 60
          : 52
  )
  const handleResize = () => {
    if (window.innerWidth >= 1200) {
      setFontSize(76)
    } else if (window.innerWidth >= 992) {
      setFontSize(68)
    } else if (window.innerWidth >= 576) {
      setFontSize(60)
    } else {
      setFontSize(52)
    }
  }

  useLayoutEffect(() => {
    setTimeout(() => {
      if (document.location.hash) {
        document.querySelector(document.location.hash).scrollIntoView({ behavior: 'smooth' })
      }
    }, 1000)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const [showModal, setShowModal] = useState(false)
  const [video, setVideo] = useState('')
  const [imgSrc, setImgSrc] = useState('')
  const videoSections = [
    {
      array: ideaData,
      heading: 'Idea',
      id: 'idea'
    },
    {
      array: humanKnowledgeAndScience,
      heading: 'Human knowledge and Science',
      id: 'humanKnowledgeAndScience'
    },
    {
      array: techniqueAndTechnology,
      heading: 'Technics And Technology',
      id: 'techniqueAndTechnology'
    },
    {
      array: classifications,
      heading: 'Classification',
      id: 'classifications'
    },
    {
      array: littleScientists,
      heading: 'Little scientists',
      id: 'littleScientists'
    },
    { array: yourHelp, heading: 'Your help', id: 'yourHelp' }
  ]
  return (
    <div>
      <Helmet>
        <title>About Us: Learning Science & Tech with Motivation.How</title>
        <meta
          name='description'
          content='Discover our innovative learning strategies to gain Science and Tech knowledge efficiently. Connect with Motivation.How community to grow and share.'
        />
      </Helmet>
      <GoogleAnalytics />
      <img
        className='paper_background'
        src={require('../../Images/paper-background.png')}
        alt=''
      />
      <ScreenPaddedContainer>
        <div className='grid grid-cols-4'>
          <div className='hidden md:grid'>
            <CompleteLogo />
          </div>
          <div className='md:hidden w-fit'>
            <Navigator screen={'/'}>
              <ImageComp
                imgSrc={require('../../Images/logo.png')}
                width={36}
                height={36}
              />
            </Navigator>
          </div>
          <div className='col-span-3'>
            <NavBar />
          </div>
        </div>
        <GradientText
          text={'ABOUT US'}
          bold
          fontSize={fontSize}
          textAlign={'center'}
        />
        <VideoListSection
          sectionId='whyus'
          array={whyUsData}
          setVideo={setVideo}
          setShowModal={setShowModal}
          heading={'Why Us?'}
          setImgSrc={setImgSrc}
        />
        <HorizontalDiv
          marginTop={76}
          marginBottom={78}
          justifyContent={'center'}
        >
          <FrameImageComp
            src={require('../../Images/dummyImages/Steve.png')}
            heading={'Steve Collins'}
            description={'Security Expert'}
            alt={'Photo of Steve Collins for Motivation.How testimonial'}
            marginRight={107}
          />
          <InfoContainer text={securityExpertRemarks} />
        </HorizontalDiv>
        <CustomizedBoldText
          text={'Know More About Platform?'}
          fontSize={50}
          lineHeight={'67px'}
        />
        <>
          {videoSections?.map((elem, index) => (
            <VideoListSection
              key={index}
              sectionId={elem.id}
              array={elem?.array}
              setVideo={setVideo}
              setShowModal={setShowModal}
              heading={elem?.heading}
              setImgSrc={setImgSrc}
            />
          ))}
        </>
        <Footer />
        <Modal opened={showModal} onClose={() => setShowModal(false)}>
          <FeedbackForm
            onClose={() => setShowModal(false)}
            videoSrc={video}
            imgSrc={imgSrc}
            filter
          />
        </Modal>
      </ScreenPaddedContainer>
    </div>
  )
}

export default About
