import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomizedMediumText from '../CustomizedMediumText'
import { NavLink, useNavigate } from 'react-router-dom'
import { removeUserData } from '../../redux/actions/userActions'
import SignInIcon from '../../Images/signIn'
import UserIcon from '../../Images/UserIcon'

const SignInBtn = () => {
  const [isProfileOpen, setIsProfileOpen] = useState(false)
  const [userLetter, setUserLetter] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { userData } = useSelector(state => state.userData)
  const { firstName, userId } = userData
    
  useEffect(()=>{
    setUserLetter(firstName?.charAt(0)?.toUpperCase())
  }, [setUserLetter, firstName])

  return <>
    <NavLink to={!userId ? '/login' : '/profile'}>
      {!userId && <>
        <div className="bg-white rounded-full border-2 border-orange-400 w-9 h-9 p-1 md:hidden cursor-pointer">
          <SignInIcon />
        </div>
        <div className="hidden text-xl md:block font-semibold text-nowrap mx-1 border-l-4 border-t-4 border-current px-1  hover:border-orange-400 cursor-pointer">
                    Sign In
        </div>
      </>}
    </NavLink>
    {userId && <>
      <div className='flex'>
        <button className={'h-11 w-20 bg-white text-black rounded-full -mt-1 grid grid-cols-2 items-center'}
          onClick={() => setIsProfileOpen(!isProfileOpen)}>
          <div className={isProfileOpen ? 'hidden' : ' flex flex-col space-y-1 pl-4 col-span-1'}>
            <span className="block h-0.5 w-4 animate-pulse bg-gray-600"></span>
            <span className="block h-0.5 w-4 animate-pulse bg-gray-600"></span>
            <span className="block h-0.5 w-4 animate-pulse bg-gray-600"></span>
          </div>
          <div className={isProfileOpen ? 'block transition-all duration-1000 pl-3' : 'hidden'}>
            <svg className="h-6 w-6 text-gray-600"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round">
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </div>
          <div className='w-11 h-11 rounded-full shadow-md profileDropdown cursor-pointer grid columns-1'>
            {userLetter ? (<span className="self-center text-center text-white text-2xl font-semibold leading-9">
              {userLetter}
            </span>) : (<div className="self-center justify-self-center flex text-white"><UserIcon stroke={2.5} className="w-6 h-6" /></div>)
            }
          </div>
          <div className={isProfileOpen ? 'showProfile' : 'hidden'}>
            <ul className="flex flex-col justify-between list-none">
              <li className="py-1.5">
                <NavLink to={'/profile'} className={({ isActive }) => isActive ? 'hidden md:hidden' : ''}>
                  <CustomizedMediumText text={'My Profile'} fontSize={16} lineHeight={'24px'} cursor='pointer' />
                </NavLink>
              </li>
              <li className="py-1.5">
                <CustomizedMediumText text={'Log Out'}
                  cursor={'pointer'}
                  fontSize={16} lineHeight={'24px'} onClick={() => dispatch(removeUserData(navigate))} />
              </li>
            </ul>
          </div>
        </button>
      </div>
    </>}
  </>
}

export default SignInBtn
