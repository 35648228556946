import React from 'react'

const DeleteIcon = () => {
  return (
    <svg
      className="w-8 h-8"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M68.125 9.375H56.4062V7.03125C56.4062 3.15422 53.252 0 49.375 0H30.625C26.748 0 23.5938 3.15422 23.5938 7.03125V9.375H11.875C7.99797 9.375 4.84375 12.5292 4.84375 16.4062C4.84375 19.52 6.87891 22.1658 9.68828 23.0877L13.8688 73.5525C14.1698 77.168 17.2477 80 20.8756 80H59.1244C62.7525 80 65.8303 77.168 66.1316 73.5514L70.3117 23.0875C73.1211 22.1658 75.1562 19.52 75.1562 16.4062C75.1562 12.5292 72.002 9.375 68.125 9.375ZM28.2812 7.03125C28.2812 5.73891 29.3327 4.6875 30.625 4.6875H49.375C50.6673 4.6875 51.7188 5.73891 51.7188 7.03125V9.375H28.2812V7.03125ZM61.46 73.1634C61.3595 74.3684 60.3337 75.3125 59.1244 75.3125H20.8756C19.6664 75.3125 18.6405 74.3684 18.5403 73.1645L14.4209 23.4375H65.5791L61.46 73.1634ZM68.125 18.75H11.875C10.5827 18.75 9.53125 17.6986 9.53125 16.4062C9.53125 15.1139 10.5827 14.0625 11.875 14.0625H68.125C69.4173 14.0625 70.4688 15.1139 70.4688 16.4062C70.4688 17.6986 69.4173 18.75 68.125 18.75Z"
        fill="black"
      />
      <path
        d="M30.6207 68.1363L28.2769 30.3238C28.1967 29.0318 27.0785 28.0491 25.7927 28.1296C24.5007 28.2098 23.5183 29.322 23.5983 30.6138L25.9421 68.4265C26.0191 69.6691 27.051 70.6252 28.2791 70.6252C29.6364 70.6252 30.7039 69.4821 30.6207 68.1363Z"
        fill="black"
      />
      <path
        d="M40 28.125C38.7056 28.125 37.6562 29.1744 37.6562 30.4688V68.2812C37.6562 69.5756 38.7056 70.625 40 70.625C41.2944 70.625 42.3438 69.5756 42.3438 68.2812V30.4688C42.3438 29.1744 41.2944 28.125 40 28.125Z"
        fill="black"
      />
      <path
        d="M54.2075 28.1296C52.9184 28.0494 51.8033 29.0318 51.7233 30.3238L49.3795 68.1363C49.2997 69.4282 50.282 70.5404 51.5739 70.6205C52.8666 70.7005 53.9781 69.7177 54.0581 68.4263L56.4019 30.6138C56.4819 29.3218 55.4995 28.2096 54.2075 28.1296Z"
        fill="black"
      />
    </svg>
  )
}

export default DeleteIcon
