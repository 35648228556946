import PropTypes from 'prop-types'
import React from 'react'

const CenteredDiv = ({
  children,
  marginTop,
  borderRadius,
  backgroundColor,
  padding,
  style,
  border,
  width,
  justifyContent,
  display,
  height,
  onClick,
  flexDirection
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        alignItems: 'center',
        justifyContent: justifyContent ? justifyContent : 'center',
        display: display ? display : 'flex',
        marginTop: marginTop,
        borderRadius: borderRadius,
        backgroundColor: backgroundColor,
        padding: padding,
        width: width,
        height: height,
        border: border,
        flexDirection: flexDirection,
        ...style
      }}
    >
      {children}
    </div>
  )
}

CenteredDiv.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  marginTop: PropTypes.number,
  borderRadius: PropTypes.number,
  backgroundColor: PropTypes.string,
  padding: PropTypes.number,
  style: PropTypes.object,
  border: PropTypes.string,
  width: PropTypes.number,
  justifyContent: PropTypes.string,
  display: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func,
  flexDirection: PropTypes.string
}

export default CenteredDiv
