import React, { useEffect, useState } from 'react'
import CustomizedText from '../CustomizedText'
import CustomizedTextArea from '../CustomizedTextArea/CustomizedTextArea'
import CustomInput from '../CustomInput/CustomInput'
import CustomizedButton from '../CustomizedButton'
import { colors } from '../../utils/colors'
import { useDispatch, useSelector } from 'react-redux'
import {
  feedbackPutRequest,
  getfeedbackData
} from '../../redux/actions/TopicActions'
import Modal from '../Modal/Modal'
import { ConfirmationMessage } from '../ConfirmationMessage/ConfirmationMessage'
import Tooltip from '../Tooltip/Tooltip'
import PropTypes from 'prop-types'

const FeedBackForChapters = ({
  selectedOption,
  width,
  setChapterSelectCheck,
  selectedChapter,
  fieldChecks,
  setFieldChecks
}) => {
  const { chapterFeedback } = useSelector((state) => state?.topics)
  const dispatch = useDispatch()

  const [inputValue, setInputValue] = useState('')
  const [link, setLink] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [inValidUrl, setInValidUrl] = useState(false)
  const [responseMessage, setResponseMessage] = useState()
  const [commentLengthCheck, setCommentLengthCheck] = useState(false)
  const [linkCheck, setLinkCheck] = useState(false)

  const id = localStorage.getItem('id')

  const apiFeedback = () => {
    setResponseMessage('Successfully added or updated feedback')
    setShowModal(true)
  }

  const apiErrorFeedback = (error) => {
    if (error?.response?.data?.includes('is not reachable')) {
      const startIndex = error?.response?.data.indexOf('URL "') + 'URL "'.length
      const endIndex = error?.response?.data.indexOf('"', startIndex)
      const invalidURL = error?.response?.data.substring(startIndex, endIndex)
      setResponseMessage(
        `Please enter a valid link. This link "${invalidURL}" is not reachable.`
      )
      setShowModal(true)
    } else {
      setResponseMessage('An error occurred. Please try again.')
    }
  }

  const isValidUrl = (urlString) => {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$',
      'i' // validate fragment locator
    )

    const urls = urlString.split(',').map((url) => url.trim())
    const areAllValidUrls = urls.every((url) => urlPattern.test(url))

    return areAllValidUrls
  }

  const handleInputChange = (event, input) => {
    setFieldChecks({
      inputValueCheck: false
    })
    setCommentLengthCheck(false)
    setLinkCheck(false)

    input ? setInputValue(event.target.value) : setLink(event.target.value)
  }

  const submitFeedback = () => {
    // const isValid = isValidUrl(link);
    setFieldChecks({
      inputValueCheck: false
    })
    const newFieldChecks = { ...fieldChecks }
    if (!inputValue || !selectedChapter) {
      selectedChapter
        ? setChapterSelectCheck(false)
        : setChapterSelectCheck(true)
      newFieldChecks.inputValueCheck = !inputValue
      setFieldChecks(newFieldChecks)
      return
    }
    if (inputValue?.length > 500) {
      setCommentLengthCheck(true)
      return
    }
    if (link?.length > 500) {
      setLinkCheck(true)
      return
    }
    if (link) {
      if (isValidUrl(link)) {
        setInValidUrl(false)
        dispatch(
          feedbackPutRequest(
            {
              comment: inputValue,
              links: link
            },
            selectedOption?.chapterId,
            apiFeedback,
            apiErrorFeedback
          )
        )
      } else {
        // setLink('')
        setInValidUrl(true)
      }
    } else {
      dispatch(
        feedbackPutRequest(
          {
            comment: inputValue,
            links: link
          },
          selectedOption?.chapterId,
          apiFeedback,
          apiErrorFeedback
        )
      )
    }
    // onClose()
  }

  useEffect(() => {
    if (selectedOption?.chapterId) {
      dispatch(getfeedbackData(selectedOption?.chapterId))
    }
  }, [dispatch, selectedOption?.chapterId])

  useEffect(() => {
    if (chapterFeedback) {
      setInputValue(chapterFeedback[0]?.comment ?? '')
      setLink(chapterFeedback[0]?.links ?? '')
    } else {
      setInputValue('no value')
      setLink('')
    }
  }, [chapterFeedback])

  const modalClose = () => {
    setShowModal(false)
  }

  return (
    <div style={{ marginTop: 10, width: width }}>
      <CustomizedText
        fontSize={16}
        lineHeight={'24px'}
        text={`Feedback For Chapter # ${selectedOption?.videoChapter ?? ''}`}
      />
      <CustomizedTextArea
        value={inputValue}
        onChange={(event) => handleInputChange(event, true)}
        placeholder='Problem description...'
      />
      <div style={{ color: 'red' }}>
        {fieldChecks?.inputValueCheck &&
          'Please fill the above problem description field'}
        {commentLengthCheck &&
          'Comment length is too large. Should be less than 500 characters'}
      </div>

      <Tooltip
        fromFeedBackForChaptrs={true}
        text='Please be sure that you can open weblink in your browser prior to saving feedback, e.g., https://www.britannica.com/ - is a valid URL; while, http://some-incorrect-web-site.com/ is not a valid URL'
      >
        <CustomInput
          value={link}
          onChange={(event) => handleInputChange(event, false)}
          placeholder={'Links...'}
        />
      </Tooltip>
      <div style={{ color: 'red' }}>
        {inValidUrl && 'Please enter a valid Link'}
        {linkCheck &&
          'Link length is too large. Should be less than 500 characters'}
      </div>
      <CustomizedButton
        onClick={id ? submitFeedback: null}
        marginTop={16}
        title={'Send Feedback'}
        backgroundColor={id ? colors?.feedbackColor : colors?.gray}
      />
      <Modal
        opened={showModal}
        onClose={() => modalClose()}
        comfirmation={true}
      >
        <ConfirmationMessage
          message={responseMessage}
          closeModal={modalClose}
        />
      </Modal>
    </div>
  )
}

FeedBackForChapters.propTypes = {
  selectedOption: PropTypes.object,
  width: PropTypes.string,
  setChapterSelectCheck: PropTypes.func,
  selectedChapter: PropTypes.object,
  fieldChecks: PropTypes.object,
  setFieldChecks: PropTypes.func
}

export default FeedBackForChapters
