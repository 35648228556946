import React, { useState } from 'react'
import SearchComp from '../searchComp/searchComp'
import VideoComp from '../videoComp'
import './styles.css'
import CompleteLogo from '../../Images/CompleteLogo'
import ImageComp from '../imageComp/ImageComp'
import { NavLink } from 'react-router-dom'
import NavBar from '../NavBar'
import { useSelector } from 'react-redux'
import { isDesktop } from 'react-device-detect'
import PropTypes from 'prop-types'

// TODO .env initial topic count

const SearchVideoWithTitleComp = ({ userFirstName }) => {
  const displayVideo = useSelector(state => state?.topics?.displayHeroVideo)
  const large = isDesktop
  const userId = localStorage.getItem('id')
  const [searchBar, setSearchBar] = useState(false)
  const [runSearch, setRunSearch] = useState(false)
  const toggleBar = (state) => {
    setSearchBar(state === undefined ? !searchBar : !!state)
  }
  const videoSrc = () => require(large ? '../../videos/HeroPage1280-720.mp4' : '../../videos/HeroPage640-360.mp4')

  return (
    <div className={'w-full' + (displayVideo ? ' md:pb-10' : '')} >
      <div className="grid grid-rows-1 items-center searchContainers">
        <div className="hidden lg:grid col-start-1 col-end-1">
          <CompleteLogo />
        </div>
        <div className="lg:hidden w-fit">
          <NavLink to={'/'}>
            <ImageComp imgSrc={require('../../Images/logo.png')} width={36} height={36} />
          </NavLink>
        </div>
        <div className='col-start-2 col-end-2'>
          <SearchComp searchBar={true} runSearch={runSearch} setRunSearch={setRunSearch} />
        </div>
        <div className="col-span-2 col-start-3 col-end-4">
          <NavBar />
        </div>
      </div>
      <div className='mx-4 mt-10 grid grid-cols-4 justify-between searchMobContainers md:hidden'>
        <NavLink to={'/'} onClick={() => setSearchBar(false)}>
          <img src={require('../../Images/logo.png')} className='w-9 h-9 min-w-9' alt="Motivation.How small logo" />
        </NavLink>
        <div className='flex w-full justify-end col-span-3'>
          <SearchComp searchBar={searchBar} runSearch={runSearch} setRunSearch={setRunSearch} toggleBar={toggleBar} />
          <NavBar userFirstName={userFirstName} userId={userId} toggleBar={toggleBar} searchBar={searchBar} />
        </div>
      </div>
      {displayVideo ? <div className="HeroSectionContainer">
        <VideoComp title='The joy of Learning and Stay motivated Video' src={videoSrc()} />
      </div> : ''}
    </div>
  )
}
SearchVideoWithTitleComp.propTypes = {
  userFirstName: PropTypes.string
}

export default SearchVideoWithTitleComp
