import '../types/BlogTypes'
import { STORE_COMMENTS, STORE_COUNT, STORE_POSTLIST } from '../types/BlogTypes'

const blogInitialData = { total: null, posts: {}, comments: {} }

export const handleBlog = (state = blogInitialData, action) => {
  switch (action.type) {
  case STORE_POSTLIST: {
    const { page, posts_list } = action.payload
    return { ...state, posts: { ...state.posts, [page]: posts_list } }
  }
  case STORE_COUNT: {
    return { ...state, ...action.payload }
  }
  case STORE_COMMENTS: {
    const { postId, page, total } = action.payload
    return { ...state, comments: { ...state.comments, [postId]: { ...state.comments[postId], [page]: action.payload[postId][page], total } } }
  }

  default:
    return state
  }
}
