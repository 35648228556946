import React, { useEffect, useState, useRef } from 'react'
import './styles.css'
import SearchVideoWithTitleComp from '../../components/searchVideoWithTitleComp/searchVideoWithTitleComp'
import ImageComp from '../../components/imageComp/ImageComp'
import { colors } from '../../utils/colors'
import HorizontalIconsTextContainer from '../../components/HorizontalIconsTextContainer'
import ArrowRightCircleIcon from '../../Images/ArrowRightCircleIcon'
import TopicsListsComp from '../../components/TopicsListsComp/TopicsListsComp'
import Footer from '../../components/Footer/Footer'
import Modal from '../../components/Modal/Modal'
import FeedbackForm from '../../components/FeedbackForm/FeedbackForm'
import { useDispatch, useSelector } from 'react-redux'
import { getAllTopics, getTopic } from '../../redux/actions/TopicActions'
import CircularDiv from '../../components/CircularDiv/CircularDiv'
import DownImg from '../../Images/DownImg'
import BorderConatiner from '../../components/BorderContainer'
import { checkAuthorData } from '../../redux/actions/userActions'
import useResponsiveStyles from '../../utils/useResponsiveStyles'
import GoogleAnalytics from '../../components/GoogleAnalytics/GoogleAnalytics'
import { handleScroll } from '../../utils/scrollHandler'
import { NavLink, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import CenteredDiv from '../../components/CenteredDiv'
import GradientText from '../../components/GradientText'
import { getPage } from '../../redux/actions/PageActions'

const Home = () => {
  //states
  const displayVideo = useSelector(state => state?.topics?.displayHeroVideo)
  const [hoveringAboutUs, setHoveringAboutUs] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [aboutUsData] = useState({ imgSrc: require('../../Images/dummyImages/AboutUs2.png'), videoSrc: 'https://youtu.be/45yvON37JR4' })
  const [scrollDirection, setScrollDirection] = useState('down')
  const [userData, setUserData] = useState()
  const { fontSize, handleResize, lineHeight } = useResponsiveStyles()
  //redux handlers
  const dispatch = useDispatch()
  const { allTopics } = useSelector(state => state.topics)
  const pageText = useSelector(state => state.texts)
  const { userData: userReducerData } = useSelector(state => state.userData)
  const topicsSectionRef = useRef(null)
  const container = useRef(null)

  const { link } = useParams()

  useEffect(() => {
    if (!pageText || !pageText['landing']) {
      dispatch(getPage({ page: 'landing' }))
    }
  }, [dispatch, pageText])

  useEffect(() => {
    if (link) {
      dispatch(getTopic({ link: link }))
    } else {
      dispatch(getAllTopics())
    }
    // eslint-disable-next-line
  }, [link])

  useEffect(() => {
    if (userData?.userId) {
      dispatch(checkAuthorData(userData.userId))
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setUserData(userReducerData)
  }, [userReducerData])

  const scrollToTopicsSection = () => {
    if (topicsSectionRef.current) {
      const el = topicsSectionRef.current
      container.current.scrollTo({ top: el.offsetTop, behavior: 'smooth' })
    }
  }

  return (
    <div className='relative'>
      {link && allTopics.length === 1 ? (
        <Helmet>
          <title>“{allTopics[0].topicName}” Simplified | Expert Insights | Motivation.How</title>
          <meta name='description' content={`Grasp Key Concepts of “${allTopics[0].topicName}” topic with short and powerful videos on Motivation.How. Gain expert-backed insights and simplify complex concepts.`} />
        </Helmet>
      ) : (
        <Helmet>
          <title>Learn Fast, Stay Motivated: Science & Tech Insights | Motivation.How</title>
          <meta name='description' content='Join Motivation.How and Dive into our content to spark your curiosity, boost your learning efficiency, and enhance your logic and reasoning skills.' />
          <meta name="google-site-verification" content="z8UYBVB-TE7cY0zSFptqD2LI2DGENpch4V2zCQi8h9o" />
        </Helmet>)}
      <GoogleAnalytics />
      <img className='paper_background' src={require('../../Images/paper-background.png')} alt="" />
      <div className="mainPaddedContainer" ref={container}
        onScroll={(e) => handleScroll(setScrollDirection, e.target, topicsSectionRef.current)}>
        <SearchVideoWithTitleComp userFirstName={userData?.firstName} />
        <div>
          <div className="detailsSection">
            {!link && displayVideo && (
              <div className='aboutUsSection'>
                <BorderConatiner>
                  <ImageComp imgSrc={aboutUsData?.imgSrc} width={'100%'} height={'100%'} showPlayButton borderRadius={66}
                    onClick={() => setShowModal(true)} />
                </BorderConatiner>
                <div className='aboutUsTextContainer'>
                  <p className='static-text leading-6 text-justify text-sm' dangerouslySetInnerHTML={{ __html: pageText?.landing?.part_01 }}></p>
                  <h4 style={{ margin: 0, fontSize: `${parseInt(fontSize) - 30}px`, lineHeight: `${parseInt(lineHeight) - 40}px`, textTransform: 'capitalize' }}>Want to know more?</h4>
                  <NavLink
                    to={'/about'}
                  >
                    <HorizontalIconsTextContainer
                      text={'Know more about us'}
                      leftImgSrc={require('../../Images/logo.png')}
                      onMouseEnter={() => setHoveringAboutUs(true)}
                      onMouseLeave={() => setHoveringAboutUs(false)}
                      hoveringAboutUs={hoveringAboutUs}
                      rightImgSrc={
                        <div style={{ marginLeft: hoveringAboutUs ? 40 : 10 }} className='arrowIconTransition'>
                          <ArrowRightCircleIcon stroke={hoveringAboutUs ? colors.black : null} />
                        </div>}
                    />
                  </NavLink>
                </div>
              </div>)}
            <div style={{ width: '100%' }} ref={topicsSectionRef}>
              <CenteredDiv>
                {link && allTopics.length === 1 ? (
                  <GradientText text={`Motivation.How “${allTopics[0].topicName}” Topic`} />
                ) : (<GradientText text='Motivation.How Topics' />)
                }
              </CenteredDiv>
              {allTopics?.map((elem, index) => {
                return (
                  <TopicsListsComp
                    index={index}
                    elem={elem}
                    key={elem?.topicId}
                  />
                )
              })}
            </div>
            <Footer />
          </div>
        </div>
        <Modal opened={showModal} onClose={() => setShowModal(false)}>
          <FeedbackForm onClose={() => setShowModal(false)}
            videoSrc={aboutUsData.videoSrc}
            imgSrc={aboutUsData.imgSrc}
          />
        </Modal>
        <div className="down-text" onClick={scrollToTopicsSection}>
          <CircularDiv>
            <DownImg transform={scrollDirection === 'down' ? false : true} />
          </CircularDiv>
        </div>
      </div>
    </div>
  )
}

export default Home
