//saving all topics data
export const SAVE_ALL_TOPICS = 'SAVE_ALL_TOPICS'

//saving topics details
export const SAVE_TOPICS_DETAILS = 'SAVE_TOPICS_DETAILS'

//saving feedback points from apo
export const SAVE_FEEDBACK_POINTS = 'SAVE_FEEDBACK_POINTS'

export const SAVE_FEEDBACK_CHAPTER = 'SAVE_FEEDBACK_CHAPTER'

//remove hero page video on search
export const DISPLAY_HERO_VIDEO = 'DISPLAY_HERO_VIDEO' 