import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

const NewPostButton = ({ openForm }) => {
  const { userData } = useSelector(state => state.userData)

  return (
    <div className='w-full justify-end inline-flex'>
      {userData.userId ? (
        <button
          className='newPostBtn text-nowrap border p-2 cursor-pointer rounded-none bg-white hover:bg-slate-300'
          onClick={openForm}>
          Add new post
        </button>
      ) : null}
    </div>
  )
}

NewPostButton.propTypes = {
  openForm: PropTypes.func.isRequired
}

export default NewPostButton
