import { combineReducers } from 'redux'
import { handlingTopics } from './TopicReducer'
import { loaderReducer } from './LoaderReducer'
import { userData } from './UserReducer'
import { toastReducer } from './toastReducer'
import { handlingText } from './TextsReducer'
import { offersReducer } from './OffersReducer'
import { handleBlog } from './BlogReducer'

const rootReducer = combineReducers({
  topics: handlingTopics,
  loader: loaderReducer,
  userData,
  toastReducer,
  texts: handlingText,
  blog: handleBlog,
  offers: offersReducer,
  // Add other reducers here if you have any
})

export default rootReducer
