import PropTypes from 'prop-types'
import React from 'react'

const ArrowDownIcon = ({transform}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.992"
      height="8"
      viewBox="0 0 13.992 8"
      transform={transform} 
    >
      <path
        id="Icon_ionic-ios-arrow-down"
        d="M13.186,16.835l5.291-5.295a1,1,0,0,1,1.412,0,1.008,1.008,0,0,1,0,1.416l-5.995,6a1,1,0,0,1-1.379.029L6.479,12.961a1,1,0,0,1,1.412-1.416Z"
        transform="translate(-6.188 -11.246)"
        fill="#131313"
      />
    </svg>
  )
}

ArrowDownIcon.propTypes = {
  transform: PropTypes.string
}

export default ArrowDownIcon
