import PropTypes from 'prop-types'
import React from 'react'

const DirectChildren = ({disabled}) => {
  return (
    <svg
      className="w-8 h-8"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 4.97066L40.3333 4.97066C45.8333 4.97066 50.3333 9.47066 50.3333 14.9707L50.3333 64.3707L68.1 48.1707L72.6 53.104L51.8333 72.0373C49.2 74.6707 44.7667 74.6707 42.0333 71.9373L21.4333 53.104L25.9333 48.1707L43.6667 64.3707L43.6667 14.9707C43.6667 13.1373 42.1667 11.6373 40.3333 11.6373L7 11.6373L7 4.97066Z"
        fill={!disabled ? 'black' : 'gray'}
      />
    </svg>
  )
}

DirectChildren.propTypes = {
  disabled: PropTypes.bool,
}

export default DirectChildren
