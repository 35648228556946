import PropTypes from 'prop-types'
import React from 'react'
import CustomizedBoldText from '../CustimizedBoldText'
import RadioButtonsComp from '../RadioButtonsComp/RadioButtonsComp'
import Tooltip from '../Tooltip/Tooltip'

const FeedbackSelectArea = ({
  data,
  handleChange,
  selectedOption,
  feedbackHeading,
  chapterSelectCheck
}) => {
  const id = localStorage.getItem('id')
  return (
    <div style={{width:'100%'}}>    
      <div
        className="feedbackChaptersContainer" >
        <Tooltip text="Please login for leaving a feedback">
          <CustomizedBoldText cursor='pointer' text={feedbackHeading} fontSize={18} lineHeight={'24px'}/>
        </Tooltip>
        <div style={{marginTop:13}}>
          {data?.map((elem,index) => {
            const ind = elem?.videoChapter.split('.')
            return (
              <div key={index} style={elem &&{ paddingLeft: `${ (ind.length-1) * 20 }px`}}>
                <RadioButtonsComp
                  value={`Chapter #${elem?.videoChapter}: ${elem?.startTime}-${elem?.endTime} ${elem?.name}`}
                  checked={id && selectedOption?.chapterId === elem?.chapterId}
                  onChange={() => handleChange(elem)}
                  disabled={Boolean(id) && elem?.acceptFeedback}
                />
              </div>
            )
          })}
        </div>
      </div>
      <div style={{color : 'red'}}>{chapterSelectCheck && 'Please Select the chapter'}</div>
    </div>
  )
}

FeedbackSelectArea.propTypes = {
  data: PropTypes.array,
  handleChange:PropTypes.func,
  selectedOption:PropTypes.object,
  feedbackHeading:PropTypes.string,
  chapterSelectCheck:PropTypes.bool
}

export default FeedbackSelectArea
