import PropTypes from 'prop-types'
import React from 'react'

const HorizontalDiv = ({
  children,
  justifyContent,
  width,
  border,
  onMouseEnter,
  onMouseLeave,
  maxWidth,
  marginTop,
  borderRadius,
  backgroundColor,
  padding,
  onClick,
  cursor,
  minWidth,
  marginBottom,
  className,
  alignItems,
  alignSelf,
  flexWrap,
  gap
}) => {
  return (
    <div
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: justifyContent ? justifyContent : 'space-between',
        width: width,
        border: border,
        maxWidth: maxWidth,
        marginTop: marginTop,
        alignItems: alignItems ? alignItems : 'center',
        alignSelf: alignSelf ? alignSelf : 'center',
        borderRadius: borderRadius,
        backgroundColor: backgroundColor,
        padding: padding,
        cursor: cursor,
        minWidth: minWidth,
        flexWrap: flexWrap ? flexWrap : 'wrap',
        marginBottom: marginBottom,
        gap: gap
      }}
    >
      {children}
    </div>
  )
}

HorizontalDiv.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]),
  justifyContent: PropTypes.string,
  width: PropTypes.string,
  border: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  maxWidth: PropTypes.number,
  marginTop: PropTypes.number,
  borderRadius: PropTypes.number,
  backgroundColor: PropTypes.string,
  padding: PropTypes.string,
  onClick: PropTypes.func,
  cursor: PropTypes.string,
  minWidth: PropTypes.number,
  marginBottom: PropTypes.number,
  className: PropTypes.string,
  alignItems: PropTypes.string,
  alignSelf: PropTypes.string,
  flexWrap: PropTypes.string,
  gap: PropTypes.number
}

export default HorizontalDiv
