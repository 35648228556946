import { HIDE_TOAST, SHOW_TOAST } from '../types/LoaderTypes'

const toastState = false

export const toastReducer = (state = toastState, action) => {
  switch (action.type) {
  case SHOW_TOAST:
    return action.payload
  case HIDE_TOAST:
    return false 
  default:
    return state
  }
}
