import PropTypes from 'prop-types'
import React from 'react'
import { colors } from '../utils/colors'
import CustomizedText from './CustomizedText'

const CustomizedButton = ({ marginTop, marginBottom, title, padding, onClick, backgroundColor, color }) => {
  return (
    <div
      onClick={onClick}
      style={{
        backgroundColor: backgroundColor ? backgroundColor : colors?.feedbackColor,
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 10,
        marginTop: marginTop,
        marginBottom: marginBottom,
        padding: padding ? padding : '11px',
        color: color,
        cursor: 'pointer'
      }}
    >
      <CustomizedText
        text={title}
        fontSize={12}
        lineHeight={'24px'}
        color={color}
      />
    </div>
  )
}

CustomizedButton.propTypes = {
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  title: PropTypes.string,
  padding: PropTypes.string,
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  color: PropTypes.string
}

export default CustomizedButton
