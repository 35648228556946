import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'

const BlogLink = ({ hide = false }) => {

  return <NavLink to={'/posts'} className={({ isActive }) => isActive ? 'hidden md:hidden' : ''}>
    <div className={hide ? 'hidden' : 'bg-white rounded-full border border-orange-400 w-9 h-9 p-1 md:hidden'}>
      <img src="/blogIcon.png" alt='Blog link' />
    </div>
    <div className={'hidden md:inline-block text-xl font-semibold mx-1 border-l-4 border-t-4 border-current px-1  hover:border-orange-400 cursor-pointer'}>
            Posts
    </div>
  </NavLink>
}

BlogLink.propTypes = {
  hide: PropTypes.bool
}

export default BlogLink
