import PropTypes from 'prop-types'
import React from 'react'
export default function SignInIcon({ className = 'w-6 h-6' }) {
  return <svg xmlns="http://www.w3.org/2000/svg" transform="scale(-1)" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75" />
  </svg>
}

SignInIcon.propTypes = {
  className: PropTypes.string,
}
