import PropTypes from 'prop-types'
import React from 'react'

const GradientText = ({
  text,
  fontSize,
  fontWeight,
  width,
  marginLeft,
  cursor,
  marginTop,
  marginBottom,
  textAlign,
  marginRight,
  lineHeight,
  style,
  border,
  onClick,
  bold
}) => {
  return (
    <div
      className='gradient-text'
      onClick={onClick}
      style={{
        fontSize: fontSize ? fontSize : '30px',
        fontWeight: fontWeight ? fontWeight : '600',
        width: width ,
        marginLeft : marginLeft,
        marginBottom : marginBottom,
        cursor : cursor,
        marginTop : marginTop,
        fontFamily: bold ? 'GilroyBold' :'Gilroy',
        textAlign : textAlign,
        marginRight : marginRight,
        lineHeight : lineHeight,
        border : border,
        ...style
      }}
    >
      <h1>
        {text}
      </h1>
    </div>
  )
}

GradientText.propTypes = {
  text: PropTypes.string,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.string,
  width: PropTypes.number,
  marginLeft: PropTypes.number,
  cursor: PropTypes.string,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  textAlign: PropTypes.string,
  marginRight: PropTypes.number,
  lineHeight: PropTypes.number,
  style: PropTypes.string,
  border: PropTypes.number,
  onClick: PropTypes.func,
  bold: PropTypes.bool
}

export default GradientText
