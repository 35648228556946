import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import BlogPost from '../../components/BlogPost/BlogPost'
import { allPosts, getCount, pagePost, setApprove, setHide, setPause } from '../../redux/actions/AdminActions'
import { checkAccess } from '../../redux/actions/userActions'
import AdminNav from './AdminNav'

const Admin = () => {
  const userId = Number(localStorage.getItem('id'))
  const { userData } = useSelector(state => state.userData)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [currentList, setCurrentList] = useState({ type: 'p', list: null, count: null, page: 1, paging: 10 })
  const img = require('../../Images/Login.png')

  const refresh = () => {
    setCurrentList({ ...currentList, list: null })
  }

  const paging = () => {
    const items = []
    for (let i = 0; i <= parseInt(currentList.count / currentList.paging); i++) {
      items.push(
        <button
          disabled={currentList.page - 1 === i ? true : null}
          onClick={() => setCurrentList({ ...currentList, page: i + 1, list: null })}
          className={`${currentList.page - 1 === i ? 'text-gray-400 font-bold' : 'border'} rounded text-orange-400 border-orange-200 px-1 ml-1`}
          key={i}>
          {i + 1}
        </button>
      )
    }
    return items
  }

  const approvePost = ({ postId }) => {
    dispatch(setApprove({ userId, postId, onSuccess: refresh }))
  }

  const toggleAccept = ({ postId, acceptComments }) => {
    dispatch(setPause({ userId, postId, acceptComments: !acceptComments, onSuccess: refresh }))
  }

  const toggleHide = ({ postId, hideComments }) => {
    dispatch(setHide({ userId, postId, hideComments: !hideComments, onSuccess: refresh }))
  }

  useEffect(() => {
    if (!userId) {
      navigate('/login')
    }
  }, [userId])

  useEffect(() => {
    // for future usage
    if (userData.userId < -1) {
      dispatch(checkAccess(userData.userId, console.log))
    }
  }, [userData])

  useEffect(() => {
    if (userData?.userId && !currentList.list) {
      switch (currentList.type) {
      case 'ua':
        dispatch(allPosts({ userId: userData.userId, approved: false, onSuccess: data => setCurrentList({ type: 'ua', list: data }) }))
        break
      default:
        if (!currentList.count) {
          dispatch(getCount({ userId, onSuccess: count => setCurrentList({ ...currentList, count }) }))
        } else if (!currentList.list) {
          dispatch(
            pagePost({
              userId: userData.userId,
              approved: currentList.type === 'c',
              max: currentList.paging,
              skip: ((currentList.page || 1) - 1) * currentList.paging,
              onSuccess: data => {
                setCurrentList({ ...currentList, ...data })
              }
            })
          )
        }
      }
    }
  }, [currentList, userData.userId])

  return (
    <div className='m-0 w-screen fixed h-screen top-0 left-0 overflow-y-auto' style={{ backgroundImage: `url("${img}")`, backgroundSize: 'cover' }}>
      <AdminNav
        setType={type => setCurrentList({ ...currentList, type, list: null, count: null, page: 1, paging: type === 'ua' ? null : currentList.paging || 10 })}
        setPaging={val => setCurrentList({ ...currentList, paging: val, list: null, page: 1 })}
        paging={currentList.paging}
      />
      <div className='flex flex-col bg-transparent w-full items-center' style={{ minHeight: '90%' }}>
        <div className='mt-12 md:w-1/2'>
          {currentList.list?.length > 0 ? (
            <>
              {currentList.list.map((data, idx) => (
                <div key={idx} className='bg-white m-1 p-2 rounded w-full grid-flow-row grid'>
                  <BlogPost data={{ ...data, hideComments: true }} />
                  <div>
                    {'uap'.indexOf(currentList.type) > -1 ? (
                      <button className='float-end border border-slate-300 px-1 mr-1 rounded bg-green-200' onClick={() => approvePost(data)}>
                        Approve
                      </button>
                    ) : (
                      <>
                        <button className='float-end border border-slate-500 mr-1 px-1 rounded' onClick={() => toggleHide(data)}>
                          {data.hideComments ? 'Show' : 'Hide'}
                        </button>
                        <button className='float-end border border-slate-500 mr-1 px-1 rounded' onClick={() => toggleAccept(data)}>
                          {data.acceptComments ? 'Mute' : 'Unmute'}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              ))}
              <div className='bg-white p-2 ml-1 rounded w-full text-right'>{paging()}</div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Admin
