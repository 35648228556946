// import apiInterceptor from "../../utils/ApiIntenpmrceptor";
import feedBackAxiosInterCeptor from '../../utils/axiosFeedbackInterCeptor'
import defaultAxios from '../../utils/axiosInterceptors'
import sideInterceptor from '../../utils/sideIntercerptor'
import { HIDE_SCREEN_LOADER, SHOW_SCREEN_LOADER } from '../types/LoaderTypes'
import { SAVE_ALL_TOPICS, SAVE_FEEDBACK_POINTS, SAVE_TOPICS_DETAILS, DISPLAY_HERO_VIDEO, SAVE_FEEDBACK_CHAPTER } from '../types/TopicTypes'

export const getAllTopics = () => async(dispatch) => {
  try {
    dispatch({type:SHOW_SCREEN_LOADER})
    // const allTopics = await defaultAxios.get('fe/topics/agg');
    const allTopics = await sideInterceptor.get('cache/starttopicslist')
    // const allTopics = await axios.get('cache/starttopicslist')
    dispatch({type: SAVE_ALL_TOPICS, payload:allTopics?.data})
    dispatch({type: DISPLAY_HERO_VIDEO, payload:true})
    dispatch({type:HIDE_SCREEN_LOADER})
  }
  catch (error){
    console.log('Error while saving topics:', error)
    dispatch({type:HIDE_SCREEN_LOADER})
  }
}

export const saveTopicsDetails = (id) => async(dispatch) => {
  try {
    dispatch({type:SHOW_SCREEN_LOADER})
    // const details = await defaultAxios.get(`fe/authorstopics/${id}`);
    const details = await sideInterceptor.get(`cache/authorstopics/${id}`)
    dispatch({type: SAVE_TOPICS_DETAILS, payload:details.data})
    dispatch({type:HIDE_SCREEN_LOADER})
  }
  catch (error){
    console.log('Error while saving topics:', error)
    dispatch({type:HIDE_SCREEN_LOADER})
  }
}

export const getTopic = ({ link }) => async(dispatch) => {
  try {
    dispatch({type:SHOW_SCREEN_LOADER})
    const allTopics = await defaultAxios.get(`multi/topics?link=${link}`)
    dispatch({type: SAVE_ALL_TOPICS, payload:allTopics.data})
    dispatch({type: DISPLAY_HERO_VIDEO, payload:false})
    dispatch({type:HIDE_SCREEN_LOADER})
  }
  catch (error){
    console.log('Error while saving topics:', error)
    dispatch({type:HIDE_SCREEN_LOADER})
  }
}

export const filterTopics = (substring) => async(dispatch) => {
  try {
    dispatch({type:SHOW_SCREEN_LOADER})
    const allTopics = await defaultAxios.get(`multi/topics?subname=${substring}`)
    dispatch({type: SAVE_ALL_TOPICS, payload:allTopics.data})
    dispatch({type: DISPLAY_HERO_VIDEO, payload:false})
    dispatch({type:HIDE_SCREEN_LOADER})
  }
  catch (error){
    console.log('Error while saving topics:', error)
    dispatch({type:HIDE_SCREEN_LOADER})
  }
}

export const saveFeedbackPoints = (id) => async(dispatch) => {
  try {
    dispatch({type:SHOW_SCREEN_LOADER})
    const details = await sideInterceptor.get(`cache/videos/${id}`)
    dispatch({type: SAVE_FEEDBACK_POINTS, payload:details?.data})
    dispatch({type:HIDE_SCREEN_LOADER})
  }
  catch (error){
    console.log('Error while saving topics:', error)
    dispatch({type:HIDE_SCREEN_LOADER})
  }
}

export const feedbackPutRequest = (data, chapter_id, apiFeedback, apiErrorFeedback) => async (dispatch) => {
  const user_id = localStorage.getItem('id')
  try {
    dispatch({ type: SHOW_SCREEN_LOADER })
    // await feedBackAxiosInterCeptor.put(`content/feedback?user_id=${user_id}&chapter_id=${chapter_id}`, data)
    const response = await feedBackAxiosInterCeptor.put(`content/feedback?user_id=${user_id}&chapter_id=${chapter_id}`, data)
    apiFeedback(response)
    dispatch({type:HIDE_SCREEN_LOADER})
  }
  catch(error){
    console.log('Error while saving feedback', error)
    apiErrorFeedback(error)
    dispatch({type:HIDE_SCREEN_LOADER})
  }
}
export const getfeedbackData = (chapter_id) => async (dispatch) => {
  const user_id = localStorage.getItem('id')
  try {
    // console.log({chapter_id, user_id});
    dispatch({ type: SHOW_SCREEN_LOADER })
    const response = await feedBackAxiosInterCeptor.get(`content/feedback?user_id=${user_id}&chapter_id=${chapter_id}`)
    dispatch({type: SAVE_FEEDBACK_CHAPTER, payload: response.data})
    dispatch({ type: SHOW_SCREEN_LOADER, payload: false })

  } catch (error) {
    console.error(error)
  } finally {
    dispatch({ type: HIDE_SCREEN_LOADER })
  }
}