import PropTypes from 'prop-types'
import React, { useState } from 'react'
import CommentsList from '../BlogComment/CommentsList'
import GenderSign from '../GenderSign'
import BlogTag from './BlogTag'
import './styles.css'

const BlogPost = ({ data }) => {
  const [display, setDisplay] = useState(false)
  const date = new Date(Date.parse(data.initialDate)).toLocaleString()
  return (
    <div className='blogPost'>
      <div className='postMeta'>
        <GenderSign {...data.owner} />
        <div style={{ lineHeight: '1em', fontSize: 12 }} className='text-xs'>
          {date}
        </div>
      </div>
      <div className='postContent'>
        <h2>{data.title}</h2>
        <div className='postBody' dangerouslySetInnerHTML={{ __html: data.content }}></div>
        <div className='tags'>{data.tags?.map((tag, idx) => <BlogTag key={idx} name={tag} />)}</div>
      </div>
      {!data.hideComments && (
        <div className='col-start-2 mt-4'>
          <CommentsList post={data} display={display} setDisplay={setDisplay} />
        </div>
      )}
    </div>
  )
}

BlogPost.propTypes = {
  data: PropTypes.object
}

export default BlogPost
