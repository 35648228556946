import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SearchSvg from '../../Images/SearchSvg'

const SearchableSelect = ({ getInterests, addInterest }) => {
  const [options, setOptions] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [searchVal, setSearchVal] = useState('')

  const { allInterests } = useSelector((state) => state.userData)
  const handleInputChange = () => {
    const filteredData = allInterests?.filter((e) =>
      e?.toLowerCase().includes(searchVal.toLowerCase())
    )
    setOptions(filteredData)
  }

  const handleOptionClick = (data) => {
    setSearchVal(data)
    addInterest(data)
    setIsOpen(false)
  }

  useEffect(() => {
    getInterests && setOptions(allInterests)
  }, [allInterests, getInterests])
  return (
    <div className='relative mb-2'>
      <div className='flex gap-3 items-center relative'>
        <input
          type='text'
          className='relative py-2 px-3 border border-amber-500 rounded-lg text-[#f77f27] text-base focus:ring focus:ring-amber-100 focus:outline-none'
          placeholder='Search...'
          onChange={(e) => setSearchVal(e.target.value)}
          onClick={() => setIsOpen(!isOpen)}
          value={searchVal}
        />
        <p
          onClick={() => {
            setIsOpen(true)
            handleInputChange()
          }}
          className='absolute right-1'
        >
          <SearchSvg />
        </p>
      </div>
      {isOpen && (
        <div className='z-40 absolute mt-2 py-1 bg-white border border-amber-500 rounded-lg text-[#f77f27] text-base shadow-lg w-full'>
          {options?.length === 0 ? (
            <p className='px-4 py-2 text-[#f77f27]'>No data found</p>
          ) : (
            options?.map((data, index) => (
              <p
                key={index}
                className='px-4 py-2 cursor-pointer hover:bg-amber-100'
                onClick={() => handleOptionClick(data)}
              >
                {data}
              </p>
            ))
          )}
        </div>
      )}
    </div>
  )
}

SearchableSelect.propTypes = {
  getInterests: PropTypes.bool,
  addInterest: PropTypes.func
}

export default SearchableSelect
