import React from 'react'

const TwitterImg = () => {
  return (
    <svg
      id="Group_68"
      xmlns="http://www.w3.org/2000/svg"
      width="24.959"
      height="24.959"
      viewBox="0 0 24.959 24.959"
    >
      <circle
        id="Ellipse_7"
        cx="12.479"
        cy="12.479"
        r="12.479"
        fill="#131313"
      />
      <g id="Group_12" transform="translate(4.878 6.54)">
        <g id="Group_11">
          <path
            id="Path_5"
            d="M1012.3,256.448a9.364,9.364,0,0,0,9.428-9.428c0-.143,0-.286-.009-.428a6.743,6.743,0,0,0,1.653-1.716,6.607,6.607,0,0,1-1.9.522,3.326,3.326,0,0,0,1.457-1.833,6.642,6.642,0,0,1-2.1.8,3.317,3.317,0,0,0-5.647,3.022,9.408,9.408,0,0,1-6.83-3.462,3.316,3.316,0,0,0,1.026,4.424,3.292,3.292,0,0,1-1.5-.415c0,.014,0,.028,0,.042a3.315,3.315,0,0,0,2.658,3.249,3.31,3.31,0,0,1-1.5.057,3.318,3.318,0,0,0,3.1,2.3,6.648,6.648,0,0,1-4.116,1.418,6.732,6.732,0,0,1-.791-.046,9.381,9.381,0,0,0,5.08,1.488"
            transform="translate(-1007.216 -243.321)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  )
}

export default TwitterImg
