import { HIDE_SCREEN_LOADER, SHOW_SCREEN_LOADER, SHOW_TOAST } from '../types/LoaderTypes'
import { STORE_COMMENTS, STORE_COUNT, STORE_POSTLIST } from '../types/BlogTypes'
import defaultAxios from '../../utils/axiosInterceptors'

export const getPosts = ({ page }) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_SCREEN_LOADER })
    const allPosts = await defaultAxios.get('/cache/posts', { params: { page } })
    const data = {...allPosts.data}
    if (Object.keys(data).length > 0) {
      dispatch({ type: STORE_POSTLIST, payload: { page, ...allPosts.data } })
    }
    dispatch({ type: HIDE_SCREEN_LOADER })
  }
  catch (error) {
    console.log('Error while saving topics:', error)
    dispatch({ type: HIDE_SCREEN_LOADER })
    dispatch({ type: SHOW_TOAST, payload: error?.response?.data || 'Can\'t load posts list' })
  }

}

export const getCount = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_SCREEN_LOADER })
    const response = await defaultAxios.get('/cache/posts', { params: { count: 'pages' } })
    dispatch({ type: STORE_COUNT, payload: { total: response.data.count } })
    dispatch({ type: HIDE_SCREEN_LOADER })
  }
  catch (error) {
    console.log('Error while obtain count:', error)
    dispatch({ type: HIDE_SCREEN_LOADER })
    dispatch({ type: SHOW_TOAST, payload: error?.response?.data || 'Can\'t load pages count' })
  }
}

export const sendPost = ({ onSuccess, title, content, userId }) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_SCREEN_LOADER })
    const response = await defaultAxios.post(`/content/post?user_id=${userId}`, { title, content })
    onSuccess()
    dispatch({ type: SHOW_TOAST, payload: response.data })
  } catch (error) {
    console.log('Error while posting:', error)
    dispatch({ type: SHOW_TOAST, payload: error?.response?.error || 'Can\'t create posts' })
  } finally {
    dispatch({ type: HIDE_SCREEN_LOADER })
  }
}

export const getComments = ({ postId, page }) => async dispatch => {
  try {
    dispatch({ type: SHOW_SCREEN_LOADER })
    const payload = { postId, page, [postId]: { total: 0 } }
    const { data: pages } = await defaultAxios.get('/cache/comments', {
      params: { post_id: postId, count: 'pages' }
    })
    if (!isNaN(pages.count)) {
      payload.total = pages.count
      const response = await defaultAxios.get('/cache/comments', {
        params: { post_id: postId, page }
      })
      payload[postId] = { [page]: { ...response.data.comments_list } }
    }
    dispatch({
      type: STORE_COMMENTS,
      payload
    })
    dispatch({ type: HIDE_SCREEN_LOADER })
  } catch (error) {
    console.log('Error while loading:', error)
    dispatch({ type: HIDE_SCREEN_LOADER })
    dispatch({
      type: SHOW_TOAST,
      payload: error?.response?.data || 'Can\'t load comments'
    })
  }
}


export const sendComment = ({ userId, postId, content }) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_SCREEN_LOADER })
    const response = await defaultAxios.post(`/multi/comments?user_id=${userId}`, { post_id: postId, content })
    if (response.data.indexOf('was added') > -1) {
      dispatch({ type: SHOW_TOAST, payload: 'Success' })
      dispatch(getComments({ postId, page: 1 }))
    } else {
      throw response.data
    }
  } catch (error) {
    console.log('Error while commenting:', error)
    dispatch({ type: SHOW_TOAST, payload: error?.response?.data || 'Can\'t create comment' })
  }
  dispatch({ type: HIDE_SCREEN_LOADER })
}

export const clearCache = (() => async (dispatch) => {
  dispatch({ type: SHOW_SCREEN_LOADER })
  const params = { user_id: 1 }
  await defaultAxios.delete('/multi/clear_posts', { params })
  dispatch({ type: HIDE_SCREEN_LOADER })
})

export const fillCache = (() => async (dispatch) => {
  dispatch({ type: SHOW_SCREEN_LOADER })
  const params = { user_id: 1 }
  await defaultAxios.get('/multi/preheat_posts', { params })
  dispatch({ type: HIDE_SCREEN_LOADER })
})
