export const handleScroll = (setScrollDirection,container,element) => {
  if (5 + container.scrollTop < element.offsetTop) {
    setScrollDirection('down')
  } else {
    setScrollDirection('top')
  }
}

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}
  
export const scrollToBottom = () => {
  window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
}