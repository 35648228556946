import React from 'react'
import CustomizedText from '../CustomizedText'
import HorizontalDiv from '../HorizontalDiv'
import { colors } from '../../utils/colors'
import './styles.css'
import PropTypes from 'prop-types'

const RadioButtonsComp = ({ value, checked, onChange, key, disabled }) => {
  return (
    <HorizontalDiv marginTop={key !== 0 ? 7 : null}>
      <label
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '6px'
        }}
      >
        <input
          type='radio'
          value={value}
          checked={checked}
          onChange={onChange}
          className='radio-input'
          disabled={!disabled}
        />
        <CustomizedText
          text={value}
          fontSize={14}
          lineHeight={'24px'}
          color={colors?.descriptionText}
        />
      </label>
    </HorizontalDiv>
  )
}

RadioButtonsComp.propTypes = {
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  key: PropTypes.number,
  disabled: PropTypes.bool
}

export default React.memo(RadioButtonsComp)
