import PropTypes from 'prop-types'
import React from 'react'
export default function SearchLink({ toggleBar }) {
  const toggle = () => {
    if (toggleBar) toggleBar()
  }
  return <>
    <div className={ toggleBar ? 'bg-white rounded-full border border-orange-400 w-9 h-9 p-1 md:hidden': 'hidden'}
      onClick={toggle}>
      <img alt='Search field expand' src={require('../Images/search.png')} width={24} height={24} />
    </div>
  </>
}

SearchLink.propTypes = {
  toggleBar: PropTypes.func
}
