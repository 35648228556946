import PropTypes from 'prop-types'
import React from 'react'

function ShareImg({fillColor}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <g id="Group_202" data-name="Group 202">
        <path
          id="Path_46"
          data-name="Path 46"
          d="m 3,13.375 c 0,3.89798 4.7124812,5.849389 7.468435,3.093435 C 13.224389,13.712481 11.27298,9 7.375,9 4.9587542,9 3,10.958754 3,13.375"
          transform="translate(1.375 4.125)"
          fill="none"
          stroke={fillColor || 'currentColor' }
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          opacity={!fillColor ? 0.6 : 1}
        />
        <path
          id="Path_47"
          data-name="Path 47"
          d="M 19.375,3 C 16.958754,3 15,4.9587542 15,7.375 c 0,3.89798 4.712481,5.849389 7.468435,3.093435 C 25.224389,7.7124812 23.27298,3 19.375,3 Z"
          transform="translate(6.875 1.375)"
          fill="none"
          stroke={fillColor || 'currentColor' }
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          opacity={!fillColor ? 0.6 : 1}
        />
        <path
          id="Path_48"
          data-name="Path 48"
          d="M19.375,19.375m-4.375,0A4.375,4.375,0,1,0,19.375,15,4.375,4.375,0,0,0,15,19.375"
          transform="translate(6.875 6.875)"
          fill="none"
          stroke={fillColor || 'currentColor' }
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          opacity={!fillColor ? 0.6 : 1}
        />
        <path
          id="Path_49"
          data-name="Path 49"
          d="M8.7,12.258,18.325,7.3"
          transform="translate(3.987 3.346)"
          fill="none"
          stroke={fillColor || 'currentColor' }
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          opacity={!fillColor ? 0.6 : 1}
        />
        <path
          id="Path_50"
          data-name="Path 50"
          d="M8.7,13.3l9.625,4.958"
          transform="translate(3.987 6.096)"
          fill="none"
          stroke={fillColor || 'currentColor' }
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          opacity={!fillColor ? 0.6 : 1}
        />
      </g>
    </svg>
  )
}

ShareImg.propTypes = {
  fillColor: PropTypes.string
}

export default ShareImg
