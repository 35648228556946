import PropTypes from 'prop-types'
import React from 'react'
import './styles.css'

const CustomizedTextArea = ({
  value,
  onChange,
  placeholder
}) => {
  return (
    <textarea
      value={value}
      onChange={onChange}
      className={'problemDescriptionInput'}
      placeholder={placeholder}
    />
  )
}

CustomizedTextArea.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
}

export default CustomizedTextArea
