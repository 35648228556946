import { useFormik } from 'formik'
import React from 'react'
import { useId } from 'react'
import './styles.css'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { sendPost } from '../../redux/actions/BlogActions'
import PropTypes from 'prop-types'

const NewPostForm = ({ onSuccess }) => {
  const taId = useId()
  const titId = useId()
  const dispatch = useDispatch()
  const { userData } = useSelector(state => state.userData)

  const schema = Yup.object({
    title: Yup.string().max(100, 'Must be 100 characters or less').required('Required'),
    content: Yup.string().max(3000, 'Must be 3000 characters or less').required('Required')
  })

  const validator = useFormik({
    initialValues: {
      title: '',
      content: ''
    },
    validationSchema: schema,
    onSubmit: values =>
      dispatch(
        sendPost({
          onSuccess,
          userId: userData.userId,
          ...values
        })
      )
  })

  return (
    <form onSubmit={validator.handleSubmit}>
      <div className='postForm grid grid-cols-6 p-4'>
        <h2 className='col-span-6 text-xl font-bold'>New post</h2>
        <label htmlFor={titId}>Post title:</label>
        <div className='col-span-5 relative'>
          <input
            className={`border p-1 w-full ${validator.errors.title ? 'is-invalid' : null}`}
            id={titId}
            type='text'
            {...validator.getFieldProps('title')}></input>
          {validator.touched.title && validator.errors.title ? <small className='text-pink-500 absolute right-2 top-1'>{validator.errors.title}</small> : null}
        </div>
        <label htmlFor={taId}>Post content:</label>
        <div className='col-span-5 relative'>
          <textarea
            id={taId}
            className={`border min-h-40 p-1 w-full ${validator.errors.content ? 'is-invalid' : null}`}
            {...validator.getFieldProps('content')}></textarea>
          {validator.touched.body && validator.errors.content ? (
            <small className='text-pink-500 absolute right-2 top-1'>{validator.errors.content}</small>
          ) : null}
        </div>
        <input
          disabled={validator.isValid ? undefined : true}
          type='submit'
          className='link cursor-pointer border text-nowrap w-fit p-2 ml-0 hover:bg-slate-300 disabled:bg-pink-200 disabled:cursor-not-allowed'></input>
      </div>
    </form>
  )
}

NewPostForm.propTypes = {
  onSuccess: PropTypes.func
}

export default NewPostForm
