import React from 'react'

function BellImg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <g id="Group_16" data-name="Group 16">
        <path id="Path_17" data-name="Path 17" d="M0,0H35V35H0Z" fill="none" />
        <path
          id="Path_18"
          data-name="Path 18"
          d="M12.75,5.917a2.917,2.917,0,0,1,5.833,0,10.208,10.208,0,0,1,5.833,8.75v4.375a5.833,5.833,0,0,0,2.917,4.375H4a5.833,5.833,0,0,0,2.917-4.375V14.667a10.208,10.208,0,0,1,5.833-8.75"
          transform="translate(1.833 1.375)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_19"
          data-name="Path 19"
          d="M9,17v1.458a4.375,4.375,0,1,0,8.75,0V17"
          transform="translate(4.125 7.792)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  )
}

export default BellImg
