import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { getPayment } from '../../redux/actions/offerActions'
import { getPage } from '../../redux/actions/PageActions'
import CenteredDiv from '../../components/CenteredDiv'
import GradientText from '../../components/GradientText'
import CompleteLogo from '../../Images/CompleteLogo'

const Payment = () => {
  const [searchParams] = useSearchParams()
  const [payment, setPayment] = useState(null)
  const currentText = useSelector(state => state.texts)
  const dispatch = useDispatch()


  const getText = (obj, strings = []) => {
    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        strings.push(obj[key])
      } else if (typeof obj[key] === 'object') {
        getText(obj[key], strings)
      }
    }
    return <div dangerouslySetInnerHTML={{ __html: strings.join() }} />
  }

  useEffect(() => {
    let page = ''
    switch (searchParams.get('result')) {
    case 'success':
      page = 'success_fund'
      break
    case 'cancel':
      page = 'cancel_fund'
      break
    default:
      page = ''
    }
    if (page) dispatch(getPage({ page: page }))

    if (searchParams.get('id') && searchParams.get('result') !== 'cancel') {
      dispatch(getPayment(searchParams.get('id'), setPayment))
    }
  }, [dispatch, searchParams])


  return <div className="signIn-container">
    <div className="signIn-form">
      <CompleteLogo />
      <CenteredDiv padding='2em 2em 0 0'>
        <GradientText text='Fund / Support us' />
      </CenteredDiv>
      <CenteredDiv>
        {getText(currentText)}
      </CenteredDiv>
      <CenteredDiv>
        {payment?.receiptUrl ? (
          <a rel="noreferrer" target="_blank" className="submitButton" href={payment.receiptUrl}>Your receipt for {payment.amount / 100} {payment.currency}</a>
        ) : <>{payment?.amount ? `Check your email please to take receipt for ${payment.amount / 100} ${payment.currency}` : ''}</>
        }
      </CenteredDiv>
    </div>
  </div>
}
export default Payment