import React from 'react'

const GraduatingHat = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="28.821"
      viewBox="0 0 35 28.821"
    >
      <path
        id="Path_52"
        d="M1304.749,473.069c-.611.355-1.2.68-1.764,1.039a.546.546,0,0,0-.158.405c-.01,1.769-.021,3.538,0,5.307a4.051,4.051,0,0,1-2.225,3.849c-1.911,1.07-3.789,2.2-5.7,3.272a8.107,8.107,0,0,1-8.395.006c-1.978-1.1-3.932-2.251-5.891-3.387a3.864,3.864,0,0,1-2.041-3.537q-.013-2.747,0-5.494a.525.525,0,0,0-.307-.526q-1.993-1.14-3.972-2.305a1.923,1.923,0,0,1,.009-3.484q7.165-4.169,14.336-8.327a3.847,3.847,0,0,1,4.084-.012q7.208,4.169,14.4,8.364a1.929,1.929,0,0,1,.06,3.42.566.566,0,0,0-.23.4q-.019,4.709,0,9.419a.632.632,0,0,0,.189.4,1.916,1.916,0,1,1-2.532-.023.532.532,0,0,0,.169-.336q.013-4.167,0-8.335A.611.611,0,0,0,1304.749,473.069Zm-29.155-3.113c.169.1.292.178.417.251,4.456,2.589,8.921,5.162,13.36,7.778a2.337,2.337,0,0,0,2.641.008c4.473-2.634,8.969-5.226,13.457-7.834.1-.058.193-.125.319-.206-.127-.078-.2-.124-.276-.168q-6.829-3.967-13.654-7.941a2.112,2.112,0,0,0-2.344.013c-3.671,2.156-7.357,4.284-11.038,6.422C1277.532,468.827,1276.589,469.377,1275.594,469.957Zm5.13,5.482v.455c0,1.333.014,2.666-.005,4a1.96,1.96,0,0,0,1.1,1.882c1.9,1.069,3.778,2.169,5.666,3.254a6.028,6.028,0,0,0,6.354.043c1.96-1.111,3.9-2.251,5.858-3.37a1.812,1.812,0,0,0,.975-1.684q0-2.149,0-4.3c0-.068-.01-.137-.021-.262-.173.1-.3.169-.425.241q-3.713,2.156-7.427,4.311a3.944,3.944,0,0,1-4.217-.014l-5.391-3.133C1282.392,476.4,1281.6,475.944,1280.724,475.439Z"
        transform="translate(-1273.194 -459.296)"
        fill="currentColor"
      />
    </svg>
  )
}

export default GraduatingHat
