import PropTypes from 'prop-types'
import React from 'react'

const BorderContainer = ({children}) => {
  return (
    <div className='gradientBorder'>
      {children}
    </div>

  )
}

BorderContainer.propTypes = {
  children: PropTypes.element
}

export default BorderContainer
