import PropTypes from 'prop-types'
import React from 'react'
import GenderSign from '../GenderSign'

const CommentCard = ({ data }) => {
  const date = new Date(Date.parse(data.commentDate)).toLocaleString()
  return (
    <div className='flex'>
      <div className='userPortrait'><GenderSign {...data.owner} /><small>{date}</small></div>
      <div className='commentCard w-full block bg-slate-50 shadow rounded-md m-2 p-2'>{data.commentText}</div>
    </div>
  )
}

CommentCard.propTypes = {
  data: PropTypes.object
}

export default CommentCard
