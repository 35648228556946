import PropTypes from 'prop-types'
import React from 'react'
import PlayButton from '../../Images/PlayButton'

const ImageComp = ({
  imgSrc,
  width,
  height,
  borderRadius,
  cursor,
  marginLeft,
  showPlayButton,
  onClick,
  left,
  filter
}) => {
  return (
    <div style={{ position: showPlayButton ? 'relative' : 'static' }}>
      <img
        src={imgSrc}
        alt='about us'
        style={{
          width: typeof width === 'string' ? width : width + 'px',
          height: typeof height === 'string' ? height : height + 'px',
          borderRadius: borderRadius,
          cursor: cursor,
          marginLeft: marginLeft,
          filter: filter
        }}
      />
      {showPlayButton && (
        <div
          onClick={onClick}
          style={{
            position: 'absolute',
            top: '35%',
            left: left ? left : '40%',
            cursor: 'pointer',
            filter: ' drop-shadow(#9797a5 1px 1px 1px)'
          }}
        >
          <PlayButton />
        </div>
      )}
    </div>
  )
}

ImageComp.propTypes = {
  imgSrc: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderRadius: PropTypes.number,
  cursor: PropTypes.string,
  marginLeft: PropTypes.number,
  showPlayButton: PropTypes.bool,
  onClick: PropTypes.func,
  left: PropTypes.number,
  filter: PropTypes.string
}

export default ImageComp
