import { SHOW_SCREEN_LOADER, HIDE_SCREEN_LOADER, SHOW_TOAST } from '../types/LoaderTypes'
import { SAVE_OFFERS } from '../types/OffersTypes'
import defaultAxios from '../../utils/axiosInterceptors'

export const getPayment = (id, setPayment) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_SCREEN_LOADER })
    const result = await defaultAxios.post('/pmts/getPaymentDetailsForUser', { id: id })

    setPayment(result?.data)
  } catch (error) {
    console.log('Error while loading data:', error)
  } finally {
    dispatch({ type: HIDE_SCREEN_LOADER })
  }
}

export const getOffers = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_SCREEN_LOADER })
    const result = await defaultAxios.get('/pmts/listProducts')
    dispatch({ type: SAVE_OFFERS, payload: result?.data })
  } catch (error) {
    console.log('Error while loading data:', error)
    dispatch({ type: SHOW_TOAST, payload: error?.response?.data || 'Can\'t load offers' })
  } finally {
    dispatch({ type: HIDE_SCREEN_LOADER })
  }
}

export const checkoutReqest = (values, navigate) => async (dispatch) => {
  try {
    const url = values.type === 'paypal' ? '/pmts/createPaypalCheckoutSession' : '/pmts/createStripeCheckoutSession'
    dispatch({ type: SHOW_SCREEN_LOADER })
    const response = await defaultAxios.post(url, { id: values.id, quantity: values.quant })
    response?.data?.link && navigate(response.data.link)
  } catch (error) {
    console.log('Error while loading data:', error)
    dispatch({ type: SHOW_TOAST, payload: error?.response?.data?.error || error.message || 'Can\'t load checkout' })
  } finally {
    dispatch({ type: HIDE_SCREEN_LOADER })
  }
}
