import React from 'react'

const UpArrowInResCircle = () => {
  return (
    <svg
      id="Group_243"
      data-name="Group 243"
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <path id="Path_53" data-name="Path 53" d="M0,0H48V48H0Z" fill="none" />
      <path
        id="Path_54"
        data-name="Path 54"
        d="M32,4.68A20,20,0,1,1,2.01,22.648L2,22l.01-.648A20,20,0,0,1,32,4.68ZM22.04,12l-.326.02-.172.032-.284.09-.226.108-.14.086-.19.142-.116.108-8,8-.166.188a2,2,0,0,0,2.994,2.64L20,18.828V30l.014.234A2,2,0,0,0,24,30V18.83l4.586,4.584.188.166a2,2,0,0,0,2.64-2.994l-8-8-.164-.146-.178-.128-.226-.124-.162-.068-.226-.068-.224-.04-.2-.012Z"
        transform="translate(2 2)"
        fill="#ee2624"
        // stroke="#2c3e50"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UpArrowInResCircle
