import React from 'react'

const BeImg = () => {
  return (
    <svg
      id="Group_70"
      xmlns="http://www.w3.org/2000/svg"
      width="24.959"
      height="24.959"
      viewBox="0 0 24.959 24.959"
    >
      <circle
        id="Ellipse_19"
        cx="12.479"
        cy="12.479"
        r="12.479"
        fill="#131313"
      />
      <g id="Group_40" transform="translate(4.16 7.15)">
        <g id="Group_39">
          <g id="Group_38">
            <path
              id="Path_18"
              d="M784.1,1032.83a6.424,6.424,0,0,1,1.365.133,2.963,2.963,0,0,1,1.057.431,2.024,2.024,0,0,1,.683.8,2.81,2.81,0,0,1,.24,1.228,2.292,2.292,0,0,1-.359,1.318,2.6,2.6,0,0,1-1.068.863,2.5,2.5,0,0,1,1.437.974,3.226,3.226,0,0,1,.169,3.051,2.671,2.671,0,0,1-.828.948,3.663,3.663,0,0,1-1.19.542,5.316,5.316,0,0,1-1.367.176h-5.071V1032.83Zm-.3,4.226a1.667,1.667,0,0,0,1.014-.293,1.1,1.1,0,0,0,.393-.952,1.244,1.244,0,0,0-.128-.6.95.95,0,0,0-.355-.364,1.438,1.438,0,0,0-.507-.184,3.085,3.085,0,0,0-.592-.052h-2.155v2.444Zm.127,4.454a2.937,2.937,0,0,0,.646-.066,1.53,1.53,0,0,0,.543-.222,1.1,1.1,0,0,0,.374-.417,1.447,1.447,0,0,0,.138-.678,1.365,1.365,0,0,0-.453-1.154,1.942,1.942,0,0,0-1.2-.343h-2.5v2.881Z"
              transform="translate(-779.165 -1032.83)"
              fill="#fff"
            />
            <path
              id="Path_19"
              d="M832.83,1053.258a2.12,2.12,0,0,0,2.432.14,1.338,1.338,0,0,0,.556-.671h1.833a3.476,3.476,0,0,1-1.351,1.954,3.932,3.932,0,0,1-2.194.59,4.365,4.365,0,0,1-1.616-.287,3.436,3.436,0,0,1-1.223-.815,3.726,3.726,0,0,1-.77-1.262,4.629,4.629,0,0,1-.272-1.615,4.438,4.438,0,0,1,.28-1.585,3.673,3.673,0,0,1,2.021-2.114,3.936,3.936,0,0,1,1.58-.308,3.586,3.586,0,0,1,1.684.375,3.351,3.351,0,0,1,1.181,1,4.1,4.1,0,0,1,.666,1.438,5.369,5.369,0,0,1,.145,1.686h-5.467A2.119,2.119,0,0,0,832.83,1053.258Zm2.39-3.98a1.556,1.556,0,0,0-1.177-.422,1.788,1.788,0,0,0-.828.17,1.672,1.672,0,0,0-.527.417,1.492,1.492,0,0,0-.279.534,2.367,2.367,0,0,0-.095.5H835.7A2.025,2.025,0,0,0,835.22,1049.278Z"
              transform="translate(-820.785 -1044.613)"
              fill="#fff"
            />
            <rect
              id="Rectangle_3"
              width="4.241"
              height="1.033"
              transform="translate(11.104 0.702)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default BeImg
