import PropTypes from 'prop-types'
import React from 'react'

const ArrowRightCircleIcon = ({stroke='#919191'}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
    <g id="Icon_feather-arrow-right-circle" data-name="Icon feather-arrow-right-circle" transform="translate(-2 -2)">
      <path
        id="Path_34"
        data-name="Path 34"
        d="M23,13A10,10,0,1,1,13,3,10,10,0,0,1,23,13Z"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_35"
        data-name="Path 35"
        d="M18,20l4-4-4-4"
        transform="translate(-5 -3)"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_36"
        data-name="Path 36"
        d="M12,18h8"
        transform="translate(-3 -5)"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
)

ArrowRightCircleIcon.propTypes = {
  stroke: PropTypes.string
}

export default ArrowRightCircleIcon
