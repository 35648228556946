import React from 'react'
import DonateLink from '../DonateLink/DonateLink'
import HelpLink from '../HelpLink'
import SignInBtn from './signinbtn'
import AboutLink from '../AboutLink'
import SearchLink from '../SearchLink'
import BlogLink from '../BlogLink'
import PropTypes from 'prop-types'

const NavBar = ({ toggleBar, searchBar }) => {
  return <div className={`md:mx-2 justify-end flex ${searchBar ? 'gap-0 md:gap-2' : 'gap-2'}`}>
    <SearchLink toggleBar={toggleBar}/>
    <DonateLink hide={searchBar} />
    <BlogLink />
    <HelpLink hide={searchBar} />
    <AboutLink hide={searchBar} />
    <SignInBtn />
  </div>
}

NavBar.propTypes = {
  toggleBar: PropTypes.func,
  searchBar: PropTypes.bool
}

export default NavBar
