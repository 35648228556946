import PropTypes from 'prop-types'
import React from 'react'

const DownBoldArrow = ({disabled}) => {
  return (
    <svg
      className="w-8 h-8"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M68.6663 51.166L41.1663 78.666C40.4996 79.3327 39.4996 79.3327 38.8329 78.666L11.3329 51.166C10.8329 50.666 10.6663 49.9993 10.9996 49.3327C11.1663 48.8327 11.8329 48.3327 12.4996 48.3327H27.6663V2.49935C27.6663 1.49935 28.3329 0.832679 29.3329 0.832679H50.4996C51.4996 0.832679 52.1663 1.49935 52.1663 2.49935V48.3327H67.3329C67.9996 48.3327 68.6663 48.666 68.8329 49.3327C69.3329 49.9993 69.1663 50.666 68.6663 51.166Z"
        fill={!disabled ? 'black' : 'gray'}
      />
    </svg>
  )
}

DownBoldArrow.propTypes = {
  disabled: PropTypes.bool
}

export default DownBoldArrow
