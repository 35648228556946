import React from 'react'

function PendingImg() {
  return (
    <svg
      id="Group_15"
      data-name="Group 15"
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <path id="Path_13" data-name="Path 13" d="M0,0H35V35H0Z" fill="none" />
      <path
        id="Path_14"
        data-name="Path 14"
        d="M16.125,16.125,3,16.125A13.125,13.125,0,1,0,16.125,3,13.125,13.125,0,0,0,3,16.125"
        transform="translate(1.375 1.375)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      
      <path
        id="Path_16"
        data-name="Path 16"
        d="M12,7v7.292"
        transform="translate(5.5 3.208)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  )
}

export default PendingImg
