import React, { useEffect, useState } from 'react'
import ImageCompWithTitle from '../ImageCompWithTitle'
import './styles.css'
import CenteredDiv from '../CenteredDiv'
import { colors } from '../../utils/colors'
import TopicsListsAuthorListComp from '../TopicsListsAuthorListComp'
import SelectedAuthorDetailsComp from '../SelectedAuthorDetailsComp/SelectedAuthorDetailsComp'
import CustomizedBoldText from '../CustimizedBoldText'
import TopicsIconsListContainer from '../TopicsIconsListContainer'
import { useDispatch, useSelector } from 'react-redux'
import { saveTopicsDetails } from '../../redux/actions/TopicActions'
import DropdownSection from './DropdownSection'
import PropTypes from 'prop-types'

const TopicsListsComp = ({ index, elem }) => {
  // states
  const [showDropdown, setShowDropdown] = useState(false)
  const [selectedAuthor, setSelectedAuthor] = useState({})
  const [listDetails, setListDetails] = useState()
  const [sectionClicked, setSectionClicked] = useState(0)
  // redux handlers
  const dispatch = useDispatch()
  const { topicDetails } = useSelector((state) => state.topics)

  const expanded = parseInt(process.env.REACT_APP_EXPANDED_TOPICS) || 3

  //functions
  const handleDropDown = () => {
    !showDropdown && dispatch(saveTopicsDetails(elem?.topicId))
    setShowDropdown(!showDropdown)
  }

  const handleSectionClick = () => {
    setSectionClicked(1)
    dispatch(saveTopicsDetails(elem?.topicId))
  }

  useEffect(() => {
    index + 1 <= expanded && handleSectionClick()
  }, [dispatch, elem?.topicId])

  useEffect(() => {
    const [filteredDetails] = topicDetails.filter(
      (key) => key?.topicId === elem?.topicId
    )
    filteredDetails && setListDetails(filteredDetails)
    if (sectionClicked && filteredDetails) {
      setSectionClicked(0)
      setListDetails(filteredDetails)
      setSelectedAuthor(filteredDetails?.authorsList[0])
    }
  }, [topicDetails, elem?.topicId, sectionClicked])

  const toggleDropdown = () => {
    !showDropdown && dispatch(saveTopicsDetails(elem?.topicId))
    setShowDropdown(!showDropdown)
  }
  return (
    <>
      <div
        className='cursor-pointer listSection'
        onClick={toggleDropdown}
        style={{ marginTop: index > 0 ? 40 : null }}
        id={`search-result-${index}`}
        key={index}
      >
        <div className='listSectionImg'>
          <ImageCompWithTitle
            imgSrc={
              elem?.topicName === 'Algorithms'
                ? require('../../Images/topicsImages/Topic-Algorithms-Thumbnail-png-2 1.png')
                : elem?.topicName === 'Mobile Networks'
                  ? require('../../Images/topicsImages/Topic-MobileNetworks-Thumbnail-png-2 1.png')
                  : elem?.topicName === 'Electricity'
                    ? require('../../Images/topicsImages/Topic-Electricity-Thumbnail-png-2 1.png')
                    : require('../../Images/dummyImages/NoVideo.png')
            }
            alt={`“${elem?.topicName}” Topic thumbnail`}
            // imgSrc={elem?.topicIcon}
            width={'350px'}
            height={'100%'}
            borderRadius={20}
            fontSize={16}
            lineHeight={'21px'}
            onClick={handleSectionClick}
          />
        </div>
        <div
          className='listSectionItems'
          style={{
            position: showDropdown ? 'relative' : null
          }}
        >
          <CenteredDiv>
            <CustomizedBoldText
              text={elem?.topicName}
              textAlign={'center'}
              color={colors?.highlightedText}
              fontSize={40}
              lineHeight={'53px'}
              marginLeft={40}
            />
          </CenteredDiv>
          <TopicsIconsListContainer elem={elem} />
          <div
            className='dropdownContainer'
            style={{ paddingLeft: 0 }}
            onClick={(evt) => {
              evt.stopPropagation()
              toggleDropdown()
            }}
          >
            <DropdownSection
              elem={elem}
              handleDropDown={handleDropDown}
              selectedAuthor={selectedAuthor}
              showDropdown={showDropdown}
            />
            {showDropdown ? (
              <TopicsListsAuthorListComp
                setSelectedAuthor={setSelectedAuthor}
                setShowDropdown={setShowDropdown}
                left={selectedAuthor ? '24%' : null}
                listDetails={listDetails}
              />
            ) : null}
          </div>
        </div>
      </div>
      {selectedAuthor?.authorName ? (
        <SelectedAuthorDetailsComp
          topic={elem}
          selectedAuthor={selectedAuthor}
          setSelectedAuthor={setSelectedAuthor}
          setShowDropdown={setShowDropdown}
        />
      ) : null}
    </>
  )
}

TopicsListsComp.propTypes = {
  index: PropTypes.number,
  elem: PropTypes.object
}

export default TopicsListsComp
