import PropTypes from 'prop-types'
import React from 'react'

const PlayButton = ({size}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size ? size : '94'}
      height={size ? size : '94'}
      viewBox="0 0 94 94"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#ed1c24" />
          <stop offset="1" stopColor="#ffcd22" />
        </linearGradient>
      </defs>
      <g id="Group_160" transform="translate(-311 -976)">
        <g
          id="Ellipse_1"
          transform="translate(321 986)"
          fill="#fff"
          stroke="rgba(255,255,255,0.3)"
          strokeWidth="10"
        >
          <circle cx="37" cy="37" r="37" stroke="none" />
          <circle cx="37" cy="37" r="42" fill="none" />
        </g>
        <path
          id="Icon_awesome-play"
          d="M20.585,10.415,3.512.321A2.315,2.315,0,0,0,0,2.324V22.507a2.326,2.326,0,0,0,3.512,2L20.585,14.421A2.326,2.326,0,0,0,20.585,10.415Z"
          transform="translate(348.435 1010.58)"
          fill="url(#linear-gradient)"
        />
      </g>
    </svg>
  )
}

PlayButton.propTypes = {
  size: PropTypes.string,
}

export default PlayButton
