import React, { Component } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ArrowRightCircleIcon2 from '../../Images/ArrowRightCircleIcon2'
import ArrowLeftCircleIcon2 from '../../Images/ArrowLeftCircleIcon2'
import PropTypes from 'prop-types'

export default class SlickSliderComp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSlide: 0, 
    }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  next() {
    this.slider.slickNext()
  }

  previous() {
    this.slider.slickPrev()
  }

  
  handleSlideChange = (currentSlide) => {
    this.setState({ currentSlide })
  }

  render() {
    const { data, slides, length } = this.props
    const settings = {
      dots: false,
      infinite: false,
      arrows: false,
      speed: 500,
      slidesToShow: slides ? slides : 3,
      slidesToScroll: 1,
      initialSlide:0,
      beforeChange: (oldIndex, newIndex) => {
        this.handleSlideChange(newIndex)
      },
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: slides ? slides : 2.5,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }

    const { currentSlide } = this.state
    const showPreviousButton = currentSlide > 0 
    const showNextButtonMobile = currentSlide < length - 1
    const showNextButtonOther = currentSlide < length - slides

    return (
      <div style={{ position: 'relative' }}>
        <Slider ref={(c) => (this.slider = c)} {...settings}>
          {data}
        </Slider>
        <div style={{ textAlign: 'center' }}>
          {showPreviousButton && (
            <div
              className="button"
              onClick={this.previous}
              style={{ position: 'absolute', left: -16, top: '30%', cursor: 'pointer' }}
            >
              <ArrowLeftCircleIcon2 />
            </div>
          )}
          {window.innerWidth <= 480
            ? showNextButtonMobile && (
              <button
                className="button"
                onClick={this.next}
                style={{ position: 'absolute', right: 16, top: '30%', cursor: 'pointer' }}
              >
                <ArrowRightCircleIcon2 />
              </button>
            )
            : showNextButtonOther && (
              <button
                className="button"
                onClick={this.next}
                style={{ position: 'absolute', right: 16, top: '30%', cursor: 'pointer' }}
              >
                <ArrowRightCircleIcon2 />
              </button>
            )}
        </div>
      </div>
    )
  }

}

SlickSliderComp.propTypes = {
  data: PropTypes.array,
  slides: PropTypes.number,
  length: PropTypes.number
}
