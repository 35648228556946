import PropTypes from 'prop-types'
import React from 'react'

function DownImg({ transform }) {
  return (
    <svg
      className='blink'
      id="svg10654"
      height="20"
      viewBox="0 0 6.3499999 6.3500002"
      width="50"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      transform={transform ? 'rotate(180)' : null}
    >
      <g id="layer1" transform="translate(0 -290.65)">
        <path
          id="path9425"
          d="m2.9119652 291.18173-.00212 4.6452-.8691987-.86765c-.049813-.0512-.1182159-.0801-.1896534-.0801-.2357199.002-.3514672.28786-.1834515.4532l1.3224009 1.32395c.1033859.10363.2712693.10363.3746553 0l1.3223981-1.32395c.25899-.25009-.1265132-.63347-.3751686-.3731l-.8728155.87281.00212-4.65036c.00265-.14915-.1185148-.27099-.2676842-.26949-.1469443.001-.2643479.12257-.2614824.26949"
          fill="white" // Change the fill color to white
        />
      </g>
    </svg>
  )
}

DownImg.propTypes = {
  transform: PropTypes.bool
}

export default DownImg
