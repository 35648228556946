import React from 'react'
import './styles.css'
import ReactDom from 'react-dom'
import CrossImg from '../../Images/CrossImg'
import PropTypes from 'prop-types'

const Modal = ({ opened, onClose, children, comfirmation }) => {
  if (!opened) return null
  return ReactDom.createPortal(
    <>
      <div className="modalOverlay" />
      <div className={comfirmation ? 'confirmModalContainer' : 'modalContainer' }>
        <div className='crossIcon'
          onClick={onClose}
        >
          <CrossImg/>
        </div>
        {children}
      </div>
    </>,
    document.getElementById('portal')
  )
}

Modal.propTypes = {
  opened: PropTypes.bool, 
  onClose: PropTypes.func, 
  children: PropTypes.element, 
  comfirmation: PropTypes.bool
}

export default Modal
