import PropTypes from 'prop-types'
import React from 'react'
import PlayButton from '../Images/PlayButton'
import { colors } from '../utils/colors'
import CustomizedBoldText from './CustimizedBoldText'

const ImageCompWithTitle = ({
  alt,
  imgSrc,
  width,
  height,
  borderRadius,
  cursor,
  marginLeft,
  showPlayButton,
  playButtonLeft,
  playButtonTop,
  size,
  marginTop,
  compMarginLeft,
  fontSize,
  lineHeight,
  onClick,
  text,
  filter,
  color,
  imgFilter,
  left
}) => {
  return (
    <div
      style={{
        position: showPlayButton ? 'relative' : null,
        marginTop: marginTop,
        marginLeft: compMarginLeft,
        filter: filter
        // height: '260px'
      }}
      onClick={onClick}
      className='videoTitleContainer flex items-center'
    >
      <img
        src={imgSrc ? imgSrc : require('../Images/dummyImages/dummyImg.jpeg')}
        alt={alt}
        style={{
          width: width,
          height: height,
          borderRadius: borderRadius,
          cursor: cursor,
          marginLeft: marginLeft,
          background:
            ' transparent radial-gradient(closest-side at 50% 50%, #00000000 0%, #000000BA 100%) 0% 0% no-repeat padding-box',
          filter: imgFilter
        }}
      />
      {text ? (
        <CustomizedBoldText
          text={text}
          fontSize={fontSize ? fontSize : 16}
          color={color ? color : colors?.highlightedText}
          left={left}
          lineHeight={lineHeight ? lineHeight : '21px'}
          style={{
            position: 'absolute',
            top: '7%'
          }}
        />
      ): null}
      {showPlayButton ? (
        <div
          style={{
            filter: ' drop-shadow(#9797a5 1px 1px 1px)',
            position: 'absolute',
            top: playButtonTop ? playButtonTop : '35%',
            left: playButtonLeft ? playButtonLeft : '40%',
            cursor: 'pointer'
          }}
        >
          <PlayButton size={size} />
        </div>
      ): null}
    </div>
  )
}

ImageCompWithTitle.propTypes = {
  alt: PropTypes.string,
  imgSrc: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  borderRadius: PropTypes.number,
  cursor: PropTypes.string,
  marginLeft: PropTypes.number,
  showPlayButton: PropTypes.bool,
  playButtonLeft: PropTypes.string,
  playButtonTop: PropTypes.string,
  size: PropTypes.string,
  marginTop: PropTypes.number,
  compMarginLeft: PropTypes.number,
  fontSize: PropTypes.number,
  lineHeight: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  filter: PropTypes.string,
  color: PropTypes.string,
  imgFilter: PropTypes.string,
  left: PropTypes.number
}

export default ImageCompWithTitle
