import React from 'react'

const LinkedIn = () => {
  return (
    <svg
      id="Group_71"
      xmlns="http://www.w3.org/2000/svg"
      width="24.959"
      height="24.959"
      viewBox="0 0 24.959 24.959"
    >
      <circle
        id="Ellipse_11"
        cx="12.479"
        cy="12.479"
        r="12.479"
        fill="#131313"
      />
      <g id="Group_23" transform="translate(5.277 4.548)">
        <g id="Group_22">
          <path
            id="Path_10"
            d="M816.436,456.321c.205-.23.374-.464.584-.662a3.206,3.206,0,0,1,2.307-.91,5.339,5.339,0,0,1,1.45.177,2.835,2.835,0,0,1,2.013,2.117,8.874,8.874,0,0,1,.268,2.426c0,1.724,0,3.448,0,5.172,0,.161-.045.2-.2.2q-1.332-.011-2.664,0c-.156,0-.19-.047-.19-.195q.007-2.461,0-4.921a4.427,4.427,0,0,0-.143-1.221,1.374,1.374,0,0,0-1.511-1.071,1.651,1.651,0,0,0-1.735,1.648,6.162,6.162,0,0,0-.047.769c0,1.6,0,3.191,0,4.786,0,.159-.039.206-.2.2q-1.342-.012-2.684,0c-.143,0-.185-.038-.185-.183q.006-4.737,0-9.475c0-.156,.051-.192,.2-.191"
            transform="translate(-808.268 -450.092)"
            fill="#fefefe"
          />
          <path
            id="Path_11"
            d="M789.547,461c0,1.563,0,3.125,0,4.688,0,.174-.043,.226-.222,.224-.887,-.009-1.776,-.007-2.663,0-.142,0-.185,-.035-.185,-.182q.006,-4.745,0,-9.491c0,-.131,.032,-.178,.171,-.177,.9,.006,1.8,.008,2.7,0,.173,0,.2,.065,.2,.214Q789.543,458.637,789.547,461Z"
            transform="translate(-786.245 -451.161)"
            fill="#fefefe"
          />
          <path
            id="Path_12"
            d="M788.756,431.329a1.773,1.773,0,1,1-1.77,-1.764A1.779,1.779,0,0,1,788.756,431.329Z"
            transform="translate(-785.21 -429.565)"
            fill="#fefefe"
          />
        </g>
      </g>
    </svg>
  )
}

export default LinkedIn
