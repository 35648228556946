import PropTypes from 'prop-types'
import React from 'react'
import { colors } from '../utils/colors'
import CustomizedMediumText from './CustomizedMediumText'

const InfoContainer = ({
  text
}) => {
  return (
    <div style={{
      backgroundColor:colors?.info,
      padding:'52px 28px',
      borderRadius:30
    }}
    className='w-full md:w-3/6 mt-6 md:mt-0'>
      <CustomizedMediumText text={text} lineHeight={'48px'}/>
    </div>
  )
}

InfoContainer.propTypes = {
  text: PropTypes.string
}

export default InfoContainer
