import React from 'react'
import CustomizedBoldText from '../CustimizedBoldText'
import { colors } from '../../utils/colors'
import CustomizedMediumText from '../CustomizedMediumText'
import './styles.css'
import PropTypes from 'prop-types'

const FrameImageComp = ({ src, heading, description, alt, marginRight }) => {
  return (
    <div
      className='mainFrameImageContainer'
      style={{
        marginRight: marginRight
      }}
    >
      <img src={src} alt={alt} />
      <CustomizedBoldText
        text={heading}
        color={colors?.danger}
        fontSize={48}
        lineHeight={'24px'}
        marginTop={30}
        marginBottom={24}
      />
      <CustomizedMediumText
        text={description}
        lineHeight={'24px'}
        marginBottom={27}
      />
    </div>
  )
}

FrameImageComp.propTypes = {
  src: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  alt: PropTypes.string,
  marginRight: PropTypes.number
}

export default FrameImageComp
