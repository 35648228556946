import PropTypes from 'prop-types'
import React from 'react'

function LikesImg({fillColor}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
      <g id="Group_197" data-name="Group 197">
        <path id="Path_37" data-name="Path 37" d="M0,0H35V35H0Z" fill="none" />
        <path
          id="Path_38"
          data-name="Path 38"
          d="M8.833,14.208V25.875a1.458,1.458,0,0,1-1.458,1.458H4.458A1.458,1.458,0,0,1,3,25.875V15.667a1.458,1.458,0,0,1,1.458-1.458H8.833a5.833,5.833,0,0,0,5.833-5.833V6.917a2.917,2.917,0,0,1,5.833,0v7.292h4.375a2.917,2.917,0,0,1,2.917,2.917l-1.458,7.292c-.431,1.838-1.62,3.027-2.917,2.917H13.208a4.375,4.375,0,0,1-4.375-4.375"
          transform="translate(1.375 1.833)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          opacity={!fillColor ? 0.6 : null}
        />
      </g>
    </svg>
  )
}

LikesImg.propTypes = {
  fillColor: PropTypes.string,
}

export default LikesImg
