import React, { useEffect, useState } from 'react'
// import ImageComp from '../imageComp/ImageComp';
import HorizontalDiv from '../HorizontalDiv'
import CenteredDiv from '../CenteredDiv'
import './styles.css'
import { colors } from '../../utils/colors'
import FeedbackSelectArea from './FeedbackSelectArea'
import FeedBackForChapters from './FeedBackForChapters'
import IframeVideoComp from '../IframeVideoComp/IframeVideoComp'
import { useDispatch, useSelector } from 'react-redux'
import { saveFeedbackPoints } from '../../redux/actions/TopicActions'
import CompleteLogo from '../../Images/CompleteLogo'
import PropTypes from 'prop-types'

const FeedbackForm = ({ onClose, opened, videoId, videoSrc }) => {
  const [flexWrap, setFlexWrap] = useState(
    window.innerWidth >= 1200
      ? 'nowrap'
      : window.innerWidth >= 992
        ? 'wrap'
        : window.innerWidth >= 576
          ? 'wrap'
          : 'wrap'
  )
  const [width, setWidth] = useState(
    window.innerWidth >= 1200
      ? '50%'
      : window.innerWidth >= 992
        ? '100%'
        : window.innerWidth >= 576
          ? '100%'
          : '100%'
  )
  const handleResize = () => {
    if (window.innerWidth >= 1200) {
      setFlexWrap('nowrap')
      setWidth('50%')
    } else if (window.innerWidth >= 992) {
      setFlexWrap('wrap')
      setWidth('100%')
    } else if (window.innerWidth >= 576) {
      setFlexWrap('wrap')
      setWidth('100%')
    } else {
      setFlexWrap('wrap')
      setWidth('100%')
    }
  }

  const buildLink = (short) => {
    return 'https://www.youtube.com/embed/'.concat(short?.split('/')[3]).concat('?autoplay=1')
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const [selectedChapter, setSelectChapter] = useState(null)
  const [radioDataArray, setRadioDataArray] = useState([])
  // const [runVideo, setRunVideo] = useState(true)
  const [videoFullFormat] = useState(buildLink(videoSrc))
  const [feedbackHeading, setFeedbackHeading] = useState()
  const [chapterSelectCheck, setChapterSelectCheck] = useState()
  const [fieldChecks, setFieldChecks] = useState({
    inputValueCheck: false
  })

  //redux handlers
  const dispatch = useDispatch()
  const { feedbackPoints } = useSelector((state) => state?.topics)

  const handleOptionChange = (chapter) => {
    setFieldChecks({
      inputValueCheck: false
    })
    setChapterSelectCheck(false)
    const id = localStorage.getItem('id')
    id && setSelectChapter(chapter)
  }

  useEffect(() => {
    videoId && dispatch(saveFeedbackPoints(videoId))
  }, [dispatch, videoId])

  useEffect(() => {
    const result = feedbackPoints?.find((elem) => elem?.videoId === videoId)
    setRadioDataArray(result?.structure)
    const { topicName, section, sequenceNumber } = result ?? {}
    topicName
      ? setFeedbackHeading(
        `Video: ${topicName}. ${section}? Part ${sequenceNumber}`
      )
      : setFeedbackHeading('')
  }, [feedbackPoints, videoId])
  return (
    <div
      className='feedbackFormContainer'
      style={{
        backgroundColor: colors?.white
      }}
    >
      <CompleteLogo />
      <CenteredDiv height={videoId ? undefined : '80vh'} marginTop={20}>
        <IframeVideoComp
          src={videoFullFormat}
          width={videoId && 500}
          height={videoId && 281}
        />
      </CenteredDiv>
      {opened && videoId ? (
        <HorizontalDiv
          gap={31}
          width={'100%'}
          flexWrap={flexWrap}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          marginTop={21}
        >
          <FeedbackSelectArea
            data={radioDataArray}
            handleChange={handleOptionChange}
            selectedOption={selectedChapter}
            feedbackHeading={feedbackHeading}
            chapterSelectCheck={chapterSelectCheck}
          />
          <FeedBackForChapters
            width={width}
            selectedOption={selectedChapter}
            onClose={onClose}
            selectedId={videoId}
            setChapterSelectCheck={setChapterSelectCheck}
            selectedChapter={selectedChapter}
            fieldChecks={fieldChecks}
            setFieldChecks={setFieldChecks}
          />
        </HorizontalDiv>
      ) : (
        <></>
      )}
    </div>
  )
}

FeedbackForm.propTypes = {
  onClose: PropTypes.func,
  opened: PropTypes.bool,
  videoId: PropTypes.string,
  videoSrc: PropTypes.string
}

export default FeedbackForm
