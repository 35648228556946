import PropTypes from 'prop-types'
import React from 'react'

const ParentHeirarichyImg = ({disabled}) => {
  return (
    <svg
      className="w-8 h-8"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M73 75.0293L39.6667 75.0293C34.1667 75.0293 29.6667 70.5293 29.6667 65.0293L29.6667 15.6293L11.9 31.8293L7.39999 26.896L28.1667 7.96267C30.8 5.32934 35.2333 5.32934 37.9667 8.06267L58.5667 26.896L54.0667 31.8293L36.3333 15.6293L36.3333 65.0293C36.3333 66.8627 37.8333 68.3627 39.6667 68.3627L73 68.3627L73 75.0293Z"
        fill={!disabled ? 'black' : 'gray'}
      />
    </svg>
  )
}

ParentHeirarichyImg.propTypes = {
  disabled: PropTypes.bool,
}

export default ParentHeirarichyImg
