import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { getComments } from '../../redux/actions/BlogActions'
import CommentCard from './CommentCard'
import ArrowDownIcon from '../../Images/ArrowDownIcon'
import CommentForm from './CommentForm'
import Paginate from '../Paginate/Paginate'

const CommentsList = ({ post, display, setDisplay }) => {
  const { comments } = useSelector(state => state.blog)
  const [page, setPage] = useState(1)
  const { postId, hideComments } = post

  const dispatch = useDispatch()

  const list = () => {
    return comments[postId][page] ? Object.values(comments[postId][page]) : []
  }

  useEffect(() => {
    if (!hideComments && display && (!comments || !comments[postId] || !comments[postId][page]) ) {
      dispatch(getComments({ postId, page }))
    }
  }, [dispatch, post, display, comments, page])
  return (
    <>
      <div className='comments'>
        {display ? (
          <>
            {comments[postId] && comments[postId].total > 0 && comments[postId][page] ? (
              list().map(comment => <CommentCard key={comment.commentId} data={comment} />)
            ) : (
              <div className='bg-slate-300 rounded-md px-2 bg-opacity-60 w-fit mx-auto block self-center'>no comments</div>
            )}
            {comments[postId] && comments[postId]?.total > 1 && <div className='flex justify-end'><Paginate handler={setPage} total={comments[postId].total} current={page} /></div>}
            <CommentForm post={post} />
          </>
        ) : null}
      </div>
      <span onClick={() => !hideComments && setDisplay(!display)} className='cursor-pointer'>
        Comments{' '}
        <span className={`relative inline-block${display ? ' rotate-180' : ''}`}>
          <ArrowDownIcon />
        </span>
      </span>
    </>
  )
}

CommentsList.propTypes = {
  post: PropTypes.object.isRequired,
  display: PropTypes.bool.isRequired,
  setDisplay: PropTypes.func.isRequired
}

export default CommentsList
