import PropTypes from 'prop-types'
import React from 'react'
import './styles.css'

const CustomInput = ({
  value,
  onChange,
  placeholder,
  style
}) => {
  return (
    <input
      style={{ ...style }}
      value={value}
      onChange={onChange}
      className="custom-input"
      placeholder={placeholder}
    />
  )
}

CustomInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.string
}

export default CustomInput
