import PropTypes from 'prop-types'
import React from 'react'

const VideoComp = ({
  poster,
  src,
  width,
  title,
}) => {
  return (
    <video
      title={title}
      src={src}
      // controls
      poster={poster}
      width={width}
      // height={height ? height : "400"}
      autoPlay
      loop
      muted
      preload="auto"
      controlsList="nodownload"
      crossOrigin="anonymous"
    >
        Your browser does not support the video tag.
    </video>
  )
}

VideoComp.propTypes = {
  poster: PropTypes.string,
  src: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.number
}

export default VideoComp
