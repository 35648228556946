import React from 'react'
import CenteredDiv from '../CenteredDiv'
import HorizontalDiv from '../HorizontalDiv'
import { colors } from '../../utils/colors'
import CustomizedText from '../CustomizedText'
import PointerIcon from '../../Images/PointerIcon'
import PropTypes from 'prop-types'

const DropdownSection = ({ elem, handleDropDown, selectedAuthor }) => {
  return (
    <CenteredDiv
      justifyContent={'flex-start'}
      onClick={() => {
        elem?.topicId > 0 && handleDropDown()
      }}
    >
      <HorizontalDiv marginTop={10}>
        <HorizontalDiv
          cursor={'pointer'}
          borderRadius={32}
          backgroundColor={colors?.white}
          padding={'8px 16px'}
          minWidth={207}
        >
          <CustomizedText
            text={`Authors: ${elem?.postedAuthors}`}
            marginRight={56}
            fontSize={20}
            lineHeight={'27px'}
          />
          <PointerIcon />
        </HorizontalDiv>
        <HorizontalDiv>
          {selectedAuthor.authorName ? (
            <CustomizedText
              text={`Selected Author: ${selectedAuthor.authorName} `}
              fontSize={20}
              color={colors?.white}
              marginLeft={12}
              lineHeight={'32px'}
            />
          ) : null}
        </HorizontalDiv>
      </HorizontalDiv>
    </CenteredDiv>
  )
}

DropdownSection.propTypes = {
  elem: PropTypes.object,
  handleDropDown: PropTypes.func,
  selectedAuthor: PropTypes.object
}

export default DropdownSection
