import React from 'react'
import CustomizedBoldText from '../CustimizedBoldText'
import { colors } from '../../utils/colors'
import PropTypes from 'prop-types'

const HeadingPlusDetailsSection = ({details}) => {
  return (
    <>
      <CustomizedBoldText
        text={'Author Description'}
        marginTop={15}
        fontSize={22}
        lineHeight={'30px'}
      />
      <p dangerouslySetInnerHTML={{__html: details}} 
        className='mt-3 text-base font-medium static-text' color={colors.descriptionText}
      />
    </>
  )
}

HeadingPlusDetailsSection.propTypes = {
  details: PropTypes.string,
}

export default HeadingPlusDetailsSection
