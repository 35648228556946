import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

const BlogTag = ({ name }) => {
  return (
    <NavLink className={'blogTag'} to={`#${name}`}>
      {`#${name}`}
    </NavLink>
  )
}

BlogTag.propTypes = {
  name: PropTypes.string.isRequired
}

export default BlogTag
