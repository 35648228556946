export const SIGN_UP_USER = 'SIGN_UP_USER'

export const GET_COUNTRIES = 'GET_COUNTRIES'

export const SAVE_USER_DATA = 'SAVE_USER_DATA'
export const REMOVE_USER_DATA = 'REMOVE_USER_DATA'

export const SAVE_USER_INTERESTS = 'SAVE_USER_INTERESTS'

export const SAVE_ALL_INTEREST_TYPES = 'SAVE_ALL_INTEREST_TYPES'

export const SAVE_USER_FEEDBACK = 'SAVE_USER_FEEDBACK'

export const SAVE_AUTHOR_DATA = 'SAVE_AUTHOR_DATA'