import React, { useState } from 'react'
import './styles.css'
import HorizontalDiv from '../HorizontalDiv'
import CustomizedBoldText from '../CustimizedBoldText'
import UpArrowInResCircle from '../../Images/UpArrowInRedCircle'
import PointerDiv from '../PointerDiv'
import HeadingPlusDetailsSection from './HeadingPlusDetailsSection'
import Modal from '../Modal/Modal'
import FeedbackForm from '../FeedbackForm/FeedbackForm'
import ConditionalSlider from '../ConditionalSlider/ConditionalSlider'
import { useDispatch } from 'react-redux'
import { SAVE_FEEDBACK_CHAPTER } from '../../redux/types/TopicTypes'
import CenteredDiv from '../CenteredDiv'
import PropTypes from 'prop-types'

const SelectedAuthorDetailsComp = ({
  topic,
  setSelectedAuthor,
  setShowDropdown,
  selectedAuthor
}) => {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [videoId, setVideoId] = useState()
  const [videoSrc, setVideoSrc] = useState('')

  const modalClose = () => {
    setShowModal(false)
    dispatch({ type: SAVE_FEEDBACK_CHAPTER, payload: [] })
  }
  const totalSize =
    selectedAuthor?.what?.length +
    selectedAuthor?.why?.length +
    selectedAuthor['add-on']?.length
  return (
    <div className='mainContainer'>
      {!totalSize ? (
        <div className='my-10'>
          <CenteredDiv>
            <CustomizedBoldText
              text={`Videos from ${selectedAuthor?.authorName} are pending`}
              fontSize={20}
            />
          </CenteredDiv>
        </div>
      ) : null}
      <HorizontalDiv>
        {selectedAuthor?.what?.length > 0 ? (
          <CustomizedBoldText
            text={'What?'}
            fontSize={40}
            lineHeight={'53px'}
          />
        ) : null}
        <PointerDiv
          onClick={() => {
            setSelectedAuthor({})
            setShowDropdown(false)
          }}
        >
          <UpArrowInResCircle />
        </PointerDiv>
      </HorizontalDiv>
      <ConditionalSlider
        topic={topic}
        author={selectedAuthor}
        array={selectedAuthor?.what}
        setShowModal={setShowModal}
        setVideoSrc={setVideoSrc}
        setVideoId={setVideoId}
      />
      {selectedAuthor?.why?.length > 0 && (
        <CustomizedBoldText
          text={'Why?'}
          marginTop={23}
          fontSize={40}
          lineHeight={'53px'}
        />
      )}
      <ConditionalSlider
        topic={topic}
        author={selectedAuthor}
        array={selectedAuthor?.why}
        setShowModal={setShowModal}
        setVideoSrc={setVideoSrc}
        setVideoId={setVideoId}
      />
      {selectedAuthor['add-on']?.length > 0 && (
        <CustomizedBoldText
          text={'Add-on'}
          marginTop={23}
          fontSize={40}
          lineHeight={'53px'}
        />
      )}
      <ConditionalSlider
        topic={topic}
        author={selectedAuthor}
        array={selectedAuthor['add-on']}
        setVideoSrc={setVideoSrc}
        setShowModal={setShowModal}
        setVideoId={setVideoId}
      />
      <HeadingPlusDetailsSection details={selectedAuthor?.description} />
      <Modal opened={showModal} onClose={() => modalClose()}>
        <FeedbackForm
          onClose={() => modalClose()}
          opened={showModal}
          videoSrc={videoSrc}
          videoId={videoId}
        />
      </Modal>
    </div>
  )
}

SelectedAuthorDetailsComp.propTypes = {
  topic: PropTypes.object,
  setSelectedAuthor: PropTypes.func,
  setShowDropdown: PropTypes.func,
  selectedAuthor: PropTypes.object
}

export default SelectedAuthorDetailsComp
