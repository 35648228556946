import { useState } from 'react'

const useResponsiveStyles = () => {
  const [fontSize, setFontSize] = useState(
    window.innerWidth >= 1200
      ? '76px'
      : window.innerWidth >= 992
        ? '68px'
        : window.innerWidth >= 576
          ? '60px'
          : '52px'
  )
  const [width, setWidth] = useState(
    window.innerWidth >= 1200
      ? '100%'
      : window.innerWidth >= 992
        ? 'auto'
        : window.innerWidth >= 576
          ? 'auto'
          : '100%'
  )

  const [lineHeight, setLineHeight] = useState(
    window.innerWidth >= 1200
      ? '94px'
      : window.innerWidth >= 992
        ? '84px'
        : window.innerWidth >= 768
          ? '74px'
          : window.innerWidth >= 576
            ? '64px'
            : '54px'
  )

  const handleResize = () => {
    if (window.innerWidth >= 1200) {
      setLineHeight('94px')
      setFontSize('76px')
      setWidth('100%')
    } else if (window.innerWidth >= 992) {
      setLineHeight('84px')
      setFontSize('68px')
      setWidth('auto')
    } 
    else if (window.innerWidth >= 768) {
      setLineHeight('74px')
    } 
    else if (window.innerWidth >= 576) {
      setLineHeight('64px')
      setFontSize('60px')
      setWidth('auto')
    } else {
      setLineHeight('54px')
      setFontSize('52px')
      setWidth('100%')
    }
  }

  return { fontSize, width ,handleResize,lineHeight}
}

export default useResponsiveStyles
