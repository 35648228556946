import { SAVE_OFFERS } from '../types/OffersTypes'

const offersInitialState = []

export const offersReducer = (state = offersInitialState, action) => {
  if (action.type === SAVE_OFFERS) {
    return [...state, ...action.payload]
  }
  return state
}
