import React, { useEffect, useRef, useState } from 'react'
import CustomizedBoldText from './CustimizedBoldText'
import ImageCompWithTitle from './ImageCompWithTitle'
import { colors } from '../utils/colors'
import SlickSliderComp from './SlickSliderComp/SlickSliderComp'
import HorizontalDiv from './HorizontalDiv'
import Tooltip from './Tooltip/Tooltip'
import ShareImg from '../Images/ShareImg'
import { copy } from '../utils/copy'
import PropTypes from 'prop-types'
import { titles } from '../screens/About/data'

const VideoListSection = ({
  array,
  setVideo,
  setShowModal,
  heading,
  setImgSrc,
  sectionId,
  setScrollPos
}) => {
  const sectionRef = useRef(null)

  const [width, setWidth] = useState(
    window.innerWidth >= 1600
      ? '410px'
      : window.innerWidth >= 1280
        ? '425px'
        : window.innerWidth >= 1024
          ? '360px'
          : window.innerWidth >= 992
            ? '350px'
            : window.innerWidth >= 768
              ? '350px'
              : window.innerWidth >= 576
                ? '350px'
                : '350px'
  )
  const [playButtonLeft, setPlayButtonLeft] = useState(
    window.innerWidth >= 1800
      ? '42%'
      : window.innerWidth >= 1600
        ? '40%'
        : window.innerWidth >= 1200
          ? '39%'
          : window.innerWidth >= 992
            ? '36%'
            : window.innerWidth >= 576
              ? '39%'
              : '39%'
  )
  const handleResize = () => {
    if (window.innerWidth >= 1800) {
      setPlayButtonLeft('42%')
    } else if (window.innerWidth >= 1600) {
      setWidth('410px')
      setPlayButtonLeft('40%')
    } else if (window.innerWidth >= 1280) {
      setWidth('425px')
      setPlayButtonLeft('39%')
    } else if (window.innerWidth >= 1024) {
      setWidth('360px')
    } else if (window.innerWidth >= 992) {
      setWidth('300px')
      setPlayButtonLeft('36%')
    } else if (window.innerWidth >= 768) {
      setWidth('350px')
      setPlayButtonLeft('39%')
    } else if (window.innerWidth >= 576) {
      setWidth('350px')
      setPlayButtonLeft('39%')
    } else {
      setWidth('350px')
      setPlayButtonLeft('39%')
    }
  }

  const [tooltip, setTooltip] = useState(false)
  const copyUrl = (url, msg) => {
    setTooltip(msg)
    copy(url, setTooltip)
  }

  useEffect(() => {
    const action = () => {
      sectionRef.current.parentNode
        .querySelectorAll('img')
        .forEach((v) => v.classList.add('flash'))
    }
    if (
      setScrollPos &&
      sectionRef.current &&
      sectionRef.current.id === window.location.hash.slice(1)
    ) {
      setTimeout(action, 500)
    }
  }, [setScrollPos, sectionRef])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <div>
      {sectionId && (
        <div
          style={{ float: 'left', backgroundColor: 'transparent' }}
          className='my-3 mr-2'
        >
          <Tooltip text={tooltip || 'Copy link to clipboard'}>
            <span
              onClick={() => {
                copyUrl(sectionRef.current.href, 'Copied!')
              }}
              name={sectionId}
              className='topicLink'
            >
              <ShareImg fillColor={colors.black} />
            </span>
          </Tooltip>
        </div>
      )}
      <a
        name={sectionId}
        className='navlink'
        id={sectionId || null}
        href={`#${sectionId || ''}`}
        ref={sectionRef}
      >
        <CustomizedBoldText
          text={heading}
          fontSize={40}
          lineHeight={'53px'}
          marginTop={45}
        />
      </a>
      {titles && titles[sectionId] ? (<p dangerouslySetInnerHTML={{ __html: titles[sectionId] }}></p>) : null}
      {array?.length > 3 ? (
        <div style={{ marginTop: 21 }}>
          <SlickSliderComp
            length={array?.length}
            slides={3}
            data={array?.map((elem, index) => {
              return (
                <ImageCompWithTitle
                  key={index}
                  cursor={'pointer'}
                  imgSrc={elem?.img}
                  width={width}
                  borderRadius={20}
                  showPlayButton
                  playButtonTop={'32%'}
                  playButtonLeft={playButtonLeft}
                  size={'80'}
                  onClick={() => {
                    setVideo(elem?.video)
                    setShowModal(!!elem?.video)
                    setImgSrc(elem.img)
                  }}
                  text={elem.title}
                  alt={`“${elem?.title}” advertisement Video`}
                  filter={'drop-shadow(2px 2px 2px black)'}
                  color={colors?.notHighlightedText}
                  imgFilter={'opacity(0.95)'}
                />
              )
            })}
          />
        </div>
      ) : (
        <HorizontalDiv
          // flexWrap="wrap"
          justifyContent={'flex-start'}
          marginTop={21}
          gap={27}
        >
          {array?.map((elem, index) => {
            return (
              <ImageCompWithTitle
                key={index}
                cursor={'pointer'}
                imgSrc={elem?.img}
                width={width}
                // height={250}
                borderRadius={20}
                showPlayButton
                playButtonTop={'32%'}
                size={'80'}
                playButtonLeft={'39%'}
                onClick={() => {
                  setVideo(elem?.video)
                  setShowModal(!!elem?.video)
                  setImgSrc(elem?.img)
                }}
                text={elem?.title}
                alt={`“${elem?.title}” explanatory video of Motivation.How`}
                filter={'drop-shadow(2px 2px 2px black)'}
                color={colors?.notHighlightedText}
                imgFilter={'opacity(0.95)'}
              />
            )
          })}
        </HorizontalDiv>
      )}
    </div>
  )
}

VideoListSection.propTypes = {
  array: PropTypes.array,
  setVideo: PropTypes.func,
  setShowModal: PropTypes.func,
  heading: PropTypes.string,
  setImgSrc: PropTypes.func,
  sectionId: PropTypes.string,
  setScrollPos: PropTypes.func
}

export default VideoListSection
