import PropTypes from 'prop-types'
import React, { useState } from 'react'

const Tooltip = ({
  text,
  children,
  opacity,
  fromFeedBackForChaptrs,
  display = 'inline-block'
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const dynamicWidth = fromFeedBackForChaptrs ? '100%' : ' '

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        position: 'relative',
        display: display,
        opacity: !isHovered && opacity,
        width: dynamicWidth
      }}
      className='tooltipContent'
    >
      {isHovered && (
        <div
          style={{
            display: display,
            position: 'absolute',
            top: '100%',
            left: '40%',
            width: '250px',
            transform: 'translateX(-50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            padding: '4px 8px',
            borderRadius: '4px',
            zIndex: 1
          }}
        >
          {text}
        </div>
      )}
      {children}
    </div>
  )
}

Tooltip.propTypes = {
  text: PropTypes.string,
  children: PropTypes.element,
  opacity: PropTypes.number,
  fromFeedBackForChaptrs: PropTypes.bool,
  display: PropTypes.string
}

export default Tooltip
