import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'

const AboutLink = ({ hide = false }) => {

  return <NavLink to={'/about'} className={({ isActive }) => isActive ? 'hidden md:hidden' : ''}>
    <div className={hide ? 'hidden' : 'bg-white rounded-full border-2 border-orange-400 w-9 h-9 p-1 md:hidden'}>
      <img src="/aboutIcon.png" alt='About Us link' />
    </div>
    <div className="hidden text-xl md:inline-block font-semibold text-nowrap mx-1 border-l-4 border-t-4 border-current px-1 hover:border-orange-400 cursor-pointer">
            About Us
    </div>
  </NavLink>
}

AboutLink.propTypes = {
  hide: PropTypes.bool
}

export default AboutLink
