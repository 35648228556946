import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPage } from '../../redux/actions/PageActions'
import CenteredDiv from '../CenteredDiv'
import { useLocation } from 'react-router-dom'

const Quotes = () => {
  const dispatch = useDispatch()
  const currentText = useSelector(state => state.texts)
  const location = useLocation()

  const pageKey = `${location.pathname.slice(1) || 'landing'}`

  const isInList = () => {
    return ['landing', 'about', 'help', 'fund'].indexOf(pageKey) > -1
  }

  useEffect(() => {
    pageKey && !currentText[pageKey] && dispatch(getPage({ page: pageKey }))
  }, [currentText, dispatch, pageKey])

  if (pageKey === 'landing') {
    return (
      <CenteredDiv marginTop={32}>
        <div
          className='static-text'
          dangerouslySetInnerHTML={{ __html: currentText?.landing?.part_02 }}
        ></div>
      </CenteredDiv>
    )
  } else {
    return isInList() && currentText[pageKey]
      ? Object.keys(currentText[pageKey]).map((key, idx) => (
        <CenteredDiv key={idx} marginTop={32}>
          <div
            className='static-text'
            id={key}
            dangerouslySetInnerHTML={{ __html: currentText[pageKey][key] }}
          ></div>
        </CenteredDiv>
      ))
      : null
  }
}

export default Quotes
