import React, { useEffect, useRef, useState } from 'react'
import OfferIcon from './OfferIcon'
import './styles.css'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { checkoutReqest } from '../../redux/actions/offerActions'
import * as Yup from 'yup'
import CustomizedMediumText from '../CustomizedMediumText'
import CustomizedText from '../CustomizedText'
import { colors } from '../../utils/colors'
import PropTypes from 'prop-types'

const Offer = ({ showPayBtns, submitPressed, setSubmitPressed, provider, offer }) => {

  const dispatch = useDispatch()
  const navigate = (url) => {
    setSubmitPressed(null)
    window.location.assign(url)
  }
  const offerRef = useRef()
  const [interacted, setInteracted] = useState(false)

  const scrollToPay = () => {
    requestAnimationFrame(() => {
      document.querySelector('.offer-buttons')?.scrollIntoView({ behavior: 'smooth' })
    })
  }

  const formik = useFormik({
    initialValues: {
      quant: 0,
      id: offer.id,
    },
    validationSchema: Yup.object().shape({
      quant: Yup.number('Enter number')
        .max(offer.quantity_max, 'Too big')
        .min(offer.quantity_min, 'Too small')
        .required('Quantity required')
    }),
    onSubmit: (values) => {
      if (submitPressed === offer.id) {
        values.type = provider
        dispatch(checkoutReqest(values, navigate))
      }
    }
  })

  const getOptions = (min, max) => {
    return Array.from({ length: max - min + 1 }, (_, index) => index + min)
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    values,
    errors,
  } = formik

  const selectOffer = (evt) => {
    if (evt?.type === 'change') {            
      handleChange(evt.nativeEvent)
      scrollToPay()
    } else if (evt?.type === 'click') {
      values.quant = evt.target.value
      document.querySelectorAll('.offer').forEach(el => el.classList.remove('selected'))
      offerRef.current.classList.add('selected')
      document.querySelectorAll('.offer:not(.selected) select').forEach(v => {
        v.value = 0
        v.selectedIndex = 0
      })
    }
    const val = !isNaN(evt?.target?.value) ? evt.target.value : values.quant
    setSubmitPressed(null)
    showPayBtns(offer.id, val * offer.price / 100)
  }

  useEffect(() => {
    (submitPressed === offer.id) && handleSubmit()
  }, [handleSubmit, offer.id, submitPressed])

  useEffect(() => {
    interacted && selectOffer() && setSubmitPressed(null)
  }, [values.quant])

  useEffect(() => {
    if (values.id === 1) {
      setValues({ quant: 1, id: offer.id })
      offerRef.current.querySelector('select').value = 1
      showPayBtns(1, 20)
      setInteracted(1)
    }
  }, [])

  return <div className={offer.id === 1 ? 'offer selected' : 'offer'} onClick={selectOffer} ref={offerRef}>
    <CustomizedText className='offer-title' color={colors.brandColor} lineHeight='1.2em' text={offer.name} />
    <OfferIcon img={offer.icon} />
    <CustomizedMediumText className="offer-description" text={offer.description} fontSize={'1em'} />
    <form onSubmit={handleSubmit} className="offer-form">
      <input type="hidden" name='id' value={offer.id} />
      <span className="offer-price">{'$' + offer.price / 100}</span>
      <select
        id={'quant' + offer.id}
        onFocus={evt => evt.target.value = 0}
        onChange={selectOffer}
        onBlur={handleBlur}
        onClick={null}
        name="quant"
        required={true}
        className={(submitPressed === offer.id && errors.quant) ? 'inputError offer-quant' : 'offer-quant'}>
        <option value='0'>---</option>
        {getOptions(offer.quantity_min, offer.quantity_max).map(idx => {
          return <option key={idx} value={idx}>{idx}</option>
        })}
      </select>
      {
        submitPressed === offer.id && errors.quant ? (
          <div className="error">{(errors.quant || errors.quant)}</div>
        ) : null
      }
    </form>
  </div>
}

Offer.propTypes =  { 
  showPayBtns: PropTypes.func,
  submitPressed: PropTypes.number,
  setSubmitPressed: PropTypes.func,
  provider: PropTypes.string, 
  offer: PropTypes.object
}

export default Offer
