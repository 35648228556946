import React from 'react'

const PinterestImg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.959"
      height="25"
      viewBox="0 0 24.959 25"
    >
      <g id="Group_69" transform="translate(0 0)">
        <g id="Group_17" transform="translate(0 0)">
          <path
            id="Path_7"
            d="M96.66,404.853a12.494,12.494,0,0,0-4.554,24.126,12.026,12.026,0,0,1,.041-3.588c.226-.977,1.46-6.209,1.46-6.209a4.549,4.549,0,0,1-.37-1.85c0-1.737,1.007-3.032,2.261-3.032a1.57,1.57,0,0,1,1.583,1.758,24.692,24.692,0,0,1-1.038,4.163,1.817,1.817,0,0,0,1.85,2.261c2.22,0,3.927-2.344,3.927-5.715a4.928,4.928,0,0,0-5.222-5.078,5.405,5.405,0,0,0-5.643,5.417,4.875,4.875,0,0,0,.925,2.847.372.372,0,0,1,.082.36c-.093.391-.308,1.244-.35,1.419-.051.226-.185.277-.421.164-1.563-.73-2.539-3-2.539-4.842,0-3.937,2.858-7.555,8.255-7.555,4.328,0,7.7,3.084,7.7,7.216,0,4.307-2.714,7.771-6.476,7.771a3.319,3.319,0,0,1-2.858-1.439l-.781,2.971a13.612,13.612,0,0,1-1.552,3.279,12.5,12.5,0,1,0,3.721-24.445Z"
            transform="translate(-84.17 -404.853)"
            fill="#131313"
          />
        </g>
      </g>
    </svg>
  )
}

export default PinterestImg
