import React from 'react'
import CenteredDiv from './CenteredDiv'
import CustomizedText from './CustomizedText'
import { colors } from '../utils/colors'
import ArrowForwardIcon from '../Images/ArrowForwardIcon'
import PropTypes from 'prop-types'

const TopicsListsAuthorListComp = ({setSelectedAuthor, setShowDropdown, listDetails}) => {
  return (
    <CenteredDiv
      display={'block'}
      marginTop={9}
      style={{ position: 'absolute', top: 46 }}
    >
      <div
        style={{
          padding: '10px 16px',
          backgroundColor: colors?.white,
          borderRadius: 22,
          minWidth: 206,
          zIndex:1,
          boxShadow: '10px 10px 50px #00000029',
        }}
      >
        {listDetails?.authorsList.length > 0 && listDetails?.authorsList?.map((elem,index) => {
          return(
            <div key={index}>
              <CustomizedText 
                onClick={(evt)=>{
                  evt.stopPropagation()
                  setSelectedAuthor(elem)
                  setShowDropdown(false)
                }}
                childClassName='selectAuthor'
                text={elem?.authorName} fontSize={20} 
                marginTop={index > 0 ? 12 : null}
                cursor={'pointer'}
              />
              <ArrowForwardIcon style={{float:'right', transform:'translateY(-150%)'}} />
            </div>
          )
        })}
      </div>
    </CenteredDiv>
  )
}

TopicsListsAuthorListComp.propTypes = {
  setSelectedAuthor:
  PropTypes.func, 
  setShowDropdown: PropTypes.func, 
  listDetails: PropTypes.object
}

export default TopicsListsAuthorListComp
