import React from 'react'
import CompleteLogo from '../../Images/CompleteLogo'
import CustomizedButton from '../CustomizedButton'
import { colors } from '../../utils/colors'
import './styles.css'
import PropTypes from 'prop-types'

export const ConfirmationMessage = ({ message, closeModal }) => {
  return (
    <>
      <CompleteLogo />
      <div className='confirmation-message'>{message}</div>
      <CustomizedButton
        onClick={() => closeModal()}
        marginTop={16}
        title={'OK'}
        backgroundColor={colors?.feedbackColor}
      />
    </>

  )
}

ConfirmationMessage.propTypes = {
  message: PropTypes.string,
  closeModal: PropTypes.func
}
