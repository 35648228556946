import PropTypes from 'prop-types'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const Navigator = ({ children, screen }) => {
  //navigator
  const navigate = useNavigate()
  return <div onClick={() => {
    navigate(screen)
    window.scrollTo(0, 0) 
  }}>{children}</div>
}

Navigator.propTypes = {
  children: PropTypes.element, 
  screen: PropTypes.string
}

export default Navigator
