import React from 'react'

const ArrowLeftCircleIcon2 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="55.894" height="55.894" viewBox="0 0 85.894 85.894">
    <path
      id="Icon_ionic-ios-arrow-dropright-circle"
      data-name="Icon ionic-ios-arrow-dropright-circle"
      d="M89.269,46.322A42.947,42.947,0,1,1,46.322,3.375,42.94,42.94,0,0,1,89.269,46.322Zm-50.483,0L55.7,29.577a3.986,3.986,0,0,0-5.637-5.637L30.361,43.7a3.981,3.981,0,0,0-.124,5.492L49.646,68.663a3.979,3.979,0,1,0,5.637-5.616Z"
      transform="translate(-3.375 -3.375)"
      fill="#919191"
    />
  </svg>
)

export default ArrowLeftCircleIcon2
