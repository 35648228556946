import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import BlogPost from '../../components/BlogPost/BlogPost'
import CenteredDiv from '../../components/CenteredDiv'
import Footer from '../../components/Footer/Footer'
import GradientText from '../../components/GradientText'
import Modal from '../../components/Modal/Modal'
import NewPostButton from './NewPostButton'
import Paginate from '../../components/Paginate/Paginate'
import SearchVideoWithTitleComp from '../../components/searchVideoWithTitleComp/searchVideoWithTitleComp'
import { getCount, getPosts } from '../../redux/actions/BlogActions'
import NewPostForm from './NewPostForm'
import './styles.css'

const Blog = () => {
  const { page } = useParams()
  const pageNum = Number(page)
  const blog = useSelector(state => state.blog)
  const [newPostModal, setNewPostModal] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: 'DISPLAY_HERO_VIDEO', payload: false })
    dispatch(getCount())
    if (pageNum && !blog.posts[pageNum] && blog.total > 0) {
      dispatch(getPosts({ page: pageNum }))
    }
  }, [blog.posts, dispatch, pageNum, blog.total])

  const afterPostHandler = () => {
    setNewPostModal(false)
  }

  return (
    <>
      <Helmet>
        <title> Blog: Posts and Comments for Motivation.How</title>
        <meta name='description' content='blog motivation' />
      </Helmet>
      <img
        className='paper_background'
        src={require('../../Images/paper-background.png')}
        alt=''
      />
      <SearchVideoWithTitleComp />
      <div className='aboutUsContainer blog'>
        <CenteredDiv>
          <GradientText text='Blog' bold />
        </CenteredDiv>
        <NewPostButton openForm={() => setNewPostModal(true)} />
        <div className='blogPage'>
          <div className='postsList'>
            {blog.posts[pageNum] ? (
              blog.posts[pageNum].map((post, idx) => {
                return <BlogPost data={post} key={idx} />
              })
            ) : null}
          </div>
          {blog.total > 1 && <Paginate total={blog.total} current={pageNum} />}
        </div>
        <Modal opened={newPostModal} onClose={() => setNewPostModal(false)}>
          <NewPostForm onSuccess={afterPostHandler} />
        </Modal>

        <Footer />
      </div>
    </>
  )
}
export default Blog
