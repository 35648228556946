import React, { useState } from 'react'
import { useFormik } from 'formik'
import './style.css'
import CustomizedMediumText from '../../components/CustomizedMediumText'
import CustomizedButton from '../../components/CustomizedButton'
import { logInInitialValues, loginSchema } from '../../utils/formikSchemas'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { authenticateLogin } from '../../redux/actions/userActions'
// @note import new component
import SSLLogo from '../../Images/SSLLogo'
import Navigator from '../../components/Navigator'
import { Helmet } from 'react-helmet'

const Login = () => {
  //states
  const [submitPressed,setSubmitPressed] = useState(false)

  //route params
  const navigate = useNavigate()

  // redux handlers
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: logInInitialValues,
    validationSchema:loginSchema,
    onSubmit: (values) => {
      dispatch(authenticateLogin({user_name:values.email},navigate))

    },
  })

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
  } = formik

  return (
    <div className='signIn-container'>
      <Helmet>
        <title>Secure Login | Access Your Account | Motivation.How</title>
        <meta name='description' content='Log in to Motivation.How to access personalized learning experiences, track your progress, and engage with our community.'/>
      </Helmet>
      <div className='signIn-form'>
        <SSLLogo/>
        <div className='signIn-logo'>
          <img src={require('../../Images/motivatonLogo.png')} alt="LOGO" />
          <h1 style={{fontSize:40, lineHeight:'53px', fontWeight:'bolder'}}>Sign in</h1>
          <CustomizedMediumText text={'Welcome back! Please enter your details.'} 
            fontSize={20} 
            lineHeight={'27px'} 
            textAlign={'center'}
            color={'#838383'}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <CustomizedMediumText text={'Email'} fontSize={16} lineHeight={'24px'}/>
            <input
              type="email"
              name="email"
              autoComplete={'username'}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder='Enter your email'
              className={(submitPressed && errors.email ) ? 'inputError': null }
            />
            {submitPressed && errors.email ? (
              <div className="error">{errors.email}</div>
            ) : null}
          </div>
          <CustomizedButton 
            marginTop={16}
            title={'Continue'}
            color={'#ffffff'}
            onClick={() => {
              setSubmitPressed(true)
              handleSubmit()
            }}
          />
          <CustomizedButton 
            marginTop={0}
            title={'Cancel'}
            color={'#ffffff'}
            onClick={() => {
              navigate('/')
            }}
          />
          <div className='loginLink'>
            <CustomizedMediumText text={'Doesn\'t have an account?'} 
              fontSize={16} 
              lineHeight={'21px'} 
              textAlign={'center'}
              color={'#838383'}
            />
            <Navigator screen={'/signup'}><span className='link'>Sign Up</span></Navigator>
          </div>
        </form>
      </div>
    </div>
  )
}


export default Login
