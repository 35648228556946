import { SAVE_ALL_TOPICS, SAVE_FEEDBACK_POINTS, SAVE_TOPICS_DETAILS, SAVE_FEEDBACK_CHAPTER, DISPLAY_HERO_VIDEO } from '../types/TopicTypes'

const topicsInitialState = {
  allTopics: [],
  topicDetails:[],
  feedbackPoints:[],
  chapterFeedback:{},
  displayHeroVideo:true
}

export const handlingTopics = (state = topicsInitialState, action) => {
  switch (action.type) {
  case SAVE_ALL_TOPICS:
    return {
      ...state,
      allTopics: action.payload,
    }
  case SAVE_TOPICS_DETAILS:
    if (!state?.topicDetails?.some((elem) => elem?.topicId === action.payload?.topicId)) {
      return {
        ...state,
        topicDetails: [...state.topicDetails, action.payload],
      }
    }
    return state
  case SAVE_FEEDBACK_POINTS:
    if (!state?.feedbackPoints?.some((elem) => elem?.videoId === action.payload?.videoId)) {
      return {
        ...state,
        feedbackPoints: [...state.feedbackPoints, action.payload],
      }
    }
    return state
  case SAVE_FEEDBACK_CHAPTER:
    return {
      ...state,
      chapterFeedback:  action.payload,
    }      
  case DISPLAY_HERO_VIDEO:
    return {
      ...state,
      displayHeroVideo: action.payload,
    }
  default:
    return state
  }
}
