import PropTypes from 'prop-types'
import React from 'react'

const UpBoldArrow = ({disabled}) => {
  return (
    <svg
      className="w-8 h-8"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M68.6663 28.834L41.1663 1.33398C40.4996 0.667318 39.4996 0.667318 38.8329 1.33398L11.3329 28.834C10.8329 29.334 10.6663 30.0007 10.9996 30.6673C11.1663 31.1673 11.8329 31.6673 12.4996 31.6673H27.6663V77.5007C27.6663 78.5007 28.3329 79.1673 29.3329 79.1673H50.4996C51.4996 79.1673 52.1663 78.5007 52.1663 77.5007V31.6673H67.3329C67.9996 31.6673 68.6663 31.334 68.8329 30.6673C69.3329 30.0007 69.1663 29.334 68.6663 28.834Z"
        fill={!disabled ? 'black' : 'gray'}
      />
    </svg>
  )
}

UpBoldArrow.propTypes = {
  disabled: PropTypes.bool,
}

export default UpBoldArrow
