import React from 'react'
import HorizontalDiv from './HorizontalDiv'
import ImageComp from './imageComp/ImageComp'
import CustomizedText from './CustomizedText'
import { colors } from '../utils/colors'
import PropTypes from 'prop-types'

const HorizontalIconsTextContainer = ({
  onMouseEnter,
  onMouseLeave,
  hoveringAboutUs,
  text,
  leftImgSrc,
  rightImgSrc,
  onClick
}) => {
  return (
    <HorizontalDiv
      width={'100%'}
      justifyContent={'flex-start'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      marginTop={20}
      onClick={onClick}
    >
      <ImageComp imgSrc={leftImgSrc} width={30} height={30} />
      <CustomizedText
        text={text}
        fontSize={22}
        marginLeft={20}
        color={
          hoveringAboutUs ? colors?.notHighlightedText : colors?.subHeading
        }
        cursor={'pointer'}
        lineHeight={'30px'}
      />
      {rightImgSrc}
    </HorizontalDiv>
  )
}

HorizontalIconsTextContainer.propTypes = {
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  hoveringAboutUs: PropTypes.bool,
  text: PropTypes.string,
  leftImgSrc: PropTypes.string,
  rightImgSrc: PropTypes.element,
  onClick: PropTypes.func
}

export default HorizontalIconsTextContainer
