import PropTypes from 'prop-types'
import React from 'react'

const SSLLogo = ({ css }) => {
  // @note loading src
  const url = process.env.REACT_APP_SSL_LOGO
  // @note just <img> html
  return (
    <a rel="noreferrer" target="_blank" href={`https://www.trustlogo.com/ttb_searcher/trustlogo?v_querytype=W&v_shortname=SC5&v_search=${window.location.href}&x=6&y=5`}><img style={css} src={url} alt="secure page" /></a>
  )
}

SSLLogo.propTypes = {
  css: PropTypes.object
}

export default SSLLogo
