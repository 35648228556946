import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

const AdminNav = props => {
  const { setType, setPaging, paging } = props
  return (
    <div className='bg-white w-full m-0 py-2 md:px-0 px-3 fixed flex flex-col items-center'>
      <div className='w-full md:w-1/2'>
        <NavLink to='/' className='font-bold text-orange-400 mr-1'>
          Home
        </NavLink>
        <button className='mx-1 text-orange-400 mr-1' onClick={() => setType('p')}>
          Paged
        </button>
        <button className='mx-1 text-orange-400 mr-1' onClick={() => setType('ua')}>
          All
        </button>
        <button className='mx-1 text-orange-400 mr-1' onClick={() => setType('c')}>
          Editing
        </button>
        {paging && (
          <div className='text-sm text-right float-right'>
            Paging:
            <select onChange={e => setPaging(Number(e.target.value))} value={`${paging}`}>
              <option value='5'>5</option>
              <option value='10'>10</option>
              <option value='25'>25</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </select>
          </div>
        )}
      </div>
    </div>
  )
}

AdminNav.propTypes = {
  setType: PropTypes.func.isRequired,
  setPaging: PropTypes.func.isRequired,
  paging: PropTypes.number
}

export default AdminNav
