import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './redux/store/Store'

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    // console.log('sw disabled')
    // navigator.serviceWorker.register('/sw.js')
    // .then((registration) => {
    //   console.log('ServiceWorker registration successful:', registration)
    // })
    // .catch((error) => {
    //   console.log('ServiceWorker registration failed:', error)
    // })
  })
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
