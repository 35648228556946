import PropTypes from 'prop-types'
import React from 'react'
import { colors } from '../utils/colors'

const CustomizedText = ({
  text,
  textTransform,
  color,
  fontSize,
  fontWeight,
  width,
  marginLeft,
  cursor,
  marginTop,
  textAlign,
  marginRight,
  lineHeight,
  style,
  border,
  onClick,
  className,
  filter,
  childClassName,
  html
}) => {
  return (
    <div className={className} onClick={onClick}>
      <h5
        className={childClassName}
        dangerouslySetInnerHTML={{ __html: html ? html : text}}
        style={{
          color: color ? color : colors?.notHighlightedText,
          fontSize: fontSize ? fontSize : '30px',
          fontWeight: fontWeight ? fontWeight : '600',
          width: width,
          filter: filter,
          marginLeft: marginLeft,
          cursor: cursor,
          marginTop: marginTop,
          fontFamily: 'Gilroy',
          textAlign: textAlign,
          textTransform: textTransform,
          marginRight: marginRight,
          lineHeight: lineHeight ? lineHeight : '27px',
          border: border,
          ...style
        }}
      />
    </div>
  )
}

CustomizedText.propTypes = {
  text: PropTypes.string,
  textTransform: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.string,
  width: PropTypes.number,
  marginLeft: PropTypes.number,
  cursor: PropTypes.string,
  marginTop: PropTypes.number,
  textAlign: PropTypes.string,
  marginRight: PropTypes.number,
  lineHeight: PropTypes.string,
  style: PropTypes.string,
  border: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  filter: PropTypes.string,
  childClassName: PropTypes.string,
  html: PropTypes.string
}

export default CustomizedText
