export const colors = {
  highlightedText:'white',
  notHighlightedText:'#131313',
  white:'white',
  black:'black',
  subHeading:'#919191',
  faded:'rgba(255, 255, 255, 0.5)',
  descriptionText:'#919191',
  danger:'#EE2624',
  info : '#FEC722',
  fadedBorder:'#919191',
  feedbackColor: '#1592E6',
  fadedBg: '#F5F5F5',
  gray:'#838383',
  brandColor: '#7B3F00'
}
