import * as Yup from 'yup'

//signup formik fields and validations
export const signupInitialValues = {
  first_name: '',
  last_name: '',
  email: '',
  us_zip_code: '',
  male_gender: 'true',
  birth_day: '',
}
export const signupSchema = Yup.object().shape({
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  // us_zip_code: Yup.string().required('US Zip Code is required'),
  birth_day: Yup.date().required('Birthday is required'),
  // ip_addr: Yup.string().required('IP Address is required'),
})


export const logInInitialValues = {
  email: '',
}
export const loginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
})

export const logInPasswordInitialValues = {
  password: '',
}
export const loginPasswordSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
})

export const signUpPasswordInitialValues = {
  password: '',
  confirmPassword:'',
}
export const signUpPasswordSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .required('Password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
})