import React from 'react'

const ArrowRightCircleIcon2 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="55.894" height="55.894" viewBox="0 0 85.894 85.894">
    <path
      id="Icon_ionic-ios-arrow-dropright-circle"
      data-name="Icon ionic-ios-arrow-dropright-circle"
      d="M3.375,46.322A42.947,42.947,0,1,0,46.322,3.375,42.94,42.94,0,0,0,3.375,46.322Zm50.483,0L36.948,29.577a3.986,3.986,0,0,1,5.637-5.637l19.7,19.76a3.981,3.981,0,0,1,.124,5.492L43,68.663a3.979,3.979,0,1,1-5.637-5.616Z"
      transform="translate(-3.375 -3.375)"
      fill="#919191"
    />
  </svg>
)

export default ArrowRightCircleIcon2
