import PropTypes from 'prop-types'
import React from 'react'

const OfferIcon = ({ img }) => {
  return <div className="offer-icon" style={{ backgroundImage: `url(${img})` }}></div>
}

OfferIcon.propTypes = {
  img: PropTypes.string,
}

export default OfferIcon
