import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOffers } from '../../redux/actions/offerActions'
import Offer from '../../components/Offer/Offer'
import './styles.css'
import SSLLogo from '../../Images/SSLLogo'
import GradientText from '../../components/GradientText'
import CenteredDiv from '../../components/CenteredDiv'
import ScreenPaddedContainer from '../../components/ScreenPaddedContainer'
import CompleteLogo from '../../Images/CompleteLogo'
import Footer from '../../components/Footer/Footer'
import { Helmet } from 'react-helmet'
import NavBar from '../../components/NavBar'
import Navigator from '../../components/Navigator'
import ImageComp from '../../components/imageComp/ImageComp'

const Fund = () => {
  const [submitPressed, setSubmitPressed] = useState()
  const [provider, setProvider] = useState()
  const [payBtns, setPayBtns] = useState()

  const stripeEnabled = (process.env.REACT_APP_PAYMENT_STRIPE === 'enabled')
  const paypalEnabled = (process.env.REACT_APP_PAYMENT_PAYPAL === 'enabled')

  const dispatch = useDispatch()
  const offers = useSelector(state => state.offers)


  useEffect(() => {
    offers.length === 0 && dispatch(getOffers())
  }, [dispatch, offers])

  const showPayBtns = (id, amount) => {
    setPayBtns(<div className="offer-buttons">{
      stripeEnabled && <div
        className={'offer-submit-container' + (paypalEnabled ? ' preferred' : '')}>
        <input
          type="submit"
          onClick={() => {
            setProvider('stripe')
            setSubmitPressed(id)
          }}
          value={`Checkout ${amount > 0 ? '$' + amount : ' '}(Stripe)`}
          className="submitButton offer-submit" />
      </div>}
    {paypalEnabled && <div
      className="offer-submit-container"><input
        type="submit"
        onClick={() => {
          setProvider('paypal')
          setSubmitPressed(id)
        }}
        value={`Checkout ${amount > 0 ? '$' + amount : ' '}(Paypal)`}
        className={'offer-submit submitButton' + (stripeEnabled ? ' moderate' : '')}
      /></div>}
    </div>)
  }
  return <>
    <Helmet>
      <title>Empower Learning Through Your Support | Motivation.How Funding</title>
      <meta name='description' content='Join us in expanding our educational content by contributing to Motivation.how. Your generous support fuels our mission to motivate and educate, benefiting our global community and empowering individuals worldwide.'/>
    </Helmet>
    <img className='paper_background' src={require('../../Images/paper-background.png')} alt="" />
    <ScreenPaddedContainer>
      <div className="grid grid-cols-4">
        <div className="hidden md:grid"><CompleteLogo /></div>
        <div className="md:hidden w-fit">
          <Navigator screen={'/'}>
            <ImageComp imgSrc={require('../../Images/logo.png')} width={36} height={36} />
          </Navigator>
        </div>
        <div className="col-span-3">
          <NavBar />
        </div>
      </div>
      <div className='logo-area'>
        <SSLLogo css={{ margin: 'auto 1em' }} />
        <GradientText text='Fund / Support us' />
      </div>
      <div className="fund-page">
        <div className="offers-list">{
          offers.map((offer) => {
            return <Offer
              showPayBtns={showPayBtns}
              setSubmitPressed={setSubmitPressed}
              submitPressed={submitPressed}
              provider={provider}
              key={offer.id}
              offer={offer}
              total={offers.length} />
          })
        }
        <div style={{ clear: 'both', width: '100%' }}></div>
        </div>
        <CenteredDiv>
          {payBtns}
        </CenteredDiv>
      </div>
      <Footer />
    </ScreenPaddedContainer>
  </>
}

export default Fund
