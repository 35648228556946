import PropTypes from 'prop-types'
import React from 'react'

const ArrowForwardIcon = ({ transform, style }) => {
  return (
    <div style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="7.9983077"
        height="13.982237"
        viewBox="0 0 7.9983079 13.982237"
        transform={transform}>
        <path
          id="Icon_ionic-ios-arrow-forward"
          d="m 5.5867882,6.9935044 -5.29500003,-5.291 a 1,1 0 0 1 0,-1.41200004 1.008,1.008 0 0 1 1.41600003,0 L 7.7077883,6.2855044 a 1,1 0 0 1 0.029,1.379 L 1.7127882,13.700504 a 1,1 0 0 1 -1.41600003,-1.412 z"
          fill="#131313"
        />
      </svg>
    </div>
  )
}

ArrowForwardIcon.propTypes = {
  transform: PropTypes.string,
  style: PropTypes.object
}

export default ArrowForwardIcon
