import axios from 'axios'
import authenticatingApiInterceptor from '../../utils/authenticatingApisInterceptor'
import { HIDE_SCREEN_LOADER, SHOW_SCREEN_LOADER, SHOW_TOAST } from '../types/LoaderTypes'
import { REMOVE_USER_DATA, SAVE_ALL_INTEREST_TYPES, SAVE_USER_DATA, SAVE_USER_INTERESTS, SAVE_AUTHOR_DATA } from '../types/userTypes'
import authorizedAxios from '../../utils/authorizedAxios'
import defaultAxios from '../../utils/axiosInterceptors'
import apiInterceptor from '../../utils/ApiInterceptor'


export const authenticateSignup = (values,navigate) => async(dispatch) => {
  try {
    dispatch({type:SHOW_SCREEN_LOADER})
    // const user = await authenticatingApiInterceptor.post('sec/users/startTmp',values);
    const user = await apiInterceptor.post('sec/users/startTmp',values)

    if(user?.data){ navigate(`/password?id=${user?.data}`) }
    dispatch({type:HIDE_SCREEN_LOADER})
  }
  catch (error){
    console.log('Error while authenticating signup:', error)
    dispatch({type:SHOW_TOAST,payload: error?.response?.data ?error?.response?.data  : 'failed to authenticate email'})
    dispatch({type:HIDE_SCREEN_LOADER})
  }
}

export const signupUser = (values,navigate) => async(dispatch) => {
  try {
    dispatch({type:SHOW_SCREEN_LOADER})
    // const response = await authenticatingApiInterceptor.post('sec/users/finishTmp',values);
    const response = await apiInterceptor.post('sec/users/finishTmp',values)

    if(response?.data){  
      dispatch({type:SHOW_TOAST,payload: 'signup successful'})
      navigate('/login') }
    dispatch({type:HIDE_SCREEN_LOADER})
  }
  catch (error){
    console.log('Error while authenticating signup:', error)
    dispatch({type:HIDE_SCREEN_LOADER})
  }
}

export const getCountries = () => async(dispatch) => {
  try { 
    dispatch({type:SHOW_SCREEN_LOADER})
    const countries = await apiInterceptor.get('sec/countries')
    dispatch({type:HIDE_SCREEN_LOADER})
    return countries?.data
      
  }
  catch (error){
    console.log('Error while authenticating signup:', error)
    dispatch({type:SHOW_TOAST,payload:'failed to get the list of countries'})
    dispatch({type:HIDE_SCREEN_LOADER})
  }
}

export const getIpAddress = () => async() => {
  try{
    const ipAddress = await axios.get('https://api.ipify.org?format=json')
    return ipAddress?.data?.ip 
  }
  catch(error){
    console.log('Error while fetching ip address: ',error)
  }
}

export const authenticateLogin = (values,navigate) => async(dispatch) => {
  try {
    dispatch({type:SHOW_SCREEN_LOADER})
    const user = await authenticatingApiInterceptor.post('sec/users/authenticate/startTmp',values)
    if(user?.data){ 
      navigate(`/loginPassword?id=${user?.data}`)
      const email = localStorage.getItem('email')
      email && localStorage.removeItem('email')
      localStorage.setItem('email',values?.user_name)
    }
    dispatch({type:HIDE_SCREEN_LOADER})
  }
  catch (error){
    console.log('Error while authenticating login:', error)
    dispatch({type:SHOW_TOAST,payload:error?.response?.data ?error?.response?.data  : 'failed to authenticate email'})
    dispatch({type:HIDE_SCREEN_LOADER})
  }
}

export const login = (values,navigate,setUnlock) => async(dispatch) => {
  try {
    dispatch({type:SHOW_SCREEN_LOADER})
    const user = await authenticatingApiInterceptor.post('sec/users/authenticate/finishTmp',values)
    if(user?.data){
      localStorage.setItem('id',values.user_id)
      localStorage.setItem('token',user.data.accessToken)
      localStorage.setItem('refreshToken',user.data.extra)
      dispatch({type:SHOW_TOAST,payload: 'login successful'})
      navigate('/') 
    }
  }
  catch (error){
    console.log('Error while loggin in:', error)
    if (error.response.status === 403 || error.response.status === 423) {
      setUnlock(true)
    }
    dispatch({type:SHOW_TOAST,payload:error?.response?.data ?error?.response?.data  : 'failed to log in, please try again later'})
  } finally {
    dispatch({type:HIDE_SCREEN_LOADER})
  }
}

export const requestUnlockEmail = ({user_id}) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_SCREEN_LOADER })
    const response = await authenticatingApiInterceptor.post('sec/users/sendUnlockEmail', {user_id: user_id})
    if (response?.data) {
      dispatch({ type: SHOW_TOAST, payload: response.data })
    }
  }
  catch (error) {
    console.log('Error while loggin in:', error)
    dispatch({type:SHOW_TOAST,payload:error?.response?.data ?error?.response?.data  : 'failed to request email, please try again later'})
  } finally {
    dispatch({ type: HIDE_SCREEN_LOADER })
  }
}

export const unlockUser = ({tmp_id},navigate) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_SCREEN_LOADER })
    const response = await authenticatingApiInterceptor.post('sec/users/unlock', {tmpId: tmp_id})
    if (response?.data) {
      dispatch({ type: SHOW_TOAST, payload: response.data })
    }
  }
  catch (error) {
    console.log('Error while unlock user:', error)
    await dispatch({type:SHOW_TOAST,payload:error?.response?.data ? error?.response?.data  : 'failed to unlock, please try again later'})
  } finally {
    dispatch({ type: HIDE_SCREEN_LOADER })
    navigate('/login')
  }
}

export const getUserData = (id) => async(dispatch) => {
  try {
    dispatch({type:SHOW_SCREEN_LOADER})
    const user = await defaultAxios.get(`sec/users/${id}`)
    if(user.data){ 
      dispatch({type:SAVE_USER_DATA, payload: user.data})
    }
  }
  catch (error){
    console.log('Error while authenticating signup:', error)
  } finally {
    dispatch({type:HIDE_SCREEN_LOADER})
  }
}

export const removeUserData = (navigate) => async(dispatch) => {
  try {
    localStorage.clear()
    navigate('/login')
    dispatch({type:SHOW_SCREEN_LOADER})
    
    dispatch({type:SHOW_TOAST,payload:'logged out successfully'})
    dispatch({type:REMOVE_USER_DATA})
    dispatch({type:HIDE_SCREEN_LOADER})
  }
  catch (error){
    console.log('Error while authenticating signup:', error)
    dispatch({type:HIDE_SCREEN_LOADER})
  }
}

export const resetPasswordFirstCall = (values) => async(dispatch) => {
  try {
    dispatch({type:SHOW_SCREEN_LOADER})
    const response = await authenticatingApiInterceptor.post('sec/users/passwordResetEmail',values)
    response?.data && dispatch({type:SHOW_TOAST,payload:response?.data})
    dispatch({type:HIDE_SCREEN_LOADER})
  }
  catch (error){
    console.log('Error while authenticating signup:', error)
    dispatch({type:HIDE_SCREEN_LOADER})
    dispatch({ type: SHOW_TOAST, payload: error?.response?.data || 'Can\'t request reset link for entered email' })
  }
}

export const resetPasswordSecondCall = (values,navigate) => async(dispatch) => {
  try {
    dispatch({type:SHOW_SCREEN_LOADER})
    const response = await authenticatingApiInterceptor.post('sec/users/changePassword',values)
    response?.data && localStorage.clear()
    dispatch({type:HIDE_SCREEN_LOADER})
    dispatch({type:SHOW_TOAST,payload:response?.data})
    navigate('/login')
  }
  catch (error){
    console.log('Error while authenticating signup:', error)
    dispatch({type:HIDE_SCREEN_LOADER})
    dispatch({type:SHOW_TOAST, payload: error.response?.data})
  }
}

export const getUserInterests = (id) => async(dispatch) => {
  try {
    dispatch({type:SHOW_SCREEN_LOADER})
    const response = await defaultAxios.get(`multi/users/${id}/fullInterests`)
    if(response?.data){
      dispatch({type:SAVE_USER_INTERESTS,payload:response?.data})
    }
    dispatch({type:HIDE_SCREEN_LOADER})
  }
  catch (error){
    console.log('Error while authenticating signup:', error)
    dispatch({type:HIDE_SCREEN_LOADER})
  }
}

export const updateProfileInfo = ({data, values, setEditProfileMode}) => async (dispatch) => {
  try {
    const response = await authorizedAxios.put('sec/users', data)
    if (response?.data) {
      dispatch({ type: SAVE_USER_DATA, payload: [values] })
      dispatch({ type: SHOW_TOAST, payload: 'Profile updated successfully' })
      setEditProfileMode(false)
    }
    dispatch({ type: SHOW_SCREEN_LOADER })
  } catch (error) {
    console.error(error)
  } finally {
    dispatch({ type: HIDE_SCREEN_LOADER })
  }
}


export const becomeAuthorReq = (values) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_SCREEN_LOADER })
    const userId = JSON.parse(localStorage.getItem('id'))
    if (userId) {
      await authorizedAxios.post(`sec/users/${userId}/authorRequest`, values)
      dispatch({ type: HIDE_SCREEN_LOADER })
      dispatch({type:SHOW_TOAST,payload:'request sent successfully'})
    } else {
      throw new Error('User ID not available')
    }
  } catch (error) {
    dispatch({type:SHOW_TOAST,payload: typeof error?.response?.data === 'string' ? error?.response?.data : 'error forwarding request'})
    dispatch({ type: HIDE_SCREEN_LOADER })
  }
}

export const checkAuthorData = (values) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_SCREEN_LOADER })
    const userId = JSON.parse(localStorage.getItem('id'))
    if (userId) {
      const response = await authorizedAxios.get(`sec/users/${userId}/authorRequest`, values)
      // console.log(response, '------response---------');
      dispatch({ type: HIDE_SCREEN_LOADER })
      dispatch({type:SAVE_AUTHOR_DATA,payload:response?.data})
    } else {
      throw new Error('User ID not available')
    }
  } catch (error) {
    dispatch({type:SHOW_TOAST,payload: typeof error?.response?.data === 'string' ? error?.response?.data : 'error forwarding request'})
    dispatch({ type: HIDE_SCREEN_LOADER })
  }
}

export const updateInterests = (value,id) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_SCREEN_LOADER })
    await authorizedAxios.put(`relations/users/${id}/interests`, value)
    dispatch(getUserInterests(id))
    dispatch({ type: HIDE_SCREEN_LOADER })
    dispatch({ type:SHOW_TOAST,payload:'interests updated successfully' })
  } catch (error) {
    console.log(error)
    const errorStatusCode = error?.response?.status
    if (errorStatusCode === 406) {
      dispatch({
        type: SHOW_TOAST,
        payload: 'Cannot save interests: attempt to save duplicated interests observed.'
      })
    } else {
      dispatch({
        type: SHOW_TOAST,
        payload: error?.message ? error?.message : 'Error updating interests'
      })
    }
    // dispatch({type:SHOW_TOAST,payload:error?.message ? error?.message : 'error updating interests'})
    dispatch({ type: HIDE_SCREEN_LOADER })
  }
}

export const requestConfirmationEmail = ({user_id}) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_SCREEN_LOADER })
    const response = await authorizedAxios.post('sec/users/confirmAccountEmail', {user_id: user_id})
    if (response?.data) {
      dispatch({ type:SHOW_TOAST, payload:'Email sent' })
    }
  } catch (e) {
    dispatch({ type:SHOW_TOAST, payload: e?.response?.data || 'Can\'t send email' })
  } finally {
    dispatch({ type: HIDE_SCREEN_LOADER })
  }
}

export const confirmAccount = ({ tmp_id }, navigate) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_SCREEN_LOADER })
    const response = await authorizedAxios.post('sec/users/confirmAccount',
      { tmpId: tmp_id }
    )
    if(response.data) { 
      await dispatch({type:SHOW_TOAST, payload: response.data})

      navigate('/profile')
    }
  } catch (error) {
    console.log({errorConfirmAccount: error})
    dispatch({type:SHOW_TOAST, payload: error.response.data})
  } finally {
    dispatch({ type: HIDE_SCREEN_LOADER })
  }
}

export const getAllInterests = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_SCREEN_LOADER })
    const response = await axios.get(process.env.REACT_APP_API + 'relations/interestnames')
    response?.data && dispatch({type:SAVE_ALL_INTEREST_TYPES,payload:response?.data})
    dispatch({ type: HIDE_SCREEN_LOADER })
  } catch (error) {
    dispatch({type:SHOW_TOAST,payload:'failed to get all interests'})
    dispatch({ type: HIDE_SCREEN_LOADER })
  }
}


export const getDirectKids = (val) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_SCREEN_LOADER })
    const directKids = await axios.get(process.env.REACT_APP_API + `multi/interests/${val}?find=directKidsOf`)
    const parentPath = await axios.get(process.env.REACT_APP_API + `multi/interests/${val}?find=pathToParent`)
    const allKids = await axios.get(process.env.REACT_APP_API + `multi/interests/${val}?find=childrenOf`)
    dispatch({ type: HIDE_SCREEN_LOADER })
    return {
      directKids:directKids?.data,
      parentPath:parentPath?.data,
      allKids:allKids?.data
    }
  } catch (error) {
    dispatch({type:SHOW_TOAST,payload:'failed to get direct sub data of interest'})
    dispatch({ type: HIDE_SCREEN_LOADER })
  }
}

export const checkAccess = (id, navigate) => async (dispatch)  => {
  try {
    dispatch({ type: SHOW_SCREEN_LOADER })
    const access = await axios.get(`/sec/users/${id}?check=manage`)
    console.log({access})
  } catch (error) {
    dispatch({ type: SHOW_TOAST, payload: 'Check your privelegies!' })
    navigate('/')
  } finally {
    dispatch({ type: HIDE_SCREEN_LOADER })
  }
}
